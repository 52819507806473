import * as Model from './actions';
import { getType } from 'typesafe-actions';
import * as Types from '../types';
import {
  initialState,
  numberOfRenderedEntriesDefault,
  numberOfRenderedEntriesIncrease,
} from '../initialState';

export default function userInterface(
  state: Types.UserInterface = initialState.userInterface,
  action: Model.UnconfuseUIAction
): Types.UserInterface {
  switch (action.type) {
    case getType(Model.updateSearchPhrase):
      return {
        ...state,
        searchPhrase: action.payload.searchPhrase,
      };
    case getType(Model.setSelectedCollection):
      return {
        ...state,
        selectedCollectionKey: action.payload.selectedCollectionKey,
      };
    case getType(Model.setPage):
      return {
        ...state,
        visiblePage: action.payload.pageNumber,
      };
    case getType(Model.renderMoreElements):
      return {
        ...state,
        numberOfRenderedEntries:
          state.numberOfRenderedEntries + numberOfRenderedEntriesIncrease,
      };
    case getType(Model.renderDefaultNumberOfElements):
      return {
        ...state,
        numberOfRenderedEntries: numberOfRenderedEntriesDefault,
      };
    case getType(Model.showBookmarkletHint):
      return {
        ...state,
        hintForBookmarkletIsVisible: true,
      };
    case getType(Model.hideBookmarkletHint):
      return {
        ...state,
        hintForBookmarkletIsVisible: false,
      };
    case getType(Model.showIntegrationsHint):
      return {
        ...state,
        hintForIntegrationIsVisible: true,
      };
    case getType(Model.hideIntegrationsHint):
      return {
        ...state,
        hintForIntegrationIsVisible: false,
      };
    case getType(Model.showFeedbackModal):
      return {
        ...state,
        modalForFeedbackIsVisible: true,
      };
    case getType(Model.hideFeedbackModal):
      return {
        ...state,
        modalForFeedbackIsVisible: false,
      };
    case getType(Model.showEditEntryView):
      return {
        ...state,
        editEntryViewVisible: true,
      };
    case getType(Model.hideEditEntryView):
      return {
        ...state,
        editEntryViewVisible: false,
      };
    case getType(Model.setVisibleContainerModal):
      return {
        ...state,
        visibleContainerModal: action.payload.visibleModal,
      };
    case getType(Model.setSidebarDrawerVisibility):
      return {
        ...state,
        showsSidebarDrawer: action.payload.isVisible,
      };
    default:
      return state;
  }
}
