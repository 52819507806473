import { Modal } from 'antd';
import * as React from 'react';
import * as Redux from 'react-redux';
import * as Thunk from 'redux-thunk';
import * as Types from '../../State/types';
import { UIOperations, UISelectors } from '../../State/UserInterface';

interface IntegrationHintProps {
  integrationHintIsVisible: boolean;
  hideIntegrationHint: () => void;
}

function IntegrationHint({
  integrationHintIsVisible,
  hideIntegrationHint,
}: IntegrationHintProps) {
  return (
    <Modal
      title="Add entries from other services via Zapier"
      visible={integrationHintIsVisible}
      onOk={hideIntegrationHint}
      onCancel={hideIntegrationHint}
      footer={''}
    >
      <p>
        Our hook is in development mode currently, please write an email to{' '}
        <a href="mailto:hello@unconfuse.me">hello@unconfuse.me</a>
        and we will add you to the group.
      </p>
    </Modal>
  );
}

function mapStateToProps(state: Types.All, ownProps: object) {
  return {
    integrationHintIsVisible: UISelectors.hintForIntegrationIsVisible(state),
  };
}

function mapDispatchToProps(
  dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>
) {
  return {
    hideIntegrationHint: () => dispatch(UIOperations.hideIntegrationsHint()),
  };
}

export default Redux.connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegrationHint);
