import * as Actions from './actions';
import {
  fetchContainersSuccess,
  populateFromPersitance,
  receivedContainerUpdate,
  enablePersistance,
} from './actions';
import * as Types from '../types';
import * as Thunk from 'redux-thunk';
import * as APIPaths from '../../lib/apiPaths';
import { SessionOperations } from '../Session';
import Processor from '../../lib/PostProcess';
import Analytics from '../../lib/analytics';
import { ContainersSelectors } from '.';
import * as _ from 'lodash';
import moment from 'moment';
import * as Auth from '../../lib/webRequests';

function fetchContainers() {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ) {
    dispatch(Actions.fetchContainersRequest());

    // Fetch requests do not work in the test env
    if (process.env.NODE_ENV === 'test') {
      return dispatch(Actions.fetchContainersSuccess({}));
    }
    const requestURL = APIPaths.fetchContainersURL(
      getState().session.lastRequestAt
    );

    return Auth.fetchWithCredentials(requestURL, undefined, null)
      .then((response) => response.json())
      .then((json) => {
        const containerById = Processor.containersAPIToClient(json.containers);
        dispatch(Actions.fetchContainersSuccess(containerById));
        dispatch(
          SessionOperations.saveLastRequestDate(moment(json.response_time))
        );
      })
      .catch(function (err: string) {
        global.console.log(
          '******* ERROR happended when fetching containers. ********'
        );
        return err;
      });
  };
}

function newContainerAt(
  name: string,
  containerType: Types.ContainerType,
  icon: string,
  aliases: string,
  description: string,
  parentContainerId: Types.StringModelId,
  index: number,
  successHandler: (id: Types.StringModelId) => void
) {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ) {
    dispatch(Actions.createContainerRequest());
    type CreateContainerResponse = { container: { id: string } };

    const successActionCreator = (response: CreateContainerResponse) => (
      dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>
    ) => {
      Analytics.track('Container created');
      successHandler(response.container.id);

      return dispatch(Actions.createContainerSuccess());
    };

    const failureActionCreator = (error: Types.UnconfuseError) => (
      dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>
    ) => {
      global.console.log(
        '******* ERROR happended when creating container. ********'
      );
      global.console.log(error.message);
      return dispatch(Actions.createContainerFailure([error.message]));
    };

    return dispatch(
      Auth.fetchWithCredentialsAndPostprocessing<CreateContainerResponse>(
        APIPaths.createContainerURL(),
        'POST',
        JSON.stringify(
          Processor.containerClientToAPI({
            name,
            entries: [],
            containerType,
            icon,
            aliases,
            description,
            parentId: parentContainerId,
            position: index,
          })
        ),
        successActionCreator,
        failureActionCreator
      )
    );
  };
}

function updateContainer(
  id: Types.StringModelId,
  containerProps: Types.UpdatedableContainer
): Thunk.ThunkAction<
  Promise<Types.AllActions> | Types.AllActions,
  Types.All,
  undefined,
  Types.AllActions
> {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ) {
    const containerToBeUpdated = ContainersSelectors.containerById(
      getState(),
      id
    );
    const updatedContainerProps = {
      ...containerToBeUpdated,
      ...containerProps,
    };
    let processedContainer = Processor.containerClientToAPI(
      updatedContainerProps
    );

    dispatch(Actions.updateContainerRequest(processedContainer));

    const successActionCreator = () => (
      dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>
    ) => {
      Analytics.track('Container updated');
      return dispatch(Actions.updateContainerSuccess());
    };

    const failureActionCreator = (error: Types.UnconfuseError) => (
      dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>
    ) => {
      global.console.log(
        '******* ERROR happended when updating container. ********'
      );
      // global.console.log(err);
      return dispatch(
        Actions.updateContainerFailure(id, {
          ...error,
          message: `updateContainer: ${error.message}`,
        })
      );
    };

    return dispatch(
      Auth.fetchWithCredentialsAndPostprocessing(
        APIPaths.updateContainerURL(id),
        'PATCH',
        JSON.stringify(processedContainer),
        successActionCreator,
        failureActionCreator
      )
    );
  };
}

function deleteContainer(containerID: Types.StringModelId) {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>
  ) {
    dispatch(Actions.deleteContainerRequest(containerID));

    const successActionCreator = () => (
      dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>
    ) => {
      Analytics.track('Container deleted');
      return dispatch(Actions.deleteContainerSuccess(containerID, {}));
    };

    const failureActionCreator = (error: Types.UnconfuseError) => (
      dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>
    ) => {
      return dispatch(
        Actions.deleteContainerFailure(containerID, {
          ...error,
          message: `deleteContainer: ${error.message}`,
        })
      );
    };

    return dispatch(
      Auth.fetchWithCredentialsAndPostprocessing(
        APIPaths.deleteContainerURL(containerID),
        'DELETE',
        undefined,
        successActionCreator,
        failureActionCreator
      )
    );
  };
}

function insertContainerAt(
  id: Types.StringModelId,
  parentContainerId: Types.StringModelId,
  index: number
): Types.UnconfuseAction {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ) {
    const parentContainer = ContainersSelectors.containerById(
      getState(),
      parentContainerId
    );
    if (parentContainer.containerType === 'collection') {
      global.console.error(
        `Tried to make a container the child of a collection`
      );
      return dispatch(SessionOperations.applicationError());
    }

    return dispatch(
      updateContainer(id, { parentId: parentContainerId, position: index })
    );
  };
}

// Move all entries from the source collection into the target collection and delete the source collection.
function mergeCollectionIntoCollection(
  sourceCollectionId: Types.StringModelId,
  targetCollectionId: Types.StringModelId
) {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ) {
    const sourceCollection = ContainersSelectors.containerById(
      getState(),
      sourceCollectionId
    );
    const targetCollection = ContainersSelectors.containerById(
      getState(),
      targetCollectionId
    );

    const sourceEntryIds = sourceCollection.entries;
    const targetEntryIds = targetCollection.entries;

    dispatch(
      updateContainer(targetCollection.id, {
        entries: targetEntryIds.concat(sourceEntryIds),
      })
    );

    return dispatch(deleteContainer(sourceCollection.id));
  };
}

function removeEntryFromCollection(
  entryId: Types.ModelId,
  collectionId: Types.StringModelId
) {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ) {
    // global.console.log(`removeEntryFromCollection: entryId=${entryId}, collectionId=${collectionId}`);
    const container = ContainersSelectors.containerById(
      getState(),
      collectionId
    );

    if (container.containerType !== 'collection') {
      throw new Error(
        `Tried to add an entry (${entryId}) to a non-collection (${collectionId})`
      );
    }

    const entries = [...container.entries];

    return dispatch(
      updateContainer(collectionId, {
        entries: _.without(entries, entryId.toString()),
      })
    );
  };
}

function addEntryToCollection(
  entryId: Types.ModelId,
  collectionId: Types.StringModelId
) {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ) {
    const container = ContainersSelectors.containerById(
      getState(),
      collectionId
    );

    if (container.containerType !== 'collection') {
      throw new Error(
        `Tried to add an entry (${entryId}) to a non-collection (${collectionId})`
      );
    }

    const entries = [...container.entries];
    entries.unshift(entryId.toString());

    return dispatch(
      updateContainer(collectionId, {
        entries: _.uniq(entries),
      })
    );
  };
}

const ContainersOperations = {
  populateFromPersitance,
  fetchContainers,
  fetchContainersSuccess,
  receivedContainerUpdate,
  newContainerAt,
  updateContainer,
  deleteContainer,
  insertContainerAt,
  mergeCollectionIntoCollection,
  removeEntryFromCollection,
  addEntryToCollection,
  enablePersistance,
};

export default ContainersOperations;
