import * as Types from '../State/types';

export function searchByPhrase(
  entries: Types.Entry[],
  searchPhrase: string
): Types.Entry[] {
  let filteredEntries = entries;

  if (searchPhrase.length > 2) {
    let searchPhraseLC = searchPhrase.toLocaleLowerCase();

    filteredEntries = filteredEntries.filter(entry => {
      return (
        entry.title.toLocaleLowerCase().indexOf(searchPhraseLC) > -1 ||
        entry.url.toLocaleLowerCase().indexOf(searchPhraseLC) > -1 ||
        entry.description.toLocaleLowerCase().indexOf(searchPhraseLC) > -1
      );
    });
  }

  return filteredEntries;
}
