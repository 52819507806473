import { Button, Form, Input, Modal, Radio } from 'antd';
import * as React from 'react';
import * as Redux from 'react-redux';
import * as Thunk from 'redux-thunk';
import Analytics from '../../lib/analytics';
import { SessionSelectors } from '../../State/Session';
import * as Types from '../../State/types';
import { UIOperations, UISelectors } from '../../State/UserInterface';
import './FeedbackForm.css';

const FormItem = Form.Item;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

interface FeedbackFormState {
  feeling: 'terrible' | 'bad' | 'ok' | 'good' | 'amazing' | '';
  thoughts: string;
  sendingFeedback: boolean;
  showingThanks: boolean;
}

interface FeedbackFormStateProps {
  userID: number;
  showingModal: boolean;
}

interface FeedbackFormDispatchProps {
  hideFeedbackModal: () => void;
}

interface FeedbackFormProps
  extends FeedbackFormStateProps,
    FeedbackFormDispatchProps {}

const initialState = {
  feeling: '' as '',
  thoughts: '',
  sendingFeedback: false,
  showingThanks: false,
};

class FeedbackForm extends React.Component<
  FeedbackFormProps,
  FeedbackFormState
> {
  constructor(props: FeedbackFormProps) {
    super(props);

    this.state = initialState;
    this.resetState = this.resetState.bind(this);
    this.sendFeedbackToService = this.sendFeedbackToService.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }

  resetState() {
    this.setState(initialState);
    this.props.hideFeedbackModal();
  }

  handleInput(name: 'feeling' | 'thoughts', value: string) {
    this.setState({
      ...this.state,
      [name]: value,
    });
  }

  sendFeedbackToService() {
    let { userID } = this.props;
    let { feeling, thoughts } = this.state;

    this.setState({
      ...this.state,
      sendingFeedback: true,
    });

    Analytics.track('Sent Feedback', {
      Feeling: Analytics.mapFeelingToNumber(this.state.feeling),
    });

    fetch(
      'https://wt-billicsich-gmx-at-0.sandbox.auth0-extend.com/feedback-edge',
      {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          fields: {
            UserID: userID,
            Feeling: feeling,
            Thoughts: thoughts,
          },
        }),
      }
    )
      .then(response => response.json())
      .then(json => {
        if (json.id) {
          this.setState({
            ...this.state,
            sendingFeedback: false,
            showingThanks: true,
          });
          setTimeout(() => this.resetState(), 800);
        }
      });
  }

  render() {
    const { showingModal } = this.props;
    const { feeling, thoughts, sendingFeedback, showingThanks } = this.state;

    return (
      <>
        {/* <div
          className="feedback-corner"
          onClick={e => this.props.showFeedbackModal()}
        >
          <Icon type="smile" theme="filled" />
          <span>Feedback</span>
        </div> */}
        <Modal
          title="Give feedback"
          visible={showingModal}
          onOk={this.resetState}
          onCancel={this.resetState}
          footer={''}
          className="feedback-form"
        >
          <Form
            onFinish={() => {
              this.sendFeedbackToService();
            }}
          >
            <FormItem
              label="How are you feeling about what you tell us?"
              colon={false}
            >
              <RadioGroup
                size="large"
                onChange={e => this.handleInput('feeling', e.target.value)}
                value={feeling}
              >
                <RadioButton value="terrible" className="feedback-feeling">
                  <span role="img" aria-label="terrible">
                    😡
                  </span>
                </RadioButton>
                <RadioButton value="bad" className="feedback-feeling">
                  <span role="img" aria-label="bad">
                    ☹️
                  </span>
                </RadioButton>
                <RadioButton value="ok" className="feedback-feeling">
                  <span role="img" aria-label="ok">
                    😐
                  </span>
                </RadioButton>
                <RadioButton value="good" className="feedback-feeling">
                  <span role="img" aria-label="good">
                    😀
                  </span>
                </RadioButton>
                <RadioButton value="amazing" className="feedback-feeling">
                  <span role="img" aria-label="amazing">
                    😍
                  </span>
                </RadioButton>
              </RadioGroup>
            </FormItem>
            <FormItem label="Share your thoughts." colon={false}>
              <Input.TextArea
                rows={4}
                onChange={e => this.handleInput('thoughts', e.target.value)}
                placeholder="I like.... I don't like... There's a problem with... It would be great if..."
                value={thoughts}
              />
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                loading={sendingFeedback}
              >
                Send
              </Button>
              <>{showingThanks && <div>Thank you!</div>}</>
            </FormItem>
          </Form>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state: Types.All): FeedbackFormStateProps {
  return {
    userID: SessionSelectors.userID(state),
    showingModal: UISelectors.modalForFeedbackIsVisible(state),
  };
}

function mapDispatchToProps(
  dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>
): FeedbackFormDispatchProps {
  return {
    hideFeedbackModal: () => {
      dispatch(UIOperations.hideFeedbackModal());
    },
  };
}

export default Redux.connect(mapStateToProps, mapDispatchToProps)(FeedbackForm);
