import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import * as React from 'react';
import * as Redux from 'react-redux';
import * as Thunk from 'redux-thunk';
import * as Types from '../../State/types';
import { UIOperations, UISelectors } from '../../State/UserInterface';

export interface PageSwitcherProps {
}

class PageSwitcher extends React.Component<{
  previousIsDisabled: boolean;
  nextIsDisabled: boolean;
  nextPage: () => void;
  previousPage: () => void;
}> {
  render() {
    const {
      previousIsDisabled,
      nextIsDisabled,
      previousPage,
      nextPage,
    } = this.props;
    return (
      <Button.Group>
        <Button
          type="default"
          onClick={(e: React.MouseEvent) => previousPage()}
          disabled={previousIsDisabled}
        >
          <CaretLeftOutlined />
        </Button>
        <Button
          type="default"
          onClick={(e: React.MouseEvent) => nextPage()}
          disabled={nextIsDisabled}
        >
          <CaretRightOutlined />
        </Button>
      </Button.Group>
    );
  }
}

function mapStateToProps(state: Types.All) {
  const numberOfPages = UISelectors.numberOfPages(state);
  return {
    numberOfPages,
    previousIsDisabled: UISelectors.visiblePage(state) === 1,
    nextIsDisabled: UISelectors.visiblePage(state) >= numberOfPages,
  };
}

function mapDispatchToProps(
  dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>
) {
  return {
    previousPage: () => {
      dispatch(UIOperations.previousPage());
    },
    nextPage: () => {
      dispatch(UIOperations.nextPage());
    },
  };
}

export default Redux.connect(
  mapStateToProps,
  mapDispatchToProps
)(PageSwitcher);
