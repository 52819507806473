import * as Actions from './actions';
import { updateEntrySuccess } from './actions';
import {
  populateFromPersitance,
  receivedEntriesUpdate,
  editEntryAction,
  fetchEntriesSuccess,
  changeEntryInEditAction,
  cancelEditing,
  enablePersistance,
} from './actions';
import * as Types from '../types';
import * as Thunk from 'redux-thunk';
import * as APIPaths from '../../lib/apiPaths';
import { SessionOperations } from '../Session';
import moment from 'moment';
import Processor from '../../lib/PostProcess';
import { ContainersOperations, ContainersSelectors } from '../Containers';
import * as _ from 'lodash';
import * as Auth from '../../lib/webRequests';
import { warning } from '../../lib/errorAndLogging';

const EntriesOperations = {
  populateFromPersitance,
  receivedEntriesUpdate,
  initialFetch,
  fetchEntries,
  fetchEntriesSuccess,
  createEntry,
  editEntry,
  updateEntry,
  createOrUpdateEntryFromEntryInEdit,
  createOrUpdateEntryFromEntryInEditAndClose,
  deleteEntry,
  changeEntryInEdit,
  cancelEditing,
  enablePersistance,
};
export default EntriesOperations;

function initialFetch() {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ) {
    // the default fetch should not state a time, since the client may have a different time
    // than the server. If the client's time is behind the server's time, this would result
    // in recently created entries not showing up.
    return dispatch(fetchEntries());
  };
}

function fetchEntries(
  loggedBefore: moment.Moment | undefined = undefined,
  loggedSince: moment.Moment | undefined = undefined
) {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ) {
    dispatch(Actions.fetchEntriesRequest());

    // Fetch requests do not work in the test env
    if (process.env.NODE_ENV === 'test') {
      return dispatch(
        Actions.fetchEntriesSuccess({}, { loggedBefore: '', loggedSince: '' })
      );
    }
    let requestURL = APIPaths.fetchEntriesURL(
      getState().session.lastRequestAt,
      loggedBefore,
      loggedSince
    );

    return Auth.fetchWithCredentials(requestURL, undefined, null)
      .then((response) => response.json())
      .then((json) => {
        const processedEntries = Processor.entriesAPIToClient(json.entries);
        return dispatch(
          Actions.fetchEntriesSuccess(processedEntries, json.params)
        );
      })
      .catch(function (err: string) {
        global.console.log(
          '******* ERROR happended when fetching entries. ********'
        );
        global.console.log(err);
        return err;
      });
  };
}

// IMPORTANT: on `create` the server expects the collections param, otherwise
//    it would require too many requests and the delay for the bookmarklet would not
//    be acceptable
function createEntry(newEntry: Types.NewEntry) {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ): Promise<Types.AllActions> {
    const newCompleteEntry = {
      ...newEntry,
      url: newEntry.url,
      updated_at: moment(),
    };

    dispatch(Actions.createEntryRequest());

    return Auth.fetchWithCredentials(
      APIPaths.createEntryURL(getState().session.lastRequestAt),
      'POST',
      JSON.stringify(Processor.entryClientToAPI(newCompleteEntry))
    )
      .then((response) => response.json())
      .then((json) => {
        dispatchUpdatedModel(dispatch, json);

        const newlyCreatedEntry = Processor.entryAPItoClient(json.entry);
        return dispatch(Actions.createEntrySuccess(newlyCreatedEntry));
      })
      .catch(function (err: string) {
        global.console.log(
          '******* ERROR happended when creating entry. ********'
        );
        global.console.log(err);
        return dispatch(Actions.createEntryFailure([err]));
      });
  };
}

function editEntry(entryId: Types.ModelId | undefined) {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ) {
    const entryCollections = entryId
      ? ContainersSelectors.collectionsForEntry(getState(), entryId.toString())
      : [];
    dispatch(editEntryAction(entryId, entryCollections));
  };
}

function changeEntryInEdit(partialEntryInEdit: Partial<Types.EntryInEdit>) {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>
  ) {
    // Make sure collections are unique
    if (partialEntryInEdit.collections) {
      const uniquedCollections = _.uniq(partialEntryInEdit.collections);
      if (uniquedCollections.length !== partialEntryInEdit.collections.length) {
        warning('Something is adding double collections to entryInEdit');
      }
      partialEntryInEdit.collections = _.uniq(uniquedCollections);
    }

    return dispatch(changeEntryInEditAction(partialEntryInEdit));
  };
}

function createOrUpdateEntryFromEntryInEdit(withResponse: boolean = true) {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ): Promise<Types.AllActions | void> {
    const entryInEdit = getState().entries.entryInEdit;

    if (!entryInEdit) {
      return Promise.resolve();
    }

    const { id, title, description, url, collections } = entryInEdit;

    const oldCollections = ContainersSelectors.collectionsForEntry(
      getState(),
      id.toString()
    );
    const newCollections = collections;

    const addedCollections = _.difference(newCollections, oldCollections);
    const removedCollections = _.difference(oldCollections, newCollections);

    // if entry exists and is to be updated
    if (id && id !== '-1') {
      // The entry counts as updated even if there was only a change to the collections, so that
      //   the last-change date reflects the edit
      addedCollections.forEach((collectionId) =>
        dispatch(ContainersOperations.addEntryToCollection(id, collectionId))
      );
      removedCollections.forEach((collectionId) =>
        dispatch(
          ContainersOperations.removeEntryFromCollection(id, collectionId)
        )
      );

      return dispatch(updateEntry(id, title, description, url, []));
    } else {
      // if entry is created
      return dispatch(
        createEntry({
          id,
          title,
          description,
          url,
          collections: newCollections,
        })
      ).then((something) => {
        if (!withResponse) {
          return;
        }
        const successAction = something as ReturnType<
          typeof updateEntrySuccess
        >;
        global.console.log(successAction);
        // TODO: if an error happens on the server then this leads to a crash here
        addedCollections.forEach((collectionId) =>
          dispatch(
            ContainersOperations.addEntryToCollection(
              successAction.payload.updatedEntry.id,
              collectionId
            )
          )
        );
      });
    }
  };
}

function createOrUpdateEntryFromEntryInEditAndClose() {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>
  ) {
    return ((dispatch(
      createOrUpdateEntryFromEntryInEdit(false)
    ) as unknown) as Promise<Types.AllActions>).then(() => {
      window.close();
    });
  };
}

function updateEntry(
  entryID: Types.ModelId,
  title: string,
  description: string,
  url: string,
  collections: string[]
) {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ): Promise<Types.AllActions> {
    let newEntryProps = {
      entryID,
      title,
      description,
      url,
      collections,
    };
    let processedEntry = Processor.entryClientToAPI(newEntryProps);
    dispatch(Actions.updateEntryRequest(processedEntry));

    return Auth.fetchWithCredentials(
      APIPaths.updateEntryURL(entryID, getState().session.lastRequestAt),
      'PATCH',
      JSON.stringify(processedEntry)
    )
      .then((response) => response.json())
      .then((json) => {
        dispatchUpdatedModel(dispatch, json);

        return dispatch(
          Actions.updateEntrySuccess(Processor.entryAPItoClient(json.entry))
        );
      })
      .catch(function (err: string) {
        global.console.log(
          '******* ERROR happended when creating entry. ********'
        );
        global.console.log(err);
        return dispatch(Actions.updateEntryFailure(entryID, [err]));
      });
  };
}

function deleteEntry(entryID: Types.ModelId) {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ) {
    const containersToModify = ContainersSelectors.collectionsForEntry(
      getState(),
      entryID.toString()
    );

    dispatch(Actions.deleteEntryRequest(entryID));

    return Auth.fetchWithCredentials(
      APIPaths.deleteEntryURL(entryID, getState().session.lastRequestAt),
      'DELETE',
      JSON.stringify({ collections: containersToModify })
    )
      .then((response) => response.json())
      .then((json) => {
        dispatchUpdatedModel(dispatch, json);

        return dispatch(Actions.deleteEntrySuccess(entryID));
      })
      .catch(function (err: string) {
        return dispatch(Actions.deleteEntryFailure(entryID, [err]));
      });
  };
}

const dispatchUpdatedModel = (
  dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
  json: Types.DefaultResponse
) => {
  const containerById = Processor.containersAPIToClient(json.containers);
  dispatch(
    ContainersOperations.receivedContainerUpdate(
      containerById,
      json.deleted_container_ids.map((id: number) => id.toString())
    )
  );
  const processedEntries = Processor.entriesAPIToClient(json.entries);
  dispatch(
    receivedEntriesUpdate(
      processedEntries,
      json.deleted_entry_ids.map((id: number) => id.toString())
    )
  );
  dispatch(SessionOperations.saveLastRequestDate(moment(json.response_time)));
};
