import {
  renderMoreElements,
  renderDefaultNumberOfElements,
  showBookmarkletHint,
  hideBookmarkletHint,
  showIntegrationsHint,
  hideIntegrationsHint,
  showFeedbackModal,
  hideFeedbackModal,
  setPage,
  updateSearchPhrase,
  showEditEntryView,
  hideEditEntryView,
  selectContainer,
  setSidebarDrawerVisibility,
  setVisibleContainerModal,
} from './actions';
import { EntriesOperations } from '../Entries';
import * as Types from '../types';
import * as Thunk from 'redux-thunk';
import { default as UISelectors } from './selectors';

const UserInterfaceOperations = {
  nextPage,
  previousPage,
  searchWithPhrase,
  renderMoreElements,
  renderDefaultNumberOfElements,
  showBookmarkletHint,
  hideBookmarkletHint,
  showIntegrationsHint,
  hideIntegrationsHint,
  showFeedbackModal,
  hideFeedbackModal,
  hideEditEntryView,
  startEditingEntry,
  selectContainer,
  setSidebarDrawerVisibility,
  setVisibleContainerModal,
};
export default UserInterfaceOperations;

function nextPage(): Thunk.ThunkAction<
  Types.AllActions,
  Types.All,
  undefined,
  Types.AllActions
> {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ) {
    let nextPageNumber = getState().userInterface.visiblePage + 1;
    return dispatch(setPage(nextPageNumber));
  };
}

function previousPage() {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ) {
    let previousPageNumber = getState().userInterface.visiblePage - 1;
    return dispatch(setPage(previousPageNumber));
  };
}

function searchWithPhrase(searchPhrase: string) {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ) {
    return dispatch(searchWithPhraseAndTags(searchPhrase, undefined));
  };
}

function searchWithPhraseAndTags(
  searchPhrase: string | undefined,
  searchTags: string[] | undefined
) {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ) {
    searchPhrase =
      searchPhrase !== undefined
        ? searchPhrase
        : UISelectors.searchPhraseSelector(getState());

    dispatch(updateSearchPhrase(searchPhrase));
    return dispatch(renderDefaultNumberOfElements());
  };
}

function startEditingEntry(entryID: undefined | Types.ModelId) {
  return function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ) {
    dispatch(showEditEntryView());
    dispatch(EntriesOperations.editEntry(entryID));
  };
}
