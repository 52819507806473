import { AppstoreTwoTone, ClockCircleTwoTone, FolderTwoTone } from '@ant-design/icons';
// import { Icon } from '@ant-design/compatible';
import { Input, Tree } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Thunk from 'redux-thunk';
import * as Keys from '../../lib/keys';
import { ContainersSelectors } from '../../State/Containers';
import * as Types from '../../State/types';
import './ContainerView.css';

const TreeNode = Tree.TreeNode;

export interface ContainerSelectViewProps {
  limitSelectionTo: Types.ContainerType | undefined;
  disabledContainerIds?: string[];
  autofocus?: boolean;
  visible?: boolean;
  onSelect: (selectedContainerId: Types.StringModelId | undefined) => void;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export interface ContainerSelectViewStateProps {
  disabledContainerIds?: string[];
  recentCollections: Types.ContainerTreeNode[];
  limitSelectionTo: Types.ContainerType | undefined;
  autofocus?: boolean;
  visible?: boolean;
  containerTreeSearch: (
    searchPhrase: string
  ) => {
    tree: Types.ContainerTreeNode;
    matchingIds: string[];
    matchingPaths: string[];
  };
  pathForContainerSelector: (containerId: string) => string;
}

export interface ContainerSelectViewDispatchProps {
  onSelect: (selectedContainerId?: Types.StringModelId) => void;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export interface ContainerSelectViewInnerProps
  extends ContainerSelectViewStateProps,
    ContainerSelectViewDispatchProps {}

function ContainerSelectView(props: ContainerSelectViewInnerProps) {
  const inputFieldForSearch: React.RefObject<Input> = React.createRef();

  const {
    disabledContainerIds,
    recentCollections,
    limitSelectionTo,
    visible,
    autofocus,
    containerTreeSearch,
    pathForContainerSelector,
    onSelect,
    onSubmit,
    onCancel,
  } = props;

  const [searchPhrase, setSearchPhrase] = useState('');
  const [manuallyExpandedKeys, setManuallyExpandedKeys] = useState<string[]>(
    []
  );

  // Select the input field, when the modal re-appears
  const [isVisible, setIsVisible] = useState(visible);
  useEffect(() => {
    setIsVisible(visible);
    if (isVisible !== visible) {
      inputFieldForSearch.current?.select();
    } else {
    }
  }, [inputFieldForSearch, isVisible, visible]);

  const {
    tree: containerTree,
    matchingPaths,
    matchingIds,
  } = containerTreeSearch(searchPhrase);

  const [selectedKey, setSelectedKey] = useState<string[]>([]);

  const handleSelect = (selectedContainerId: string) => {
    onSelect(selectedContainerId);
    inputFieldForSearch.current?.select();
  };

  const handleSpecialKeyInInputField = (
    keyCode: number,
    modifierKeys?: string[]
  ) => {
    let currentlySelectedIndex =
      selectedKey.length > 0 ? matchingIds.indexOf(selectedKey[0]) : -1;

    if (keyCode === Keys.KEY_ESC && currentlySelectedIndex > -1) {
      currentlySelectedIndex = -1;
    } else if (keyCode === Keys.KEY_ESC && currentlySelectedIndex === -1) {
      onCancel && onCancel();
    } else if (keyCode === Keys.KEY_ENTER && modifierKeys?.includes('meta')) {
      onSubmit && onSubmit();
    } else if (
      (keyCode === Keys.KEY_ENTER || keyCode === Keys.KEY_TAB) &&
      currentlySelectedIndex > -1
    ) {
      handleSelect(selectedKey[0]);
      currentlySelectedIndex = -1;
    } else if (
      (keyCode === Keys.KEY_ENTER || keyCode === Keys.KEY_TAB) &&
      currentlySelectedIndex === -1
    ) {
      onSubmit && onSubmit();
    } else if (
      currentlySelectedIndex === matchingIds.length - 1 &&
      keyCode === Keys.KEY_DOWN
    ) {
      // don't change
    } else if (currentlySelectedIndex >= 0 && keyCode === Keys.KEY_DOWN) {
      currentlySelectedIndex += 1;
    } else if (currentlySelectedIndex > 0 && keyCode === Keys.KEY_UP) {
      currentlySelectedIndex -= 1;
    } else if (currentlySelectedIndex < 0 && keyCode === Keys.KEY_DOWN) {
      currentlySelectedIndex = 0;
    } else if (currentlySelectedIndex === 0 && keyCode === Keys.KEY_DOWN) {
      currentlySelectedIndex = -1;
    } else {
      currentlySelectedIndex = -1;
    }

    setSelectedKey(
      currentlySelectedIndex > -1 ? [matchingIds[currentlySelectedIndex]] : []
    );
  };

  const makeTreeNode = (
    container: Types.ContainerTreeNode,
    limitSelectionTo: Types.ContainerType | undefined,
    disabledContainerIds: string[] | undefined,
    pathForContainerSelector?: (containerId: string) => string
  ) => {
    const showFullPathForName = !!pathForContainerSelector;

    const { id, name, icon, containerType } = container;
    return (
      <TreeNode
        key={id}
        active={true}
        icon={
          icon === 'default' ? (
            containerType === 'folder' ? (
              <FolderTwoTone twoToneColor="#3b568c" />
            ) : (
              <AppstoreTwoTone twoToneColor="#3b568c" />
            )
          ) : (
            // This is a hack! Icons needs to change according
            //  to the string, antd 4.0 made this significantly hard
            <AppstoreTwoTone twoToneColor="#3b568c" />
          )
        }
        title={
          showFullPathForName && pathForContainerSelector
            ? pathForContainerSelector(id)
            : name
        }
        className={containerType}
        selectable={
          limitSelectionTo ? limitSelectionTo === containerType : true
        }
        isLeaf={containerType === Types.ContainerType.COLLECTION}
        disabled={
          disabledContainerIds ? disabledContainerIds.includes(id) : false
        }
      >
        {containerType === Types.ContainerType.FOLDER && container.children
          ? container.children.map((subContainer) =>
              makeTreeNode(subContainer, limitSelectionTo, disabledContainerIds)
            )
          : null}
      </TreeNode>
    );
  }

  return (
    <div className="ContainerSelectView">
      <div className="input_div">
        <Input
          ref={inputFieldForSearch}
          value={searchPhrase === ' ' ? '' : searchPhrase}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const searchPhrase = event.target.value;
            // Prevent space as first char from slipping into the search
            if (searchPhrase !== ' ') {
              setSearchPhrase(event.target.value);
            } else {
              // The phrase needs to be set because the space bar could have been
              //   pressed when the whole input was selected and thus serve as input
              //   delete
              setSearchPhrase('');
            }
          }}
          placeholder={
            limitSelectionTo
              ? `Search your ${limitSelectionTo}s`
              : `Search and select collections`
          }
          allowClear={true}
          autoComplete="false"
          autoCorrect="false"
          spellCheck={false}
          autoFocus={autofocus}
          onKeyDown={(e) => {
            if (
              e.metaKey &&
              e.keyCode === Keys.KEY_ENTER &&
              navigator.platform.indexOf('Mac') !== -1
            ) {
              e.preventDefault();
              handleSpecialKeyInInputField(Keys.KEY_ENTER, ['meta']);
            } else if (
              e.keyCode === Keys.KEY_ENTER &&
              searchPhrase.trim().length === 0
            ) {
              onSubmit && onSubmit();
            } else if (e.keyCode === Keys.KEY_ENTER) {
              e.preventDefault();
              handleSpecialKeyInInputField(e.keyCode);
            } else if (
              e.keyCode === Keys.KEY_UP ||
              e.keyCode === Keys.KEY_DOWN ||
              e.keyCode === Keys.KEY_ESC ||
              e.keyCode === Keys.KEY_TAB
            ) {
              e.preventDefault();
              handleSpecialKeyInInputField(e.keyCode);
            } else if (
              e.ctrlKey &&
              e.keyCode === Keys.KEY_N &&
              navigator.platform.indexOf('Mac') !== -1
            ) {
              e.preventDefault();
              handleSpecialKeyInInputField(Keys.KEY_DOWN);
            } else if (
              e.ctrlKey &&
              e.keyCode === Keys.KEY_P &&
              navigator.platform.indexOf('Mac') !== -1
            ) {
              e.preventDefault();
              handleSpecialKeyInInputField(Keys.KEY_UP);
            }
          }}
        />
      </div>
      <div className="container_tree">
        {/* Recent collections */}
        {limitSelectionTo === Types.ContainerType.COLLECTION &&
          recentCollections.length > 0 && (
            <Tree
              onSelect={(selectedKeys) =>
                selectedKeys.length > 0 && handleSelect(`${selectedKeys[0]}`)
              }
              showIcon={true}
              defaultExpandAll={true}
            >
              <TreeNode
                key="recent"
                active={true}
                selectable={false}
                icon={<ClockCircleTwoTone twoToneColor="#3b8c56" />}
                title="Recently used"
              >
                {recentCollections.map((container) =>
                  makeTreeNode(
                    container,
                    limitSelectionTo,
                    disabledContainerIds,
                    pathForContainerSelector
                  )
                )}
              </TreeNode>
            </Tree>
          )}
        {/* Full tree */}
        <Tree
          onSelect={(selectedKeys) =>
            selectedKeys.length > 0 && handleSelect(`${selectedKeys[0]}`)
          }
          showIcon={true}
          expandedKeys={matchingPaths.concat(manuallyExpandedKeys)}
          selectedKeys={selectedKey}
          onExpand={(expandedKeys, info) => {
            const expandedId = `${info.node.key}`;
            if (!expandedId) {
              return;
            }
            const newKeys = info.expanded
              ? _.uniq(manuallyExpandedKeys.concat(expandedId))
              : _.without(manuallyExpandedKeys, expandedId);
            global.console.log(newKeys);
            setManuallyExpandedKeys(newKeys);
          }}
        >
          {containerTree.children.map((container) =>
            makeTreeNode(container, limitSelectionTo, disabledContainerIds)
          )}
        </Tree>
      </div>
    </div>
  );
}

function mapStateToProps(
  state: Types.All,
  ownProps: ContainerSelectViewProps
): ContainerSelectViewStateProps {
  return {
    limitSelectionTo: ownProps.limitSelectionTo,
    containerTreeSearch: ContainersSelectors.containerTreeForSearchPhaseWithMatchingIdsCreator(
      state
    ),
    recentCollections: ContainersSelectors.recentCollectionsTree(state).slice(
      0,
      5
    ),
    autofocus: ownProps.autofocus,
    visible: ownProps.visible,
    disabledContainerIds: ownProps.disabledContainerIds,
    pathForContainerSelector: (containerId) => ContainersSelectors.treePathFor(state, containerId),
  };
}

function mapDispatchToProps(
  dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
  ownProps: ContainerSelectViewProps
): ContainerSelectViewDispatchProps {
  return {
    onSelect: ownProps.onSelect,
    onSubmit: ownProps.onSubmit,
    onCancel: ownProps.onCancel,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainerSelectView);
