import * as Redux from 'react-redux';
import * as Thunk from 'redux-thunk';
import { ContainersSelectors } from '../../../State/Containers';
import { EntriesOperations, EntriesSelectors } from '../../../State/Entries';
import * as Types from '../../../State/types';
import { UIOperations } from '../../../State/UserInterface';
import * as EditEntryFormInline from './EditEntryFormInline';

interface EditEntryFormInlineProps {
  title?: string;
  description?: string;
  url?: string;
  bookmarklet?: boolean;
}

function mapStateToProps(
  state: Types.All,
): EditEntryFormInline.EditEntryFormStateProps {
  return {
    entryInEdit: EntriesSelectors.entryInEdit(state),
    errors: [],
    containerTree: ContainersSelectors.containerTree(state),
  };
}

function mapDispatchToProps(
  dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
  props: EditEntryFormInlineProps
): EditEntryFormInline.EditEntryFormDispatchProps {
  props = props || {
    title: '',
    url: '',
    description: '',
    bookmarklet: false,
  };
  let bookmarklet = props.bookmarklet;

  return {
    changeEntryInEdit: (partialEntryInEdit) => {
      dispatch(EntriesOperations.changeEntryInEdit(partialEntryInEdit));
    },
    cancelEditing: () => {
      dispatch(UIOperations.hideEditEntryView());
      if (bookmarklet) {
        window.close();
      }
    },
    updateEntry: () => {
      dispatch(UIOperations.hideEditEntryView());

      if (bookmarklet) {
        dispatch(
          EntriesOperations.createOrUpdateEntryFromEntryInEditAndClose()
        );
      } else {
        dispatch(EntriesOperations.createOrUpdateEntryFromEntryInEdit());
      }
    },
    handleDeleteEntry: (entryID) => {
      dispatch(UIOperations.hideEditEntryView());
      dispatch(EntriesOperations.deleteEntry(entryID));
    },
  };
}

export default Redux.connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEntryFormInline.default);
