import * as React from 'react';

class ErrorBoundary extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = { hasError: false };
  // }

  static getDerivedStateFromError(error: Error | null) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error | null, errorInfo: object) {
    // You can also log the error to an error reporting service
    console.error(
      '🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧 ERROR BOUNDARY 🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧🔧'
    );
    console.error(error, errorInfo);
  }

  render() {
    // if (this.state.hasError) {
    //   // You can render any custom fallback UI
    //   return <h1>Something went wrong.</h1>;
    // }

    return this.props.children;
  }
}

export default ErrorBoundary;