/* eslint-disable jsx-a11y/anchor-is-valid */
import { CheckOutlined, MessageOutlined } from '@ant-design/icons';
import { Card, Col } from 'antd';
import * as React from 'react';
import * as Redux from 'react-redux';
import * as Thunk from 'redux-thunk';
import Analytics from '../../lib/analytics';
import * as Types from '../../State/types';
import { UIOperations, UISelectors } from '../../State/UserInterface';

export enum StateType {
  noEntriesLoggedYet,
  noEntriesLoggedYetInInbox,
  inboxEmpty,
}

interface EmptyStatesInternalProps {
  stateType: StateType;
  initialFetchIsDone: boolean;
  showBookmarkletHint: () => void;
  showIntegrationsHint: () => void;
}

export interface EmptyStatesProps {
  stateType: StateType;
}

function EmptyStates({
  stateType,
  initialFetchIsDone,
  showBookmarkletHint,
  showIntegrationsHint,
}: EmptyStatesInternalProps) {
  if (!initialFetchIsDone) {
    return <span />;
  }

  switch (stateType) {
    case StateType.noEntriesLoggedYetInInbox:
      return noEntriesLoggedYetInInbox(
        showBookmarkletHint,
        showIntegrationsHint
      );
    case StateType.noEntriesLoggedYet:
      return noEntriesLoggedYet(showBookmarkletHint, showIntegrationsHint);
    case StateType.inboxEmpty:
      return inboxEmpty();
    default:
      return <span />;
  }
}

function noEntriesLoggedYet(
  showBookmarkletHint: () => void,
  showIntegrationsHint: () => void
) {
  return noEntryEmptyState(
    'All your entries will live here',
    <>
      <p>
        The quickest way to add entries while your browsing is the{' '}
        <a onClick={showBookmarkletHint}>bookmarklet</a>.
      </p>
      <p>
        To have <em>unconfuse</em> collect entries automatically while you're
        active in other services, the{' '}
        <a onClick={showIntegrationsHint}>integration with Zapier</a> is great.
      </p>
    </>
  );
}

function noEntriesLoggedYetInInbox(
  showBookmarkletHint: () => void,
  showIntegrationsHint: () => void
) {
  return noEntryEmptyState(
    'This is your Inbox',
    <>
      <p>
        All entries that are added - via{' '}
        <a onClick={showBookmarkletHint}>bookmarklet</a> or from{' '}
        <a onClick={showIntegrationsHint}>other services</a> - land here.
      </p>
      <p>
        Visit regularly to see what you've been doing and sort your entries.
      </p>
    </>
  );
}

function inboxEmpty() {
  return (
    <>
      <Col>
        <Card bordered={false}>
          <Card.Meta
            title="Inbox empty"
            description={`You're all done. Go and explore your collection
            or browse the internets and find interesting stuff.`}
            avatar={
              <CheckOutlined
                style={{
                  fontSize: 44,
                  padding: 8,
                  opacity: 0.65,
                  filter: 'grayscale(100%)',
                }}
              />
            }
          />
        </Card>
      </Col>
    </>
  );
}

function mapStateToProps(state: Types.All, ownProps: EmptyStatesProps) {
  return {
    stateType: ownProps.stateType,
    initialFetchIsDone: UISelectors.initialFetchIsDone(state),
  };
}

function mapDispatchToProps(
  dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>
) {
  return {
    showBookmarkletHint: () => {
      dispatch(UIOperations.showBookmarkletHint());
      Analytics.track('Shows Bookmarklet Hint');
    },
    showIntegrationsHint: () => {
      dispatch(UIOperations.showIntegrationsHint());
    },
  };
}

export default Redux.connect(mapStateToProps, mapDispatchToProps)(EmptyStates);

// Helper function
function noEntryEmptyState(title: string, description: React.ReactNode) {
  return (
    <>
      <Col
        style={{
          marginTop: '10vh',
          textAlign: 'left',
          padding: 8,
          border: '1px rgb(250,250,250)',
          background: 'rgba(255,255,255,0.55)',
          borderRadius: 5,
          color: '#9e9e9e',
        }}
        sm={{
          span: 10,
          offset: 2,
        }}
      >
        <Card>
          <Card.Meta
            avatar={
              <MessageOutlined
                style={{
                  fontSize: 44,
                  padding: 8,
                  opacity: 0.65,
                  filter: 'grayscale(100%)',
                }}
              />
            }
            title={title}
            description={description}
          />
        </Card>
      </Col>
    </>
  );
}
