import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import * as React from 'react';
import * as Redux from 'react-redux';
import * as Thunk from 'redux-thunk';
import { SessionSelectors } from '../../State/Session';
import * as Types from '../../State/types';
import { MenuItem, UIOperations, UISelectors } from '../../State/UserInterface';
import BookmarkletHint from '../UserGuidance/BookmarkletHint';
import IntegrationHint from '../UserGuidance/IntegrationHint';
import './MainTopMenu.css';
import PageSwitcher from './PageSwitcher';
import SearchField from './SearchField';

interface MainMenuStateProps {
  initialFetchDone: boolean;
  activeMenuItem: MenuItem;
  userEmail: string;
  sidebarDrawerIsVisible: boolean;
}

interface MainMenuDispatchProps {
  setDrawerVisibility: (isVisible: boolean) => void;
}

interface MainMenuProps extends MainMenuStateProps, MainMenuDispatchProps {}

function MainMenu(props: MainMenuProps) {
  const { sidebarDrawerIsVisible, setDrawerVisibility } = props;
  return (
    <>
      <BookmarkletHint />
      <IntegrationHint />
      <Button
        className={`sidebar-drawer-button ${
          sidebarDrawerIsVisible ? 'translated' : ''
        }`}
        onClick={() => {
          setDrawerVisibility(!sidebarDrawerIsVisible);
        }}
      >
        {sidebarDrawerIsVisible ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
      </Button>

      <Row className="menu_bar menu_bar_controls">
        <Col className="search_bar_col">
          <SearchField />
        </Col>
        <Col className="page_switcher_col">
          <PageSwitcher />
        </Col>
      </Row>
    </>
  );
}

function mapStateToProps(state: Types.All): MainMenuStateProps {
  return {
    initialFetchDone: UISelectors.initialFetchIsDone(state),
    activeMenuItem: UISelectors.activeMenuItem(state),
    userEmail: SessionSelectors.userEmail(state),
    sidebarDrawerIsVisible: UISelectors.sidebarDrawerIsVisible(state),
  };
}

function mapDispatchToProps(
  dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>
): MainMenuDispatchProps {
  return {
    setDrawerVisibility: (isVisible: boolean) => {
      dispatch(UIOperations.setSidebarDrawerVisibility(isVisible));
    },
  };
}

export default Redux.connect(mapStateToProps, mapDispatchToProps)(MainMenu);
