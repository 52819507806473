import { Input } from 'antd';
import * as React from 'react';
import * as Redux from 'react-redux';
import * as Thunk from 'redux-thunk';
import * as Types from '../../State/types';
import { UIOperations, UISelectors } from '../../State/UserInterface';
import './SearchField.css';

export interface SearchFieldStateProps {
  searchPhrase: string;
}

export interface SearchFieldDispatchProps {
  handleSearchPhraseUpdate: (newSearchPhrase: string) => void;
  handleTagClick: (tag: string) => void;
}

export interface SearchFieldProps
  extends SearchFieldStateProps,
    SearchFieldDispatchProps {}

function SearchField({
  searchPhrase,
  handleSearchPhraseUpdate,
}: SearchFieldProps) {
  return (
    <Input.Search
      id="search"
      placeholder="Search for tags or search entries in title, url, description"
      autoFocus={true}
      autoComplete="off"
      autoCorrect="false"
      spellCheck={false}
      name="search"
      enterButton={true}
      onChange={e => {
        e.preventDefault();
        let newSearchPhrase = e.target.value;
        return handleSearchPhraseUpdate(newSearchPhrase || '');
      }}
      value={searchPhrase}
      allowClear={true}
    />
  );
}

function mapStateToProps(
  state: Types.All,
  ownProps: object
): SearchFieldStateProps {
  return {
    searchPhrase: UISelectors.searchPhraseSelector(state),
  };
}

function mapDispatchToProps(
  dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>
): SearchFieldDispatchProps {
  return {
    handleSearchPhraseUpdate: searchPhrase =>
      dispatch(UIOperations.searchWithPhrase(searchPhrase)),
    handleTagClick: tag => {
      dispatch(UIOperations.searchWithPhrase(''));
    },
  };
}

export default Redux.connect(mapStateToProps, mapDispatchToProps)(SearchField);
