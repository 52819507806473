import { CheckOutlined, EditOutlined, LinkOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import * as Redux from 'react-redux';
import * as Thunk from 'redux-thunk';
import * as Keys from '../lib/keys';
import { ContainersSelectors } from '../State/Containers';
import { EntriesOperations, EntriesSelectors } from '../State/Entries';
import * as Types from '../State/types';
import { UIOperations } from '../State/UserInterface';
import './Bookmarklet.css';
import CollectionListView from './Containers/CollectionListView';
import ContainerSelectView from './Containers/ContainerSelectView';
import './ContentView/Timeline/EditEntryForm.css';
import EditContainerModal from './Molecules/EditContainerModal';
import SelectContainerModal from './Molecules/SelectContainerModal';

const FormItem = Form.Item;

export interface BookmarkletFormStateProps {
  errors: string[];
  entryInEdit: Types.EntryInEdit | undefined;
  containerTree: Types.ContainerTreeNode;
  entryForURLSelector: (url: string) => Types.Entry | undefined;
}

export interface BookmarkletFormDispatchProps {
  changeEntryInEdit: (entryInEditing: Partial<Types.EntryInEdit>) => void;
  editEntry: (entryId: Types.StringModelId) => void;
  cancelEditing: () => void;
  saveEntry: () => void;
}

export interface BookmarkletFormProps
  extends BookmarkletFormStateProps,
    BookmarkletFormDispatchProps {}

function Bookmarklet(props: BookmarkletFormProps) {
  const {
    entryInEdit,
    changeEntryInEdit,
    editEntry,
    cancelEditing,
    saveEntry,
    entryForURLSelector,
  } = props;

  const [isEditingTitleAndURL, setIsEditingTitleAndURL] = useState(false);
  const [parentContainerId, setParentContainerId] = useState<
    string | undefined
  >();
  const [
    isShowingNewContainerParentSelection,
    setIsShowingNewContainerParentSelection,
  ] = useState(false);
  const [
    isShowingNewContainerDetails,
    setIsShowingNewContainerDetails,
  ] = useState(false);

  // To determine if the page has already been saved previously
  //  and in the moment of determination, overwrite the local state
  //  with the previously saved information
  const [isExistingEntryState, setIsExistingEntryState] = useState(false);
  const existingEntry =
    entryInEdit && entryInEdit.url
      ? entryForURLSelector(entryInEdit.url)
      : undefined;
  useEffect(() => {
    if (existingEntry && !isExistingEntryState) {
      editEntry(existingEntry.id);
      setIsExistingEntryState(true);
    }
  }, [existingEntry, isExistingEntryState, editEntry, changeEntryInEdit]);

  let search: string = window.location.search || '';
  if (!entryInEdit) {
    if (search.startsWith('?')) {
      let paramsSplit = search.split('&');
      changeEntryInEdit({
        url: decodeURIComponent(paramsSplit[0].split('=')[1]),
        description: decodeURIComponent(paramsSplit[1].split('=')[1]),
        title: decodeURIComponent(paramsSplit[2].split('=')[1]),
      });
      return null;
    } else {
      global.console.error('No entryInEdit and no params');
      return <>Missing parameters for Bookmarklet</>;
    }
  }

  const { title, url, description, collections } = entryInEdit;

  const basicEntryInfo = (
    <>
      {isEditingTitleAndURL ? (
        <>
          <Col style={{ width: 'calc(100% - 34px)' }} xs={22}>
            {/* # Entry basic info edit # */}
            {/* # Title Input # */}
            <FormItem>
              <Input
                name="title"
                placeholder="Title"
                value={title}
                autoFocus={true}
                onChange={(e) => changeEntryInEdit({ title: e.target.value })}
                onKeyDown={(e) => {
                  if (e.keyCode === Keys.KEY_ENTER) {
                    e.preventDefault();
                    setIsEditingTitleAndURL(false);
                  }
                }}
              />
            </FormItem>
            {/* # URL Input # */}
            <FormItem className="url">
              <Input
                name="url"
                size="small"
                placeholder="https://interesting/page.htm"
                value={url}
                addonBefore={<LinkOutlined />}
                onChange={(e) => changeEntryInEdit({ url: e.target.value })}
                onKeyDown={(e) => {
                  if (e.keyCode === Keys.KEY_ENTER) {
                    e.preventDefault();
                    setIsEditingTitleAndURL(false);
                  }
                }}
              />
            </FormItem>
          </Col>
          <Col style={{ width: '34px', textAlign: 'right' }} xs={2}></Col>
        </>
      ) : (
        <>
          {/* # Entry basic info presentation # */}
          <span
            className="title"
            style={{ fontWeight: 'bold', lineHeight: '12px' }}
          >
            {title}
          </span>
          <br />
          <span
            className="url"
            style={{
              fontStyle: 'italic',
              fontSize: 11,
              color: 'gray',
              lineHeight: '12px',
            }}
          >
            {url}
          </span>
        </>
      )}
    </>
  );

  return (
    <div className="Bookmarklet">
      <div className="EditEntryForm">
        <div className="flex-fixed">
          {basicEntryInfo}
          {/* # Description Input # */}
          <Input
            placeholder="Enter a description for this entry (it really helps to remember later)"
            value={description}
            // prefix={<PushpinOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            // autoSize={{ minRows: 1, maxRows: 6 }}
            onChange={(e) => changeEntryInEdit({ description: e.target.value })}
            className="description_input"
            name="description"
            autoFocus={true}
            size="small"
            onKeyDown={(e) => {
              if (
                e.keyCode === Keys.KEY_ENTER &&
                e.metaKey &&
                navigator.platform.indexOf('Mac') !== -1
              ) {
                e.preventDefault();
                saveEntry();
              }
            }}
          />
        </div>
        <div className="edit_buttons">
          {isEditingTitleAndURL ? (
            <Button
              icon={<CheckOutlined />}
              className="secondary"
              onClick={() => setIsEditingTitleAndURL(false)}
            />
          ) : (
            <Button
              className="secondary"
              onClick={() => setIsEditingTitleAndURL(true)}
              icon={<EditOutlined />}
            >
              Edit{' '}
            </Button>
          )}
        </div>
        {/* # Collections Listing # */}
        <div className="flex-fixed">
          <CollectionListView
            collectionIds={collections}
            onRemove={(removeId) =>
              changeEntryInEdit({
                collections: collections.filter(
                  (collId) => collId !== removeId
                ),
              })
            }
          />
        </div>
        {/* # Collections Selection # */}
        <ContainerSelectView
          limitSelectionTo={Types.ContainerType.COLLECTION}
          disabledContainerIds={collections}
          onSelect={(collectionId) => {
            if (collectionId) {
              changeEntryInEdit({
                collections: collections.concat(collectionId),
              });
            }
          }}
          onSubmit={() => saveEntry()}
          autofocus={true}
        />
        {/* # Action Buttons # */}
        <div className="action_buttons">
          <Button
            className="secondary add_collection"
            icon={<PlusOutlined />}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              setIsShowingNewContainerParentSelection(true);
            }}
          >
            Collection
          </Button>
          <div className="save_cancel_group">
            <Button type="link" onClick={() => cancelEditing()}>
              Cancel
            </Button>
            &nbsp;&nbsp;
            <Button onClick={saveEntry} type="primary">
              {isExistingEntryState ? `Update entry` : `Save entry`}
            </Button>
          </div>
        </div>
      </div>
      {/* Flow for new collection */}
      {/* 01 Parent for the new collection */}
      <SelectContainerModal
        title={`Select collection to add`}
        visible={isShowingNewContainerParentSelection}
        onCancel={() => setIsShowingNewContainerParentSelection(false)}
        className=""
        limitSelectionTo={Types.ContainerType.FOLDER}
        onSelect={(selectedId) => {
          setParentContainerId(selectedId);
          setIsShowingNewContainerParentSelection(false);
          setIsShowingNewContainerDetails(true);
        }}
        containersToDisable={[]}
      />
      {/* 02 Details for the new collection */}
      <EditContainerModal
        container={undefined}
        parentContainerId={parentContainerId}
        visible={isShowingNewContainerDetails}
        className=""
        title="Details for new collection"
        afterSave={(containerId) => {
          changeEntryInEdit({
            collections: collections.concat(containerId),
          });
          setParentContainerId(undefined);
          setIsShowingNewContainerDetails(false);
        }}
        onCancel={() => {
          setParentContainerId(undefined);
          setIsShowingNewContainerDetails(false);
        }}
      />
    </div>
  );
}

function mapStateToProps(state: Types.All): BookmarkletFormStateProps {
  return {
    errors: [],
    entryInEdit: EntriesSelectors.entryInEdit(state),
    containerTree: ContainersSelectors.containerTree(state),
    entryForURLSelector: (url) => EntriesSelectors.entryForURL(state, url),
  };
}

function mapDispatchToProps(
  dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>
): BookmarkletFormDispatchProps {
  return {
    changeEntryInEdit: (entryInEditing: Partial<Types.EntryInEdit>) =>
      dispatch(EntriesOperations.changeEntryInEdit(entryInEditing)),
    editEntry: (entryId: Types.StringModelId) => {
      dispatch(EntriesOperations.editEntry(entryId));
    },
    cancelEditing: () => {
      dispatch(UIOperations.hideEditEntryView());
      dispatch(EntriesOperations.cancelEditing());
      window.close();
    },
    saveEntry: () => {
      dispatch(UIOperations.hideEditEntryView());
      dispatch(EntriesOperations.createOrUpdateEntryFromEntryInEditAndClose());
    },
  };
}

export default Redux.connect(mapStateToProps, mapDispatchToProps)(Bookmarklet);
