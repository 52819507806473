import * as Types from '../types';
import * as Search from '../../lib/search';
import {
  visiblePage,
  pageSize,
  searchPhraseSelector,
} from '../UserInterface/selectors';
import _ from 'lodash';
import { ContainersSelectors } from '../Containers';
import { UISelectors } from '../UserInterface';
import { createSelector } from 'reselect';

// TKAB 2017-09-16: The following statement can not be used, because I seem to have
//   an incomplete understanding of export/import functionality in ES6. Therefore
//   the line below it is here. If you know how to improve this, please do!
// import { UISelectors } from '../UserInterface';
// import { default as UISelectors } from '../UserInterface/selectors';

const entriesById = (state: Types.All) => state.entries.byId;
const entriesAllIds = (state: Types.All) => state.entries.allIds;

const allEntries = createSelector(entriesById, entriesAllIds, (byId, allIds) =>
  allIds.map((id) => byId[id])
);

export function entryForID(state: Types.All, entryID: Types.ModelId) {
  return state.entries.byId[entryID];
}

function entryForURL(state: Types.All, URL: string): Types.Entry | undefined {
  const entryId = state.entries.allIds.find(
    (id) => state.entries.byId[id].url === URL
  );
  return entryId ? entryForID(state, entryId) : undefined;
}

/**
 * The entries that are meant to be shown, this depends on the selection
 * in the collection tree, there are four possibilities:
 * 1. all
 * 2. unsorted (entries that are not in a collection)
 * 3. <collection>
 * [4. <folder>](not implemented yet)
 */
function relevantEntries(state: Types.All) {
  const entryListingMode = UISelectors.entriesListingMode(state);
  const containerId = UISelectors.selectedContainerId(state);

  switch (entryListingMode) {
    case Types.EntriesListingMode.all:
      return allEntries(state);

    case Types.EntriesListingMode.unsorted:
      return unsortedEntries(state);

    case Types.EntriesListingMode.collection:
      return containerId ? entriesForContainer(state, containerId) : [];

    case Types.EntriesListingMode.folder:
      return [];
  }
}

const matchingEntries = createSelector(
  searchPhraseSelector,
  relevantEntries,
  (searchPhrase, relevantEntriesArray) =>
    searchPhrase
      ? Search.searchByPhrase(relevantEntriesArray, searchPhrase)
      : relevantEntriesArray
);

const visibleEntries = createSelector(
  pageSize,
  visiblePage,
  matchingEntries,
  (pageSizeNumber, visiblePageNumber, matchingEntriesArray) => {
    const firstIndex = pageSizeNumber * (visiblePageNumber - 1);
    const lastIndex = firstIndex + pageSizeNumber;
    return matchingEntriesArray.slice(firstIndex, lastIndex + 1);
  }
);

export const unsortedEntries = (state: Types.All) => {
  const entriesInCollections = Object.keys(state.containers.byEntryId);
  return state.entries.allIds.reduce((prev, curr) => {
    if (!entriesInCollections.includes(curr.toString())) {
      prev.push(state.entries.byId[curr]);
    }
    return prev;
  }, [] as Types.Entry[]);
};

export const entriesForContainer = (
  state: Types.All,
  containerId: Types.StringModelId
): Types.Entry[] => {
  const container = ContainersSelectors.containerById(state, containerId);
  return container && container.containerType === Types.ContainerType.COLLECTION
    ? _.compact(container.entries.map((entryId) => entryForID(state, entryId)))
    : [];
};

export const collectionsOfEntries = (
  state: Types.All,
  entries: Types.Entry[],
  excludeContainerId: Types.StringModelId
): Types.ContainerOccurrences => {
  const occuringCollections = {} as Types.ContainerOccurrences;
  const rootContainer = ContainersSelectors.rootContainer(state);
  entries.forEach((entry) =>
    ContainersSelectors.collectionsForEntry(state, entry.id).forEach(
      (collectionId) => {
        if (collectionId === excludeContainerId) {
          return;
        }
        const parent = ContainersSelectors.parentContainerFor(
          state,
          collectionId
        );
        if (!occuringCollections[collectionId]) {
          occuringCollections[collectionId] = {
            occurences: [],
            path: ContainersSelectors.treePathFor(state, collectionId),
            id: collectionId,
            parentId:
              parent && rootContainer && parent.id !== rootContainer.id
                ? parent.id
                : undefined,
          };
        }
        occuringCollections[collectionId].occurences.push(entry);
      }
    )
  );
  return occuringCollections;
};

export function entryInEdit(state: Types.All): Types.EntryInEdit | undefined {
  return state.entries.entryInEdit;
}

export function isNewEntry(state: Types.All): boolean {
  const entryInEditVar = entryInEdit(state);
  return !!entryInEditVar && entryInEditVar.id === '-1';
}

const EntriesSelectors = {
  allEntries,
  matchingEntries,
  visibleEntries,
  entryForID,
  entryForURL,
  unsortedEntries,
  entriesForContainer,
  collectionsOfEntries,
  entryInEdit,
  isNewEntry,
};
export default EntriesSelectors;
