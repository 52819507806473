import { Col, Drawer, Row, Skeleton } from 'antd';
import React, { useEffect } from 'react';
import * as Redux from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Route } from 'react-router-dom';
import * as Thunk from 'redux-thunk';
import { EntriesSelectors } from '../../State/Entries';
import * as Types from '../../State/types';
import { UIOperations, UISelectors } from '../../State/UserInterface';
import ContainerView from '../Containers/ContainerView';
import SidebarContainer from '../ContentView/SidebarContainer';
import Timeline from '../ContentView/Timeline/Timeline';
import MainTopMenu from '../Molecules/MainTopMenu';
import EmptyStates, { StateType } from '../MultiPurpose/EmptyStates';
import './ContentView.css';

export interface ContentViewStateProps {
  showsCollectionView: boolean;
  visibleEntries: Types.Entry[];
  allEntries: Types.Entry[];
  visiblePage: number;
  entryInEdit?: Types.EntryInEdit;
  editEntryFormIsVisible: boolean;
  containerId?: Types.StringModelId;
  initialFetchIsDone: boolean;
  sidebarDrawerIsVisible: boolean;
  history: RouteComponentProps['history'];
}

export interface ContentViewDispatchProps {
  handleEditEntry: (entryID: Types.ModelId) => void;
  setDrawerVisibility: (isVisible: boolean) => void;
}

export interface ContentViewProps
  extends ContentViewStateProps,
    ContentViewDispatchProps {}

export function ContentView({
  showsCollectionView,
  visibleEntries,
  allEntries,
  visiblePage,
  entryInEdit,
  editEntryFormIsVisible,
  containerId,
  initialFetchIsDone,
  history,
  handleEditEntry,
  sidebarDrawerIsVisible,
  setDrawerVisibility,
}: ContentViewProps) {
  // Scroll the view to top when visible entries change
  useEffect(() => {
    document?.getElementById('ScrollView')?.scrollTo(0, 0);
  }, [visibleEntries]);

  const sidebarSpan = 6;

  if (!initialFetchIsDone) {
    return (
      <>
        <Row>
          <Col md={sidebarSpan} xs={0} className="Sidebar">
            <Route path={`*`} component={SidebarContainer} />
          </Col>
          <Col
            md={24 - sidebarSpan}
            xs={24}
            className="Timeline"
            style={{
              paddingRight: 8,
            }}
          >
            <Skeleton.Input
              style={{
                width: '300px',
                marginTop: 8,
                marginBottom: 8,
              }}
              active={false}
              size="large"
            />
            <Skeleton paragraph={{ rows: 12 }} active={true} />
          </Col>
        </Row>
      </>
    );
  } else if (initialFetchIsDone && allEntries.length === 0) {
    return <EmptyStates stateType={StateType.noEntriesLoggedYet} />;
  } else {
    return (
      <>
        <Row>
          <Col md={sidebarSpan} xs={0} className="Sidebar">
            <Route path={`*`} component={SidebarContainer} />
          </Col>
          <Col md={24 - sidebarSpan} xs={24}>
            <Row>
              <Col xs={24}>
                <Route path={`*`} component={MainTopMenu} />
              </Col>
            </Row>
            <Row className="ContentView">
              <Col xs={24} id="ScrollView">
                <Row>
                  <Col xs={24} md={0}>
                    <Drawer
                      visible={sidebarDrawerIsVisible}
                      placement="left"
                      onClose={() => setDrawerVisibility(false)}
                      closable={false}
                      style={{ WebkitOverflowScrolling: 'touch' }}
                    >
                      <Route path={`*`} component={SidebarContainer} />
                    </Drawer>
                  </Col>
                </Row>
                {!showsCollectionView ? (
                  <Timeline
                    visibleEntries={visibleEntries}
                    allEntries={allEntries}
                    visiblePage={visiblePage}
                    entryInEdit={entryInEdit}
                    editEntryFormIsVisible={editEntryFormIsVisible}
                    handleEditEntry={handleEditEntry}
                  />
                ) : containerId ? (
                  <ContainerView
                    containerId={containerId}
                    history={history}
                    entries={visibleEntries}
                  />
                ) : (
                  <h3>Container loading</h3>
                )}
              </Col>
            </Row>{' '}
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(
  state: Types.All,
  ownProps: RouteComponentProps<{}>
): ContentViewStateProps {
  const containerId =
    state.router.location.pathname.startsWith('/collections') &&
    state.router.location.pathname.length > 6
      ? state.router.location.pathname.split('/')[2]
      : '-1';

  return {
    showsCollectionView:
      UISelectors.entriesListingMode(state) ===
      Types.EntriesListingMode.collection,
    visibleEntries: EntriesSelectors.visibleEntries(state),
    allEntries: EntriesSelectors.allEntries(state),
    visiblePage: UISelectors.visiblePage(state),
    entryInEdit: EntriesSelectors.entryInEdit(state),
    editEntryFormIsVisible: UISelectors.editEntryViewIsVisibleSelector(state),
    containerId,
    initialFetchIsDone: UISelectors.initialFetchIsDone(state),
    sidebarDrawerIsVisible: UISelectors.sidebarDrawerIsVisible(state),
    history: ownProps.history,
  };
}

function mapDispatchToProps(
  dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>
): ContentViewDispatchProps {
  return {
    handleEditEntry: (entryID: Types.ModelId) => {
      dispatch(UIOperations.startEditingEntry(entryID));
    },
    setDrawerVisibility: (isVisible: boolean) => {
      dispatch(UIOperations.setSidebarDrawerVisibility(isVisible));
    },
  };
}

export default Redux.connect(mapStateToProps, mapDispatchToProps)(ContentView);
