import * as Redux from 'react-redux';
import * as Types from '../../State/types';
import { UISelectors, UIOperations } from '../../State/UserInterface';
import { RouteComponentProps } from 'react-router';
import * as Thunk from 'redux-thunk';
import * as Sidebar from './Sidebar';
import {
  ContainersSelectors,
  ContainersOperations,
} from '../../State/Containers';
import { SessionSelectors, SessionOperations } from '../../State/Session';
import * as RoutesHelper from '../../lib/RoutesHelper';
import Analytics from '../../lib/analytics';

function mapStateToProps(
  state: Types.All,
  ownProps: RouteComponentProps<{}>
): Sidebar.StateProps {
  let selectedContainerId;

  if (ownProps.location?.pathname.startsWith('/collections')) {
    selectedContainerId =
      ownProps.location.pathname.length > 13
        ? ownProps.location.pathname.split('/')[2]
        : '-1';
  } else {
    selectedContainerId = ownProps.location.pathname.slice(1);
  }

  return {
    selectedContainerId,
    initialFetchDone: UISelectors.initialFetchIsDone(state),
    containerTreeSearch: ContainersSelectors.containerTreeForSearchPhaseWithMatchingIdsCreator(
      state
    ),
    userEmail: SessionSelectors.userEmail(state),
    history: ownProps.history,
  };
}

function mapDispatchToProps(
  dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
  ownProps: RouteComponentProps<{}>
): Sidebar.DispatchProps {
  const afterCreationHandler = (containerId: Types.StringModelId) => {
    dispatch(UIOperations.setVisibleContainerModal(Types.ContainerModal.edit));
    ownProps.history.push(RoutesHelper.routeForCollection(containerId));
  };

  return {
    addNewFolder: (parentContainerId: Types.StringModelId, index: number) => {
      dispatch(
        ContainersOperations.newContainerAt(
          'New Folder',
          Types.ContainerType.FOLDER,
          'default',
          '',
          '',
          parentContainerId,
          index,
          afterCreationHandler
        )
      );
    },
    addNewCollection: (
      parentContainerId: Types.StringModelId,
      index: number
    ) => {
      dispatch(
        ContainersOperations.newContainerAt(
          'New Collection',
          Types.ContainerType.COLLECTION,
          'default',
          '',
          '',
          parentContainerId,
          index,
          afterCreationHandler
        )
      );
    },
    showBookmarkletHint: () => {
      dispatch(UIOperations.showBookmarkletHint());
      Analytics.track('Bookmarklet hint shows');
    },
    showIntegrationHint: () => {
      dispatch(UIOperations.showIntegrationsHint());
      Analytics.track('Integration hint shows');
    },
    showFeedbackModal: () => {
      dispatch(UIOperations.showFeedbackModal());
    },
    newEntry: () => {
      dispatch(UIOperations.startEditingEntry(undefined));
    },
    handleLogout: () => {
      dispatch(SessionOperations.logout());
    },
    handleChangePassword: () => {
      dispatch(SessionOperations.goToChangePassword());
    },
  };
}

export default Redux.connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar.default);
