// import * as Types from '../State/types';
// import * as moment from 'moment';
import _ from 'lodash';

interface DefaultReturn<T> {
  byId: { [key: string]: T };
  allIds: string[];
}

export function updateElements<T extends { id: string }>(
  existingElementsById: { [key: string]: T },
  existingElementsIds: string[],
  newElementsById: { [key: string]: T },
  deletedElements: string[],
  compare: (a: T, b: T) => number
): DefaultReturn<T> {
  const { byId, allIds } = deleteElements(
    existingElementsById,
    existingElementsIds,
    deletedElements
  );

  return insertElements(byId, allIds, newElementsById, compare);
}

export function insertElement<T extends { id: string }>(
  existingElementsById: { [key: string]: T },
  existingElementsIds: string[],
  newElement: T,
  compare: (a: T, b: T) => number
): DefaultReturn<T> {
  return insertElements(
    existingElementsById,
    existingElementsIds,
    { [newElement.id]: newElement },
    compare
  );
}

// Compare function must return 0 for equal, < 0 for a < b, and > 0 for a > b
// Existing and new elements are expected to be sorted.
export function insertElements<T extends { id: string }>(
  existingElementsById: { [key: string]: T },
  existingElementsIds: string[],
  newElementsById: { [key: string]: T },
  compare: (a: T, b: T) => number
): DefaultReturn<T> {
  const newElementsIds = sortElements(
    newElementsById,
    Object.keys(newElementsById),
    compare
  );

  if (existingElementsIds.length === 0) {
    return { byId: newElementsById, allIds: newElementsIds };
  }
  if (newElementsIds.length === 0) {
    return { byId: existingElementsById, allIds: existingElementsIds };
  }

  const smallestExistingElement = existingElementsById[existingElementsIds[0]];

  const greatestExistingElement =
    existingElementsById[existingElementsIds[existingElementsIds.length - 1]];

  const smallestNewElement = newElementsById[newElementsIds[0]];

  const greatestNewElement =
    newElementsById[newElementsIds[newElementsIds.length - 1]];

  let allElementsIds: string[];
  const allElementsById = { ...existingElementsById, ...newElementsById };

  if (isSmaller(greatestNewElement, smallestExistingElement, compare)) {
    allElementsIds = _.uniq(newElementsIds.concat(existingElementsIds));
  } else if (isGreater(smallestNewElement, greatestExistingElement, compare)) {
    allElementsIds = _.uniq(existingElementsIds.concat(newElementsIds));
  } else {
    allElementsIds = newElementsIds.concat(existingElementsIds);
    allElementsIds = _.uniq(
      sortElements(allElementsById, allElementsIds, compare)
    );
  }

  return { byId: allElementsById, allIds: allElementsIds };
}

export function deleteElement<T extends { id: string }>(
  byId: { [key: string]: T },
  allIds: string[],
  deleteId: string
): DefaultReturn<T> {
  delete byId[deleteId];
  allIds = allIds.filter(id => deleteId !== id);
  return { byId, allIds };
}

export function deleteElements<T extends { id: string }>(
  byId: { [key: string]: T },
  allIds: string[],
  deleteIds: string[]
): DefaultReturn<T> {
  deleteIds.forEach(idToDelete => delete byId[idToDelete]);
  allIds = allIds.filter(id => !deleteIds.includes(id));
  return { byId, allIds };
}

export function updateElement<T extends { id: string }>(
  byId: { [key: string]: T },
  allIds: string[],
  updatedElement: T,
  compare: (a: T, b: T) => number
): DefaultReturn<T> {
  const oldElement = byId[updatedElement.id];

  byId[updatedElement.id] = updatedElement;

  // if the comparison element has changed, the
  //   whole array needs to be sorted
  if (compare(oldElement, updatedElement) !== 0) {
    allIds = sortElements(byId, allIds, compare);
  }

  return { byId, allIds };
}

export function normalizeElementsArray<T extends { id: string }>(
  elementsArray: T[]
): DefaultReturn<T> {
  const elementsById = {} as { [key: string]: T };
  const elementIds = [] as string[];

  elementsArray.forEach(element => {
    elementsById[element.id] = element;
    elementIds.push(element.id);
  });

  return { byId: elementsById, allIds: elementIds };
}

export function sortElements<T>(
  byId: { [key: string]: T },
  allIds: string[],
  compare: (a: T, b: T) => number
): string[] {
  allIds.sort(function(a: string, b: string) {
    const aElement: T = byId[a];
    const bElement: T = byId[b];
    return compare(aElement, bElement);
  });
  return _.uniq(allIds);
}

export function isSmaller<T>(
  a: T,
  b: T,
  compare: (a: T, b: T) => number
): boolean {
  return compare(a, b) < 0;
}

export function isGreater<T>(
  a: T,
  b: T,
  compare: (a: T, b: T) => number
): boolean {
  return compare(a, b) > 0;
}
