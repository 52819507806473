import { Col, Row } from 'antd';
import * as React from 'react';
import * as Types from '../../../State/types';
import ExplanationWidget from '../../Molecules/ExplanationWidget';
import EntriesList from './EntriesList';
import './Entry.css';
import './Timeline.css';

export interface TimelineStateProps {
  visibleEntries: Types.Entry[];
  allEntries: Types.Entry[];
  visiblePage: number;
  entryInEdit?: Types.EntryInEdit;
  editEntryFormIsVisible: boolean;
}

export interface TimelineDispatchProps {
  handleEditEntry: (entryID: Types.ModelId) => void;
}

export interface TimelineProps
  extends TimelineStateProps,
    TimelineDispatchProps {}

export default function Timeline({
  visibleEntries,
  entryInEdit,
  editEntryFormIsVisible,
  allEntries,
  handleEditEntry,
}: TimelineProps) {
  return (
    <div className="TimelineEntriesList">
      <Row justify="space-between" className="header-row">
        <Col xs={12}>
          <div className="entry-list-header">All entries</div>
        </Col>
        <Col xs={12} style={{ textAlign: 'right' }}>
          <ExplanationWidget />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          {allEntries.length > 0 && visibleEntries.length > 0 ? (
            <>
              <EntriesList
                visibleEntries={visibleEntries}
                entryInEdit={entryInEdit}
                editEntryFormIsVisible={editEntryFormIsVisible}
                handleEditEntry={handleEditEntry}
              />
              <em>
                <small>End of page</small>
              </em>
            </>
          ) : (
            <em>No matches for your search.</em>
          )}
        </Col>
      </Row>
    </div>
  );
}
