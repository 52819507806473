import { BranchesOutlined, DeleteOutlined, EditOutlined, ForkOutlined, PullRequestOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Checkbox, Col, List, Modal, Row, Tag } from 'antd';
import _ from 'lodash';
import * as React from 'react';
import * as Redux from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import * as Thunk from 'redux-thunk';
import * as RoutesHelper from '../../lib/RoutesHelper';
import { ContainersOperations, ContainersSelectors } from '../../State/Containers';
import { EntriesSelectors } from '../../State/Entries';
import * as Types from '../../State/types';
import { UIOperations, UISelectors } from '../../State/UserInterface';
import EntriesList from '../ContentView/Timeline/EntriesList';
import EditContainerModal from '../Molecules/EditContainerModal';
import SelectContainerModal from '../Molecules/SelectContainerModal';
import './ContainerView.css';

const { confirm } = Modal;

const NUM_OF_ENTRIES_THRESHOLD_FOR_FILTER = 7;

export interface ContainerViewProps {
  containerId?: Types.StringModelId;
  history: RouteComponentProps['history'];
  entries: Types.Entry[];
}

interface ContainerViewStateProps {
  folderOrCollection?: Types.Container;
  children: Types.Container[];
  entries: Types.Entry[];
  containerPath: Types.Container[];
  showModal?: Types.ContainerModal;
  concurrentCollections: Types.ContainerOccurrences;
  history: RouteComponentProps['history'];
  containerForId: (containerId: string) => Types.Container | undefined;
}

interface ContainerViewDispatchProps {
  setContainerModal: (modal?: Types.ContainerModal) => void;
  editEntry: (entryID: Types.ModelId) => void;
  updateContainer: (
    containerId: Types.StringModelId,
    containerProps: Types.UpdatedableContainer
  ) => void;
  mergeCollectionInto: (
    sourceId: Types.StringModelId,
    targetId: Types.StringModelId
  ) => void;
  moveContainerTo: (
    containerId: Types.StringModelId,
    targetContainerId: Types.StringModelId,
    index: number
  ) => void;
  deleteContainer: (containerId: Types.StringModelId) => void;
}

interface ContainerViewInnerProps
  extends ContainerViewStateProps,
    ContainerViewDispatchProps {}

interface ContainerViewState {
  name?: string;
  aliases?: string;
  description?: string;
  selectedContainerId?: Types.StringModelId;
  activatedConcurrentContainerIds: {
    [id: string]: Types.StringModelId[];
  };
  splitCollectionsWithEntries: {
    id: string;
    name: string;
    entries: Types.StringModelId[];
  }[];
}

const defaultState = {
  showModal: undefined,
  name: '',
  description: '',
  aliases: '',
  selectedContainerId: undefined,
  activatedConcurrentContainerIds: {},
  splitCollectionsWithEntries: [],
};

class ContainerView extends React.Component<
  ContainerViewInnerProps,
  ContainerViewState
> {
  constructor(props: ContainerViewInnerProps) {
    super(props);

    this.state = defaultState;

    this.startEditing = this.startEditing.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.save = this.save.bind(this);
    this.concurrentsForThisCollection = this.concurrentsForThisCollection.bind(
      this
    );
    this.setConcurrentsForThisCollection = this.setConcurrentsForThisCollection.bind(
      this
    );
    this.addSplitCollection = this.addSplitCollection.bind(this);
    this.setCheckedStateForEntry = this.setCheckedStateForEntry.bind(this);
    this.setCheckedStateForWholeCollection = this.setCheckedStateForWholeCollection.bind(
      this
    );
    this.isInSplitModeButInDifferentContainer = this.isInSplitModeButInDifferentContainer.bind(
      this
    );
    this.resetSplit = this.resetSplit.bind(this);
    this.commitSplit = this.commitSplit.bind(this);
    this.reset = this.reset.bind(this);
  }

  changeValue(key: string, value: string | undefined) {
    this.setState({
      ...this.state,
      [key]: value,
    });
  }

  startEditing() {
    if (this.props.folderOrCollection) {
      const { name, aliases, description } = this.props.folderOrCollection;
      this.setState({
        ...this.state,
        name,
        aliases,
        description,
      });
      this.props.setContainerModal(Types.ContainerModal.edit);
    }
  }

  save() {
    if (this.props.folderOrCollection) {
      this.props.updateContainer(this.props.folderOrCollection.id, {
        name: this.state.name,
        aliases: this.state.aliases,
        description: this.state.description,
      });
      this.props.setContainerModal(undefined);
    }
  }

  // CONCURRENT COLLECTIONS START
  toggleActiveConcurrent(containerId: Types.StringModelId) {
    let newActiveContainers = this.concurrentsForThisCollection();
    if (newActiveContainers?.includes(containerId)) {
      const idx = newActiveContainers.indexOf(containerId);
      newActiveContainers.splice(idx, 1);
    } else if (!newActiveContainers) {
      newActiveContainers = [containerId];
    } else {
      newActiveContainers.push(containerId);
    }

    this.setConcurrentsForThisCollection(newActiveContainers);
  }

  concurrentsForThisCollection() {
    if (!this.props.folderOrCollection) {
      return [];
    }

    return (
      this.state.activatedConcurrentContainerIds[
        this.props.folderOrCollection.id
      ] || []
    );
  }

  setConcurrentsForThisCollection(concurrentCollections: string[]) {
    this.props.folderOrCollection &&
      this.setState({
        activatedConcurrentContainerIds: {
          ...this.state.activatedConcurrentContainerIds,
          [this.props.folderOrCollection.id]: concurrentCollections,
        },
      });
  }
  // CONCURRENT COLLECTIONS END

  // SPLITTING START
  addSplitCollection(selectedContainerId: Types.StringModelId) {
    let splitCollectionsWithEntries = this.state.splitCollectionsWithEntries;
    const folderOrCollection = this.props.folderOrCollection;

    if (!folderOrCollection) {
      return;
    }

    // If the user changed to a different container while in split mode
    //   and started a split in the new container, throw away the
    //   previous split state and start fresh
    if (this.isInSplitModeButInDifferentContainer()) {
      splitCollectionsWithEntries = [];
    }

    const newContainerSplits = [...splitCollectionsWithEntries];

    if (splitCollectionsWithEntries.length === 0) {
      newContainerSplits.unshift({
        id: folderOrCollection.id,
        name: folderOrCollection.name,
        entries: folderOrCollection.entries,
      });
    }

    const selectedCollection = this.props.containerForId(selectedContainerId);

    selectedCollection &&
      // Only entries of the selected collection that are also in
      //  the currently visible collection must go to the selection
      newContainerSplits.unshift({
        id: selectedCollection.id,
        name: selectedCollection.name,
        entries: _.intersection(
          selectedCollection.entries,
          folderOrCollection.entries
        ),
      });

    this.setState({ splitCollectionsWithEntries: newContainerSplits });
    this.props.setContainerModal(undefined);
  }

  setCheckedStateForWholeCollection(
    collectionId: Types.StringModelId,
    checked: boolean
  ) {
    const splitCollectionsWithEntries = this.state.splitCollectionsWithEntries;
    const indexOfCollectionObject = _.findIndex(splitCollectionsWithEntries, {
      id: collectionId,
    });
    let newCollectionObject =
      splitCollectionsWithEntries[indexOfCollectionObject];
    if (!newCollectionObject || !this.props.folderOrCollection) {
      return;
    } else {
      newCollectionObject = { ...newCollectionObject };
    }

    if (checked) {
      newCollectionObject.entries = [...this.props.folderOrCollection.entries];
    } else {
      newCollectionObject.entries = [];
    }
    const newSplitCollectionsWithEntries = [...splitCollectionsWithEntries];
    newSplitCollectionsWithEntries[
      indexOfCollectionObject
    ] = newCollectionObject;

    this.setState({
      splitCollectionsWithEntries: newSplitCollectionsWithEntries,
    });
  }

  setCheckedStateForEntry(
    entryId: Types.ModelId,
    collectionId: Types.StringModelId,
    checked: boolean
  ) {
    const splitCollectionsWithEntries = this.state.splitCollectionsWithEntries;
    const indexOfCollectionObject = _.findIndex(splitCollectionsWithEntries, {
      id: collectionId,
    });
    let newCollectionObject =
      splitCollectionsWithEntries[indexOfCollectionObject];
    if (!newCollectionObject) {
      return;
    } else {
      newCollectionObject = { ...newCollectionObject };
    }

    if (checked) {
      newCollectionObject.entries = newCollectionObject.entries.concat(entryId);
    } else {
      newCollectionObject.entries = _.without(
        newCollectionObject.entries,
        entryId
      );
    }

    const newSplitCollectionsWithEntries = [...splitCollectionsWithEntries];
    newSplitCollectionsWithEntries[
      indexOfCollectionObject
    ] = newCollectionObject;

    this.setState({
      splitCollectionsWithEntries: newSplitCollectionsWithEntries,
    });
  }

  isInSplitModeButInDifferentContainer() {
    return (
      this.state.splitCollectionsWithEntries.length > 0 &&
      this.state.splitCollectionsWithEntries[
        this.state.splitCollectionsWithEntries.length - 1
      ].id !== this.props.folderOrCollection?.id
    );
  }

  resetSplit() {
    this.setState({
      splitCollectionsWithEntries: [],
    });
  }

  commitSplit() {
    this.state.splitCollectionsWithEntries.forEach((splitCollectionObject) => {
      const selectedEntries = splitCollectionObject.entries;
      const originalEntries = this.props.containerForId(
        splitCollectionObject.id
      )?.entries;
      const entriesOfVisibleCollection = this.props.folderOrCollection?.entries;

      if (!selectedEntries || !originalEntries || !entriesOfVisibleCollection) {
        global.console.error(
          `Encountered a non existing array where it must exist.`
        );
        return;
      }

      const oldEntriesToRemove = _.without(
        _.intersection(originalEntries, entriesOfVisibleCollection),
        ...selectedEntries
      );
      const oldEntriesToKeep = _.without(
        originalEntries,
        ...oldEntriesToRemove
      );
      const newEntries = _.without(selectedEntries, ...originalEntries);

      this.props.updateContainer(splitCollectionObject.id, {
        entries: oldEntriesToKeep.concat(newEntries),
      });
    });

    this.resetSplit();
  }
  // SPLITTING STOP

  reset() {
    if (this.props.folderOrCollection) {
      this.setState({
        name: undefined,
        description: undefined,
        aliases: undefined,
        selectedContainerId: undefined,
      });
      this.props.setContainerModal(undefined);
    }
  }

  render() {
    const {
      folderOrCollection,
      children,
      containerPath,
      entries,
      concurrentCollections: occuringCollections,
      editEntry,
      mergeCollectionInto,
      moveContainerTo,
      deleteContainer,
      setContainerModal,
      showModal,
      history,
    } = this.props;
    let { splitCollectionsWithEntries } = this.state;

    if (!folderOrCollection) {
      return <h1>Container loading</h1>;
    }

    // Hide the splitting if the user changes to a different container
    if (this.isInSplitModeButInDifferentContainer()) {
      splitCollectionsWithEntries = [];
    }

    const numOfEntries = folderOrCollection.entries.length;

    return (
      <div>
        <Row>
          <Col xs={24}>
            <Breadcrumb>
              <Breadcrumb.Item key="all">
                <Link to={RoutesHelper.mainView()}>All entries</Link>
              </Breadcrumb.Item>
              {containerPath.map((pathElement, idx, fullPath) => (
                <Breadcrumb.Item key={pathElement.id}>
                  {pathElement !== _.last(fullPath) ? (
                    <Link to={RoutesHelper.routeForCollection(pathElement.id)}>
                      {pathElement.name}
                    </Link>
                  ) : (
                    <>
                      <span>{pathElement.name}</span>
                      <span className="num_of_entries">{`(${numOfEntries} ${
                        numOfEntries === 1 ? 'entry' : 'entries'
                      })`}</span>
                    </>
                  )}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
            <h2>
              {folderOrCollection.name || 'undefined'}
              <Button
                className="label-button"
                onClick={this.startEditing}
                type="link"
              >
                <EditOutlined /> Edit details
              </Button>
              &nbsp;
              <Button
                className="label-button"
                onClick={() => setContainerModal(Types.ContainerModal.move)}
                type="link"
              >
                <BranchesOutlined /> Move to...
                {/* or icon "apartment" */}
              </Button>
              {folderOrCollection.containerType === 'collection' && (
                <>
                  &nbsp;
                  <Button
                    className="label-button"
                    onClick={() =>
                      setContainerModal(Types.ContainerModal.merge)
                    }
                    type="link"
                  >
                    <PullRequestOutlined /> Merge into...
                    {/* or icon "import" */}
                  </Button>
                </>
              )}
              <Button
                className="label-button"
                onClick={() => setContainerModal(Types.ContainerModal.split)}
                type="link"
              >
                <ForkOutlined /> Distribute...
                {/* or icon "export" */}
              </Button>
              <Button
                onClick={() => {
                  confirm({
                    title: `Delete «${folderOrCollection.name}»?`,
                    content: 'Some descriptions',
                    okText: 'Delete',
                    okType: 'primary',
                    okButtonProps: {
                      danger: true,
                    },
                    cancelText: 'Keep',
                    onOk() {
                      deleteContainer(folderOrCollection.id);
                      history.push(RoutesHelper.mainView());
                    },
                    onCancel() {},
                  });
                }}
                type="link"
                // ghost={true}
                className="label-button danger"
              >
                <DeleteOutlined /> Delete...
              </Button>
            </h2>
          </Col>
        </Row>
        {entries.length > NUM_OF_ENTRIES_THRESHOLD_FOR_FILTER && (
          <Row style={{ marginBottom: 16 }}>
            <Col style={{ overflow: 'scroll', whiteSpace: 'nowrap' }}>
              {Object.values(occuringCollections)
                .filter(
                  (occuringCollection) =>
                    occuringCollection.occurences.length > 1
                )
                .sort(
                  (collA, collB) =>
                    collB.occurences.length - collA.occurences.length
                )
                .map((occuringCollection) => (
                  <Tag
                    className={`occuring-collection ${
                      this.concurrentsForThisCollection().includes(
                        occuringCollection.id
                      )
                        ? 'active'
                        : 'inactive'
                    }`}
                    key={occuringCollection.id}
                    onClick={() =>
                      this.toggleActiveConcurrent(occuringCollection.id)
                    }
                  >
                    {`${occuringCollection.path} (${occuringCollection.occurences.length})`}
                  </Tag>
                ))}
            </Col>
          </Row>
        )}
        {splitCollectionsWithEntries.length > 0 && (
          <Row className="collection_checkbox_title_row">
            {[...splitCollectionsWithEntries]
              .reverse()
              .map((collectionObject) => (
                <Col className="checkbox_column" key={collectionObject.id}>
                  <div className="checkbox_title">{collectionObject.name}</div>
                  <Checkbox
                    checked={
                      collectionObject.entries.length ===
                      folderOrCollection.entries.length
                    }
                    indeterminate={
                      collectionObject.entries.length > 0 &&
                      collectionObject.entries.length <
                        folderOrCollection.entries.length
                    }
                    onChange={(e) =>
                      this.setCheckedStateForWholeCollection(
                        collectionObject.id,
                        e.target.checked
                      )
                    }
                  />
                </Col>
              ))}
            <Col className="distribution_button_box">
              <div>
                Entries will be saved to the selected collections and removed
                from unselected.
              </div>
              <div className="distribution_buttons">
                <Button type="primary" onClick={this.commitSplit}>
                  Commit distribution
                </Button>
                <Button onClick={this.resetSplit}>Cancel</Button>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col style={{ flexGrow: 1 }}>
            {folderOrCollection.containerType ===
            Types.ContainerType.COLLECTION ? (
              entries.length <= NUM_OF_ENTRIES_THRESHOLD_FOR_FILTER ||
              this.concurrentsForThisCollection().length === 0 ? (
                <EntriesList
                  visibleEntries={entries as Types.Entry[]}
                  visibleCollection={folderOrCollection.id}
                  handleEditEntry={editEntry}
                  checkboxValues={splitCollectionsWithEntries}
                  setChecked={this.setCheckedStateForEntry}
                />
              ) : (
                <>
                  {this.concurrentsForThisCollection().map((contId) => (
                    <>
                      <h3>{occuringCollections[contId].path}</h3>
                      <EntriesList
                        visibleEntries={occuringCollections[contId].occurences}
                        visibleCollection={folderOrCollection.id}
                        handleEditEntry={editEntry}
                        checkboxValues={splitCollectionsWithEntries}
                        setChecked={this.setCheckedStateForEntry}
                      />
                    </>
                  ))}
                </>
              )
            ) : (
              // => folderOrCollection.containerType === Types.ContainerType.FOLDER
              subContainerList(children)
            )}
          </Col>
        </Row>

        <EditContainerModal
          title={`Edit details of «${folderOrCollection.name}»`}
          visible={showModal === Types.ContainerModal.edit}
          afterSave={() => setContainerModal(undefined)}
          onCancel={this.reset}
          className="container_modal"
          container={folderOrCollection}
        />

        <SelectContainerModal
          title={`Select collection to merge «${folderOrCollection.name}» into`}
          visible={showModal === Types.ContainerModal.merge}
          onSelect={(selectedContainerId) => {
            if (selectedContainerId) {
              mergeCollectionInto(folderOrCollection.id, selectedContainerId);
              history.push(
                RoutesHelper.routeForCollection(selectedContainerId)
              );
            }
            this.reset();
          }}
          onCancel={this.reset}
          containersToDisable={[folderOrCollection.id]}
          className="container_modal"
          limitSelectionTo={Types.ContainerType.COLLECTION}
        />

        <SelectContainerModal
          title={`Select folder to move «${folderOrCollection.name}» into`}
          visible={showModal === Types.ContainerModal.move}
          onSelect={(selectedContainerId) => {
            selectedContainerId &&
              moveContainerTo(folderOrCollection.id, selectedContainerId, 0);

            this.reset();
          }}
          onCancel={this.reset}
          containersToDisable={[folderOrCollection.id]}
          className="container_modal"
          limitSelectionTo={Types.ContainerType.FOLDER}
        />

        <SelectContainerModal
          title={`Select folder to distribute entries`}
          visible={showModal === Types.ContainerModal.split}
          onSelect={(selectedContainerId) => {
            if (!selectedContainerId) {
              return;
            }
            this.addSplitCollection(selectedContainerId);
          }}
          onCancel={this.reset}
          containersToDisable={
            splitCollectionsWithEntries.length === 0
              ? [folderOrCollection.id]
              : splitCollectionsWithEntries.map((val) => val.id)
          }
          className="container_modal"
          limitSelectionTo={Types.ContainerType.COLLECTION}
        />
      </div>
    );
  }
}

const subContainerList = (containers: Types.Container[]) => {
  return (
    <List<Types.Container>
      grid={{
        gutter: 16,
        md: 4,
        xs: 2,
      }}
      dataSource={containers}
      renderItem={(container) => (
        <List.Item>
          <Card
            title={
              <Link to={`/collections/${container.id}`}>{container.name}</Link>
            }
          >
            {container.containerType === Types.ContainerType.COLLECTION
              ? `Entries: ${container.entries.length}`
              : ``}
            {container.description}
          </Card>
        </List.Item>
      )}
    />
  );
};

// This works already with containers
// function listAsTable(
//   container: Types.Container,
//   entries: Types.Entry[],
//   editEntry: (entryID: number) => void
// ) {
//   return (
//     <Col>
//       <Row>
//         <Col>
//           <div className="entries-cluster">
//             {
//               <div key={container.name}>
//                 <EntryTable
//                   visibleEntries={entries}
//                   title={''}
//                   editEntry={editEntry}
//                 />
//               </div>
//             }
//           </div>
//         </Col>
//       </Row>
//     </Col>
//   );
// }

// Entries that are not in any group
// function withUnsortedGroup(
//   collection: Types.SmartFolder,
//   groupsData: Types.GroupData[]
// ) {
//   let allTagsInGroups = groupsData.reduce(
//     (acc, groupData) =>
//       acc.concat(
//         groupData.query.reduce(
//           (acc2, query) => acc2.concat(query.in),
//           [] as string[]
//         )
//       ),
//     [] as string[]
//   );

//   let unsortedGroupData: Types.GroupData = {
//     name: 'Unsorted',
//     query: [
//       {
//         in: [] as string[],
//         not_in: allTagsInGroups,
//       },
//     ],
//   };
//   return [unsortedGroupData].concat(groupsData);
// }

// function listAsCluster(
//   collection: Types.Collection,
//   entries: Types.Entry[],
//   searchPhrase: string,
//   editEntry: (entryID: number) => void,
//   setEditGroup: (groupsIndex: number) => void
// ) {
//   return (
//     <Col>
//       <Row>
//         <Col>
//           {withUnsortedGroup(collection, collection.groupsData).map(
//             (groupData, groupIndex) => (
//               <div key={groupData.name}>
//                 <EntryCluster
//                   visibleEntries={Search.searchByTagsAndPhrase(
//                     entries,
//                     groupData.query,
//                     searchPhrase
//                   )}
//                   title={groupData.name}
//                   editEntry={editEntry}
//                   setEditGroup={() => setEditGroup(groupIndex - 1)}
//                 />{' '}
//               </div>
//             )
//           )}
//         </Col>
//       </Row>
//     </Col>
//   );
// }

// function listAsList(
//   collection: Types.Collection,
//   entries: Types.Entry[],
//   searchPhrase: string,
//   editEntry: (entryID: number) => void,
//   setEditGroup: (groupsIndex: number) => void
// ) {
//   return (
//     <Col>
//       <Row>
//         <Col>
//           {withUnsortedGroup(collection, collection.groupsData).map(
//             (groupData, groupIndex) => (
//               <div key={groupData.name}>
//                 <EntryList
//                   visibleEntries={Search.searchByTagsAndPhrase(
//                     entries,
//                     groupData.query,
//                     searchPhrase
//                   )}
//                   title={groupData.name}
//                   editEntry={editEntry}
//                   setEditGroup={() => setEditGroup(groupIndex - 1)}
//                 />
//               </div>
//             )
//           )}
//         </Col>
//       </Row>
//     </Col>
//   );
// }

function mapStateToProps(
  state: Types.All,
  ownProps: ContainerViewProps
): ContainerViewStateProps {
  const loadEmptyState = {
    folderOrCollection: undefined,
    children: [],
    containerPath: [],
    entries: [],
    concurrentCollections: {},
    history: ownProps.history,
    containerForId: (containerId: string) => undefined,
  };
  if (!ownProps.containerId) {
    return loadEmptyState;
  }
  const container = ContainersSelectors.containerById(
    state,
    ownProps.containerId
  );
  if (!container) {
    return loadEmptyState;
  }
  return {
    folderOrCollection: container,
    children:
      container?.children?.map((childId) =>
        ContainersSelectors.containerById(state, childId)
      ) ?? [],
    containerPath: ownProps.containerId
      ? // The first element is the root container, it is not needed
        ContainersSelectors.treePathContainersFor(state, ownProps.containerId)
      : [],
    showModal: UISelectors.visibleContainerModal(state),
    entries: ownProps.entries,
    concurrentCollections: EntriesSelectors.collectionsOfEntries(
      state,
      ownProps.entries,
      container.id
    ),
    history: ownProps.history,
    containerForId: (containerId) => {
      return ContainersSelectors.containerById(state, containerId);
    },
  };
}

function mapDispatchToProps(
  dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>
): ContainerViewDispatchProps {
  return {
    editEntry: (entryID: Types.ModelId) => {
      dispatch(UIOperations.startEditingEntry(entryID));
    },
    setContainerModal: (modal?: Types.ContainerModal) => {
      dispatch(UIOperations.setVisibleContainerModal(modal));
    },
    updateContainer: (
      containerId: Types.StringModelId,
      containerProps: Types.UpdatedableContainer
    ) => {
      dispatch(
        ContainersOperations.updateContainer(containerId, containerProps)
      );
    },
    mergeCollectionInto: (
      sourceId: Types.StringModelId,
      targetId: Types.StringModelId
    ) => {
      dispatch(
        ContainersOperations.mergeCollectionIntoCollection(sourceId, targetId)
      );
    },
    moveContainerTo: (
      containerId: Types.StringModelId,
      targetContainerId: Types.StringModelId,
      index: number
    ) => {
      dispatch(
        ContainersOperations.insertContainerAt(
          containerId,
          targetContainerId,
          index
        )
      );
    },
    deleteContainer: (containerId: Types.StringModelId) => {
      dispatch(ContainersOperations.deleteContainer(containerId));
    },
  };
}

export default Redux.connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainerView);
