import * as apiPaths from '../../lib/apiPaths';

import { StringModelId } from '../types';
import * as Types from '../types';
import moment from 'moment';
import { createAction } from 'typesafe-actions';

export enum LoginState {
  Unknown,
  LoggedOut,
  LoggedIn,
}

export interface FeatureFlags {
  shouldStoreContent?: boolean;
}

export const populateFromPersistance = createAction(
  'POPULATE_SESSION_FROM_PERSISTANCE',
  (persistedState: Types.PersistanceSession) => ({persistedState})
)();

export const saveLastRequestDate = createAction(
  'SAVE_LAST_REQUEST_DATE',
  (lastRequestAt: moment.Moment) => ({lastRequestAt})
)();

export const loginCheckRequest = createAction('LOGIN_CHECK_REQUEST', () => {})();

export const loginSuccess = createAction(
  'LOGIN_SUCCESS',
  (
    email: string,
    userID: number,
    featureFlags: FeatureFlags,
    rootFolderId: StringModelId,
    numOfContainers: number,
    numOfEntries: number
  ) => ({
    email,
    userID,
    featureFlags,
    rootFolderId,
    numOfContainers,
    numOfEntries,
  })
)();

export const loginFailure = createAction(
  'LOGIN_FAILURE',
  (errors: string[]) => ({errors})
)();

export const goToChangePassword = createAction(
  'CHANGE_PASSWORD_REQUEST',
  () => {
    window.location.assign(apiPaths.passwordChangeURL().toString());
    return {};
  }
)();

export const logoutRequest = createAction('LOGOUT_REQUEST', () => {})();

export const logoutSuccess = createAction('LOGOUT_SUCCESS', () => {})();

export const logoutFailure = createAction('LOGOUT_FAILURE', () => {})();

export const applicationError = createAction('APPLICATION_ERROR', () => {})();

export type LoginAction =
  | ReturnType<typeof populateFromPersistance>
  | ReturnType<typeof saveLastRequestDate>
  | ReturnType<typeof loginCheckRequest>
  | ReturnType<typeof logoutRequest>
  | ReturnType<typeof logoutRequest>
  | ReturnType<typeof logoutSuccess>
  | ReturnType<typeof logoutFailure>
  | ReturnType<typeof goToChangePassword>
  | ReturnType<typeof loginSuccess>
  | ReturnType<typeof loginFailure>
  | ReturnType<typeof applicationError>;
