import * as ParseURL from './ParseURL';
import moment from 'moment';
import * as Types from '../State/types';
import _ from 'lodash';

// Postprocessing for incoming requests.
// Expects an array of entries, with at least the properties logged_at and url, sorted
// in descending order

function entriesAPIToClient(
  entries: Types.ServerEntryInbound[]
): Types.ById<Types.Entry> {
  const byId: Types.ById<Types.Entry> = {};
  entries.forEach(entry => (byId[entry.id] = entryAPItoClient(entry)));
  return byId;
}

function entryAPItoClient(entry: Types.ServerEntryInbound): Types.Entry {
  return {
    id: entry.id.toString(),
    title: entry.title,
    url: entry.url,
    collections: [],
    loggedAt: moment(entry.logged_at),
    updatedAt: moment(entry.updated_at),
    domain: ParseURL.forDomain(entry.url),
    description: entry.description,
  };
}

function entryClientToAPI(entry: Types.NewEntry): Types.ServerEntryOutbound {
  // do not return ID, not allowed as prop on the API
  return {
    title: entry.title,
    url: entry.url,
    collections: entry.collections,
    description: entry.description,
    logged_at: entry.loggedAt
      ? moment(entry.loggedAt).toISOString()
      : undefined,
  };
}

// # CONTAINER #

function containersAPIToClient(
  containers: Types.ServerContainer[]
): { [containerId: string]: Types.Container } {
  const containerById: { [containerId: string]: Types.Container } = {};
  containers.forEach(container => {
    const processedContainer = containerAPIToClient(container);
    containerById[container.id] = processedContainer;
    if (processedContainer.containerType === Types.ContainerType.COLLECTION) {
      processedContainer.entries.forEach(entryId => {});
    }
  });
  return containerById;
}

function containerAPIToClient(
  serverContainer: Types.ServerContainer
): Types.Container {
  const containerType = containerTypeStringToType(serverContainer.container_type);
  const entries =
    containerType === Types.ContainerType.COLLECTION &&
    serverContainer.entries.length > 0
    // HACK: At some point I discovered that there's a mix of strings and number in the entries
    //        array of collections, this mapping to a string below is a fix for that
      ? JSON.parse(serverContainer.entries).map((id: string | number) => `${id}`)
      : [];
  const parentId = serverContainer.parent_id
    ? serverContainer.parent_id.toString()
    : undefined;

  return {
    id: serverContainer.id.toString(),
    name: serverContainer.name,
    entries,
    containerType,
    icon: serverContainer.icon,
    aliases: serverContainer.aliases,
    description: serverContainer.description,
    parentId,
    position: serverContainer.position,
    createdAt: moment(serverContainer.created_at),
    updatedAt: moment(serverContainer.updated_at),
  };
}

function containersClientToAPI(
  containers: Types.SendContainer[]
): Types.SendServerContainer[] {
  let myContainers = containers;
  return myContainers.map(container => containerClientToAPI(container));
}

function containerClientToAPI(
  container: Types.SendContainer
): Types.SendServerContainer {
  const entries =
    container.entries.length > 0
      ? JSON.stringify(_.uniq(container.entries))
      : '';

  return {
    name: container.name,
    entries,
    container_type: container.containerType,
    icon: container.icon,
    aliases: container.aliases,
    description: container.description,
    parent_id: container.parentId
      ? parseInt(container.parentId, 10)
      : undefined,
    position: container.position,
  };
}

// Persistance

const containersClientToPersist = (
  containers: Types.ById<Types.Container>
): Types.ById<Types.PersistContainer> => {
  return transformTheByIdObject<Types.Container, Types.PersistContainer>(
    containers,
    containerToTransform => ({
      ...containerToTransform,
      createdAt: containerToTransform.createdAt.toISOString(),
      updatedAt: containerToTransform.updatedAt.toISOString(),
    })
  );
};

const containersPersistToClient = (
  containers?: Types.ById<Types.PersistContainer>
): Types.ById<Types.Container> | undefined => {
  return containers
    ? transformTheByIdObject<Types.PersistContainer, Types.Container>(
        containers,
        containerToTransform => ({
          ...containerToTransform,
          createdAt: moment(containerToTransform.createdAt),
          updatedAt: moment(containerToTransform.updatedAt),
        })
      )
    : undefined;
};

const entriesClientToPersist = (
  entries: Types.ById<Types.Entry>
): Types.ById<Types.PersistEntry> => {
  return transformTheByIdObject<Types.Entry, Types.PersistEntry>(
    entries,
    entryToTransform => ({
      ...entryToTransform,
      loggedAt: entryToTransform.loggedAt.toISOString(),
      updatedAt: entryToTransform.updatedAt.toISOString(),
    })
  );
};

const entriesPersistToClient = (
  entries?: Types.ById<Types.PersistEntry>
): Types.ById<Types.Entry> | undefined => {
  return entries
    ? transformTheByIdObject<Types.PersistEntry, Types.Entry>(
        entries,
        entryToTransform => ({
          ...entryToTransform,
          loggedAt: moment(entryToTransform.loggedAt),
          updatedAt: moment(entryToTransform.updatedAt),
        })
      )
    : undefined;
};

const transformTheByIdObject = <ClientType, PersistType>(
  clientObjects: Types.ById<ClientType>,
  transform: (clientObject: ClientType) => PersistType
): Types.ById<PersistType> => {
  const processedObjects: Types.ById<PersistType> = {};
  Object.keys(clientObjects).forEach(
    objectId =>
      (processedObjects[objectId] = transform(clientObjects[objectId]))
  );
  return processedObjects;
};

const PostProcess = {
  entriesAPIToClient,
  entryAPItoClient,
  entryClientToAPI,
  containersAPIToClient,
  containerAPIToClient,
  containersClientToAPI,
  containerClientToAPI,
  containersClientToPersist,
  containersPersistToClient,
  entriesClientToPersist,
  entriesPersistToClient,
};
export default PostProcess;

const containerTypeStringToType = (
  containerType: 'folder' | 'collection' | 'smartfolder'
): Types.ContainerType => {
  const typesMap: { [containerType: string]: Types.ContainerType } = {
    collection: Types.ContainerType.COLLECTION,
    folder: Types.ContainerType.FOLDER,
    smart_folder: Types.ContainerType.SMARTFOLDER,
  };
  return typesMap[containerType] || Types.ContainerType.COLLECTION;
};
