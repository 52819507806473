import { LinkOutlined, PushpinOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import * as React from 'react';
import * as Types from '../../../State/types';
import './EditEntryFormInline.css';

const FormItem = Form.Item;

export interface EditEntryFormStateProps {
  entryInEdit?: Types.EntryInEdit;
  errors: string[];
  containerTree: Types.ContainerTreeNode;
}

export interface EditEntryFormDispatchProps {
  changeEntryInEdit: (entryInEditing: Partial<Types.EntryInEdit>) => void;
  cancelEditing: () => void;
  updateEntry: () => void;
  handleDeleteEntry: (entryID: Types.ModelId) => void;
}

export interface EditEntryFormProps
  extends EditEntryFormStateProps,
    EditEntryFormDispatchProps {}

interface EditEntryFormState {}

export default class EditEntryForm extends React.Component<
  EditEntryFormProps,
  EditEntryFormState
> {
  render() {
    const {
      handleDeleteEntry,
      entryInEdit,
      changeEntryInEdit,
      updateEntry,
      cancelEditing,
    } = this.props;

    if (!entryInEdit) {
      return null;
    }

    const { id, title, url, description } = entryInEdit;

    const isNewEntry = id === '-1';

    return (
      <>
        {isNewEntry && <h3>New Entry</h3>}
        <Form
          layout="vertical"
          onFinish={(e) => updateEntry()}
          className="EntryEditFormInline"
        >
          <div className="entry-edit-fields">
            {/* # Title Input # */}
            <FormItem>
              <Input
                placeholder="Title"
                value={title}
                onChange={(e) => changeEntryInEdit({ title: e.target.value })}
                name="title"
              />
            </FormItem>
            {/* # URL Input # */}
            <FormItem className="url">
              <Input
                addonBefore={<LinkOutlined />}
                placeholder="https://interesting/page.htm"
                value={url}
                onChange={(e) => changeEntryInEdit({ url: e.target.value })}
                name="url"
                size="small"
              />
            </FormItem>
            {/* # Description Input # */}
            <FormItem className="description">
              <Input
                addonBefore={<PushpinOutlined />}
                placeholder="Description"
                value={description}
                onChange={(e) =>
                  changeEntryInEdit({ description: e.target.value })
                }
                name="description"
              />
            </FormItem>
          </div>
          {/* # Submit Buttons # */}
          <FormItem className="entry-edit-buttons">
            {isNewEntry ? (
              <Button onClick={updateEntry} type="primary">
                Save entry
              </Button>
            ) : (
              <Button onClick={updateEntry} type="primary">
                Update entry
              </Button>
            )}
            <Button onClick={cancelEditing}>Cancel</Button>
            {!isNewEntry ? (
              <Button
                className="delete-button"
                danger={true}
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  handleDeleteEntry(id);
                }}
              >
                Delete
              </Button>
            ) : (
              <></>
            )}
          </FormItem>
        </Form>
      </>
    );
  }
}
