import { Button, Checkbox, Col, List, Row } from 'antd';
import * as React from 'react';
import * as Types from '../../../State/types';
import ErrorBoundary from '../../Containers/ErrorBoundary';
import EditEntryFormInlineContainer from './EditEntryFormInlineContainer';
import Entry from './Entry';

// type EntrySelection = {
//   entry: string;
//   collectionId: string;
// };

/**
 * @param{array} checkboxValues: the values for the checkboxes, if empty or undefined
 *                                    no checkboxes are displayed
 */
export interface EntriesListProps {
  visibleEntries: Types.Entry[];
  entryInEdit?: Types.EntryInEdit;
  editEntryFormIsVisible?: boolean;
  visibleCollection?: Types.StringModelId;
  checkboxValues?: {
    id: string;
    name: string;
    entries: Types.StringModelId[];
  }[];
  handleEditEntry: (entryID: Types.ModelId) => void;
  setChecked?: (
    entryId: Types.ModelId,
    collectionId: Types.StringModelId,
    checked: boolean
  ) => void;
}

const EntriesList = function ({
  visibleEntries,
  visibleCollection,
  entryInEdit,
  editEntryFormIsVisible,
  checkboxValues,
  handleEditEntry,
  setChecked,
}: EntriesListProps) {
  const checkboxValuesDefined = checkboxValues || [];
  const reversedCheckboxValues = [...checkboxValuesDefined].reverse();

  return (
    <>
      <List
        dataSource={visibleEntries}
        renderItem={(entry: Types.Entry) =>
          editEntryFormIsVisible && entryInEdit?.id === entry.id ? (
            <List.Item>
              <EditEntryFormInlineContainer />
            </List.Item>
          ) : (
            <List.Item
              actions={
                reversedCheckboxValues.length > 0
                  ? []
                  : [
                      <Button
                        type="link"
                        key="edit"
                        onClick={(e) => handleEditEntry(entry.id)}
                      >
                        edit
                      </Button>,
                    ]
              }
            >
              <List.Item.Meta
                title={
                  <ErrorBoundary>
                    <Entry
                      entry={entry}
                      visibleCollection={visibleCollection}
                    />
                  </ErrorBoundary>
                }
              />
              {checkboxValuesDefined.length > 0 && (
                <Row>
                  {checkboxValuesDefined.map((collectionValues) => (
                    <Col
                      className="checkbox_column"
                      key={collectionValues.id}
                      xs={24}
                    >
                      <Checkbox
                        checked={collectionValues.entries.includes(entry.id)}
                        onChange={(e) =>
                          setChecked &&
                          setChecked(
                            entry.id,
                            collectionValues.id,
                            e.target.checked
                          )
                        }
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </List.Item>
          )
        }
      />
    </>
  );
};

export default EntriesList;
