declare var Rollbar: {
  configure: (configuration: object) => void;
  warning: (message: string) => void;
  error: (message: string) => void;
};

export function warning(message: string): void {
  if (typeof Rollbar !== 'undefined') {
    Rollbar.warning(message);
  } else {
    global.console.log(`Rollbar.warning: ${message}`);
  }
}

export function error(message: string): void {
  if (typeof Rollbar !== 'undefined') {
    Rollbar.error(message);
  } else {
    global.console.log(`Rollbar.error: ${message}`);
  }
}
