import * as Actions from './actions';
import * as Types from '../types';

const SessionSelectors = {
  getIsLoggedIn,
  rootFolderId,
  getErrors,
  userID,
  userEmail,
};

export default SessionSelectors;

function getIsLoggedIn(state: Types.All): Actions.LoginState {
  return state.session.loginState;
}

function rootFolderId(state: Types.All): string {
  return state.session.rootFolderId.toString() || '-1';
}

function getErrors(state: Types.All): string[] {
  return state.session.errors;
}

function userID(state: Types.All): number {
  return state.session.userID;
}

function userEmail(state: Types.All): string {
  return state.session.email;
}
