// Taken from: https://www.byteconf.com/blog/integrating-mixpanel-into-a-react-and-redux-application/
//  Read up for usage with user profiles.
import * as Mixpanel from 'mixpanel-browser';
import * as APIPaths from './apiPaths';
import * as ErrorAndLogging from './errorAndLogging';

const analytics = {
  init,
  track,
  mapFeelingToNumber,
  updateProfile,
};
export default analytics;

function init(userID: number, email: string) {
  if (shouldTrack() && userID !== 1) {
    Mixpanel.init('ed9bbdfe84ea9059a6b01c820986d528');
  } else {
    Mixpanel.init('b18295edf8b2f29027ffbbb684920166');
  }
  Mixpanel.identify(userID);
  Mixpanel.register({
    id: userID,
  });
  Mixpanel.people.set({
    $last_login: new Date(),
    $email: email,
  });
  if (shouldTrack()) {
    if (window.location.pathname.includes('bookmarklet_add')) {
      Mixpanel.people.increment('Bookmarklet started');
      track('Bookmarklet started');
    } else {
      Mixpanel.people.increment('App started');
      track('App started');
    }
  }
}

function track(eventName: string, evenProperties: object = {}) {
  Mixpanel.track(eventName, evenProperties);
}

function updateProfile(numOfTags: number) {
  if (Mixpanel.people) {
    Mixpanel.people.set({
      '#tags': numOfTags,
    });
  } else {
    ErrorAndLogging.warning(`Mixpanel.people not present.`);
    return;
  }
}

function shouldTrack(): boolean {
  return APIPaths.currentEnvironment() === 'production';
}

function mapFeelingToNumber(
  feeling: 'terrible' | 'bad' | 'ok' | 'good' | 'amazing' | ''
): number {
  return {
    '': 0,
    terrible: 1,
    bad: 2,
    ok: 3,
    good: 4,
    amazing: 5,
  }[feeling];
}
