import * as React from 'react';
import * as Redux from 'react-redux';
import * as Thunk from 'redux-thunk';
import { EntriesSelectors } from '../../State/Entries';
import * as Types from '../../State/types';
import { UISelectors } from '../../State/UserInterface';

export interface ExplanationStateProps {
  numberOfPages: number;
  visiblePage: number;
  matchingEntries: Types.Entry[];
  searchPhrase: string;
}

export interface ExplanationDispatchProps {}

export interface ExplanationProps
  extends ExplanationStateProps,
    ExplanationDispatchProps {}

function ExplanationWidget(props: ExplanationProps) {
  const { numberOfPages, visiblePage, matchingEntries, searchPhrase } = props;

  let entriesExplanation =
    searchPhrase.length > 0 ? `entries matching "${searchPhrase}"` : `entries`;

  return (
    <span>
      <span className="pagination-long">
        {`Page ${visiblePage} of ${numberOfPages}`}
        &nbsp;
      </span>
      <span className="pagination-short">
        {`${visiblePage}/${numberOfPages}`}
        &nbsp;
      </span>
      <span className="explanation">
        {`(for ${matchingEntries.length} ${entriesExplanation})`}
      </span>
    </span>
  );
}

function mapStateToProps(state: Types.All): ExplanationStateProps {
  return {
    searchPhrase: UISelectors.searchPhraseSelector(state),
    numberOfPages: UISelectors.numberOfPages(state),
    visiblePage: UISelectors.visiblePage(state),
    matchingEntries: EntriesSelectors.matchingEntries(state),
  };
}

function mapDispatchToProps(
  dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>
): ExplanationDispatchProps {
  return {};
}

export default Redux.connect(
  mapStateToProps,
  mapDispatchToProps
)(ExplanationWidget);
