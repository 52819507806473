import * as moment from 'moment';
import * as Types from '../State/types';

type Environment = 'development' | 'test' | 'staging' | 'production';

export function currentEnvironment(): Environment {
  if (process.env.NODE_ENV === `development`) {
    return 'development';
  } else if (process.env.NODE_ENV === 'test') {
    return 'test';
  } else if (window.location.host.search('staging') !== -1) {
    return 'staging';
  } else {
    return 'production';
  }
}

/***
 * @return {string} Domain name with protocol and optional port (e.g. http://localhost:3000, https://app.unconfuse.me)
 */
export function appBaseURL(): string {
  return window.location.origin;
}

export function APIHost(withPath: string = ''): URL {
  let baseURL = 'https://hello.unconfuse.me';

  if (currentEnvironment() === 'development') {
    // baseURL =
    // window.location.origin.substring(0, window.location.origin.length - 5) +
    // ':3001';
    // the fixed IP here is required to be able to test on the mobile phone
    // baseURL = 'http://192.168.178.117:3001';
    baseURL = 'http://localhost:3001';
  } else if (window.location.host.includes('netlify')) {
    baseURL = 'https://journalbot-api-staging.herokuapp.com/';
  }
  let hostURL = new URL(baseURL);
  hostURL.pathname += withPath;
  return hostURL;
}

export function searchEntriesURL(searchPhrase: string): URL {
  const requestURL = APIHost('api/entries.json');

  // Something in the libs (probably JS-dom) used by Jest 20.0.2 does not
  //  support URLSearchParams()
  if (currentEnvironment() !== 'test') {
    requestURL.searchParams.set('search_phrase', searchPhrase);
  }

  return requestURL;
}

export function fetchEntriesURL(
  updatedSince: moment.Moment | undefined,
  loggedBefore: moment.Moment | undefined = undefined,
  loggedSince: moment.Moment | undefined = undefined
): URL {
  const requestURL = APIHost('api/entries.json');

  loggedBefore &&
    requestURL.searchParams.set('logged_before', loggedBefore.toISOString());
  loggedSince &&
    requestURL.searchParams.set('logged_since', loggedSince.toISOString());
  updatedSince &&
    requestURL.searchParams.set('updated_since', updatedSince.toISOString());

  return requestURL;
}

export function createEntryURL(updatedSince: moment.Moment | undefined): URL {
  const requestURL = APIHost('api/entries/');

  updatedSince &&
    requestURL.searchParams.set('updated_since', updatedSince.toISOString());

  return requestURL;
}

export function updateEntryURL(
  entryID: Types.ModelId,
  updatedSince: moment.Moment | undefined
): URL {
  const requestURL = APIHost(`api/entries/${entryID}`);

  updatedSince &&
    requestURL.searchParams.set('updated_since', updatedSince.toISOString());

  return requestURL;
}

export function deleteEntryURL(
  entryID: Types.ModelId,
  updatedSince: moment.Moment | undefined
): URL {
  const requestURL = APIHost(`api/entries/${entryID}`);

  updatedSince &&
    requestURL.searchParams.set('updated_since', updatedSince.toISOString());

  return requestURL;
}

export function fetchContainersURL(
  updatedSince: moment.Moment | undefined
): URL {
  const requestURL = APIHost(`api/containers.json`);

  updatedSince &&
    requestURL.searchParams.set('updated_since', updatedSince.toISOString());

  return requestURL;
}

export function createContainerURL(
  updatedSince: moment.Moment | undefined = undefined
): URL {
  const requestURL = APIHost('api/containers/');

  updatedSince &&
    requestURL.searchParams.set('updated_since', updatedSince.toISOString());

  return requestURL;
}

export function updateContainerURL(
  containerID: Types.StringModelId,
  lastUpdatedAt: moment.Moment | undefined = undefined
): URL {
  const requestURL = APIHost(`api/containers/${containerID}`);

  lastUpdatedAt &&
    requestURL.searchParams.set('updated_since', lastUpdatedAt.toISOString());

  return requestURL;
}

export function deleteContainerURL(
  containerID: Types.StringModelId,
  lastUpdatedAt: moment.Moment | undefined = undefined
): URL {
  const requestURL = APIHost(`api/containers/${containerID}`);

  lastUpdatedAt &&
    requestURL.searchParams.set('updated_since', lastUpdatedAt.toISOString());

  return requestURL;
}

export function userInfoURL(): URL {
  return APIHost('users');
}

export function loginURL(): URL {
  return APIHost('users/sign_in/');
}

export function logoutURL(): URL {
  // Requires JSON to circumvent Rails' `protect_from_forgery`
  return APIHost('users/sign_out.json');
}

export function passwordChangeURL(): URL {
  return APIHost('users/edit/');
}
