import * as Actions from './actions';
import * as Types from '../types';
import moment from 'moment';
import { getType } from 'typesafe-actions';
import { insertElements, updateElements } from '../../lib/StateManagement';
import { initialState } from '../initialState';
import * as PersistanceManagement from '../../lib/PersistanceManagement';

const blankEntry = {
  id: '-1',
  loggedAt: moment(),
  title: '',
  description: '',
  url: '',
  collections: [],
};

export default function entries(
  state: Types.Entries = initialState.entries,
  action: Actions.EntriesAction
): Types.Entries {
  switch (action.type) {
    case getType(Actions.populateFromPersitance):
      return action.payload.partialEntriesState.byId
        ? {
            ...state,
            ...action.payload.partialEntriesState,
            ...insertElements(
              { ...state.byId },
              [...state.allIds],
              action.payload.partialEntriesState.byId,
              compareDescByDateLogged
            ),
            shouldPersist: true,
            initialFetchOfEntriesIsDone: true,
          }
        : {
            ...state,
            ...action.payload,
            shouldPersist: true,
          };
    case getType(Actions.fetchEntriesRequest):
      return { ...state };
    case getType(Actions.fetchEntriesSuccess):
      return persist({
        ...state,
        ...insertElements(
          { ...state.byId },
          [...state.allIds],
          action.payload.entries,
          compareDescByDateLogged
        ),
        initialFetchOfEntriesIsDone: true,
        mostDistantFetchedDate: moment.min(
          state.mostDistantFetchedDate,
          moment(action.payload.params.loggedBefore)
        ),
        nearestFetchedDate: moment.max(
          state.nearestFetchedDate,
          moment(action.payload.params.loggedSince)
        ),
      });
    case getType(Actions.receivedEntriesUpdate):
      return persist({
        ...state,
        ...updateElements(
          { ...state.byId },
          [...state.allIds],
          action.payload.entries,
          action.payload.deletedEntries,
          compareDescByDateLogged
        ),
      });
    case getType(Actions.editEntryAction):
      // TODO: the check and assignment of the blank entry should happen in operations
      const entryID = action.payload.entryID;
      const entryToEdit = entryID
        ? state.byId[entryID] || blankEntry
        : blankEntry;
      return {
        ...state,
        entryInEdit: {
          ...entryToEdit,
          collections: action.payload.entryCollections,
        },
      };
    case getType(Actions.changeEntryInEditAction):
      return {
        ...state,
        entryInEdit: {
          ...blankEntry,
          ...state.entryInEdit,
          ...action.payload.entryInEdit,
        },
      };
    case getType(Actions.cancelEditing):
      return {
        ...state,
        entryInEdit: undefined,
      };
    case getType(Actions.updateEntrySuccess):
      return {
        ...state,
        entryInEdit: undefined,
      };
    case getType(Actions.enablePersistance):
      return {
        ...state,
        shouldPersist: true,
      };
    case getType(Actions.deleteEntrySuccess):
    case getType(Actions.createEntrySuccess):
    default:
      return state;
  }
}

// The sort order is DESC by datetime of loggedAt
function compareDescByDateLogged(
  aEntry: Types.Entry,
  bEntry: Types.Entry
): number {
  return bEntry.loggedAt.diff(aEntry.loggedAt);
}

// This breaks the "no side effect" rule of reducers, but it makes no sense
//   to duplicate all reducer functionality in the operations
const persist = (state: Types.Entries) => {
  if (state.shouldPersist) {
    PersistanceManagement.persistEntries(state.byId);
  }
  return state;
};
