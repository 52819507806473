import * as Types from './types';
import moment from 'moment';
import { LoginState } from './Session/actions';

export const numberOfRenderedEntriesIncrease = 100;
export const numberOfRenderedEntriesDefault = 50;

export const initialState: Types.All = {
  entries: {
    byId: {},
    allIds: [],
    initialFetchOfEntriesIsDone: false,
    mostDistantFetchedDate: moment(),
    nearestFetchedDate: moment(),
    entryInEdit: undefined,
  },
  containers: {
    byId: {},
    initialFetchOfContainersIsDone: false,
    // The map from entry Ids to collections is built here on the intial fetch
    //   subsequent updates during runtime, are done via the Entry update action
    byEntryId: {},
  },
  userInterface: {
    visiblePage: 1,
    pageSize: 30,
    searchPhrase: '',
    selectedCollectionKey: '',
    numberOfRenderedEntries: numberOfRenderedEntriesDefault,
    editEntryViewVisible: false,
    hintForBookmarkletIsVisible: false,
    hintForIntegrationIsVisible: false,
    modalForFeedbackIsVisible: false,
    errors: [],
    showsSidebarDrawer: false,
  },
  session: {
    loginState: LoginState.Unknown,
    email: '',
    userID: 0,
    errors: [],
    featureFlags: {},
    rootFolderId: '-1',
    numOfEntries: -1,
    numOfContainers: -1,
  },
  router: {
    location: {
      pathname: '',
      search: '',
      state: {},
      hash: '',
      query: {"": ""}
    },
    action: 'PUSH',
  },
};
