import * as Types from '../types';
import * as Actions from './actions';
import { EntriesSelectors } from '../Entries';

function initialFetchIsDone(state: Types.All): boolean {
  return (
    state.entries.initialFetchOfEntriesIsDone &&
    state.containers.initialFetchOfContainersIsDone
  );
}

function editEntryViewIsVisibleSelector(state: Types.All): boolean {
  return state.userInterface.editEntryViewVisible;
}

const entriesListingMode = (state: Types.All): Types.EntriesListingMode => {
  const path = state.router.location.pathname;
  if (path.startsWith('/collections')) {
    return Types.EntriesListingMode.collection;
  } else if (path.startsWith('/unsorted')) {
    return Types.EntriesListingMode.unsorted;
  } else {
    return Types.EntriesListingMode.all;
  }
};

function selectedContainerId(state: Types.All) {
  return state.router.location.pathname.startsWith('/collections') &&
    state.router.location.pathname.length > 6
    ? state.router.location.pathname.split('/')[2]
    : undefined;
}

export function visiblePage(state: Types.All): number {
  return state.userInterface.visiblePage;
}

function numberOfPages(state: Types.All) {
  return Math.ceil(
    EntriesSelectors.matchingEntries(state).length /
      state.userInterface.pageSize
  );
}

export function pageSize(state: Types.All): number {
  return state.userInterface.pageSize;
}

function searchIsActive(state: Types.All): boolean {
  return searchPhraseSelector.length > 0;
}

export function searchPhraseSelector(state: Types.All): string {
  return state.userInterface.searchPhrase;
}

function numOfRenderedElementsSelector(state: Types.All): number {
  return state.userInterface.numberOfRenderedEntries;
}

function hintForBookmarkletIsVisible(state: Types.All): boolean {
  return state.userInterface.hintForBookmarkletIsVisible;
}

function hintForIntegrationIsVisible(state: Types.All): boolean {
  return state.userInterface.hintForIntegrationIsVisible;
}

function modalForFeedbackIsVisible(state: Types.All): boolean {
  return state.userInterface.modalForFeedbackIsVisible;
}

function sidebarDrawerIsVisible(state: Types.All): boolean {
  return state.userInterface.showsSidebarDrawer;
}

function activeMenuItem(state: Types.All): Actions.MenuItem {
  if (state.router.location.pathname.startsWith('/collections')) {
    return Actions.MenuItem.Collections;
  } else {
    return Actions.MenuItem.All;
  }
}

const visibleContainerModal = (
  state: Types.All
): Types.ContainerModal | undefined => {
  return state.userInterface.visibleContainerModal;
};

const UserInterfaceSelectors = {
  initialFetchIsDone,
  editEntryViewIsVisibleSelector,
  entriesListingMode,
  selectedContainerId,
  visiblePage,
  numberOfPages,
  pageSize,
  searchIsActive,
  searchPhraseSelector,
  numOfRenderedElementsSelector,
  hintForBookmarkletIsVisible,
  hintForIntegrationIsVisible,
  modalForFeedbackIsVisible,
  activeMenuItem,
  sidebarDrawerIsVisible,
  visibleContainerModal,
};

export default UserInterfaceSelectors;
