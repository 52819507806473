// This modules handles communication with a Rails backend that uses devise.
// The module needs to know the host address of the backend, it assumes the default configuration

import * as apiPaths from './apiPaths';
import * as Thunk from 'redux-thunk';
import * as Types from '../State/types';
import { SessionOperations } from '../State/Session';
import moment from 'moment';
import Processor from '../lib/PostProcess';
import { ContainersOperations } from '../State/Containers';
import { EntriesOperations } from '../State/Entries';
import * as PersistanceManagement from '../lib/PersistanceManagement';

export function fetchWithCredentials(
  requestURL: URL,
  method: string = 'GET',
  body: string | null = null
): Promise<Response> {
  return fetch(requestURL.toString(), {
    method,
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    credentials: 'include',
    body,
  }).then((response) => {
    if (response.status === 401) {
      PersistanceManagement.clearPersitentStorage();
      const loginUrl = apiPaths.loginURL();
      loginUrl.searchParams.append('referrer', 'logout_forward');
      window.location.assign(loginUrl.toString());
      throw response;
    } else if (response.status >= 400 && response.status <= 500) {
      throw response;
    } else {
      return response;
    }
  });
}

export const goTologin = (): void => {
  global.console.log('Going to login');
  window.location.assign(apiPaths.loginURL().toString());
};

/**
 *
 * @description This method never returns the raw response body,
 *  it only returns JavaScript objects in a form that corresponds
 *  to the client (i.e. preprocessed).
 *
 *  Type `T` should only refer to the part of the response
 *     that is not handled automatically (i.e. containers, deleted_container_ids,...)
 *
 *  Regarding errors:
 *  - 2xx successful, pre-process and return result to app
 *  - 4xx
 *      - 400 (bad request): report! (or can the backend do it?)
 *      - 401-403 (unauthorized, forbidden): logout
 *      - unauthenticated: logout
 *      - ???
 *  - 5xx server error: ignore or display a notification
 *
 *  If there's an `updated_since` in the request, the response
 *  has these keys, that are handled automatically:
 *  - `containers`: all containers edited since `updated_since`
 *  - `deleted_container_ids`: all containers deleted since `updated_since`
 *  - `entries`: all entries edited since `updated_since`
 *  - `deleted_entry_ids`: all entries deleted since `updated_since`
 *  - `response_time`: the current server time, to save for correspondence
 * @param requestURL the URL where the request should go to
 * @param method HTTP method of the request
 * @param body body of the request, can be `undefined`
 * @param successActionCreator A ThunkAction creator, that takes an optional response object
 *      and returns the function that is to be used as a callback
 * @param failureAction
 */

export function fetchWithCredentialsAndPostprocessing<T>(
  requestURL: URL,
  method: string = 'GET',
  body: string | null = null,
  successActionCreator: (response: T) => Types.UnconfuseAction,
  failureActionCreator: (error: Types.UnconfuseError) => Types.UnconfuseAction
): Thunk.ThunkAction<
  Promise<Types.AllActions> | Types.AllActions,
  Types.All,
  undefined,
  Types.AllActions
> {
  return async function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ): Promise<Types.AllActions> {
    //  global.console.log(`Starting new fetch`);

    const lastUpdatedAt = getState().session.lastRequestAt;
    lastUpdatedAt &&
      requestURL.searchParams.set('updated_since', lastUpdatedAt.toISOString());

    const response = await fetch(requestURL.toString(), {
      method,
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      credentials: 'include',
      body,
    });

    //  global.console.log(`Response received`);

    try {
      if (response.status >= 400 && response.status < 500) {
        // failureAction;
        dispatch(SessionOperations.logout());
        return dispatch(
          failureActionCreator({
            message: `Received status ${response.status}`,
            level: Types.ErrorLevel.error,
          })
        );
      } else {
        return response.json().then((json) => {
          if (
            !json.containers ||
            !json.deleted_container_ids ||
            !json.entries ||
            !json.deleted_entry_ids
          ) {
            return dispatch(
              failureActionCreator({
                message: `Missing a reponse key`,
                level: Types.ErrorLevel.error,
              })
            );
          }
          const containerById = Processor.containersAPIToClient(
            json.containers
          );
          const deletedContainerIds = json.deleted_container_ids.map(
            (id: number) => id.toString()
          );
          dispatch(
            ContainersOperations.receivedContainerUpdate(
              containerById,
              deletedContainerIds
            )
          );
          const processedEntries = Processor.entriesAPIToClient(json.entries);
          const deletedEntryIds = json.deleted_entry_ids.map((id: number) =>
            id.toString()
          );
          dispatch(
            EntriesOperations.receivedEntriesUpdate(
              processedEntries,
              deletedEntryIds
            )
          );
          dispatch(
            SessionOperations.saveLastRequestDate(moment(json.response_time))
          );
          return dispatch(successActionCreator(json as T));
        });
      }
    } catch (e) {
      return dispatch(
        failureActionCreator({
          message: `Some error happened ${e}`,
          level: Types.ErrorLevel.error,
        })
      );
    }
  };
}
