import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Input, Modal, Tooltip } from 'antd';
import React, { useState } from 'react';
import * as Redux from 'react-redux';
import * as Thunk from 'redux-thunk';
import { ContainersOperations } from '../../State/Containers';
import * as Types from '../../State/types';

export interface EditContainerModalProps {
  container?: Types.Container;
  parentContainerId?: Types.StringModelId;
  visible: boolean;
  className: string;
  title: string;
  afterSave: (id: Types.StringModelId) => void;
  onCancel: () => void;
}

export interface EditContainerModalStateProps {
  container?: Types.Container;
  visible: boolean;
  className: string;
  title: string;
  parentContainerId?: Types.StringModelId;
}

export interface EditContainerModalDispatchProps {
  afterSave: (id: Types.StringModelId) => void;
  onCancel: () => void;
  updateContainer: (
    containerId: Types.StringModelId,
    containerProps: Types.UpdatedableContainer
  ) => void;
  newContainerAt: (
    name: string,
    aliases: string,
    description: string,
    parentContainerId: Types.StringModelId,
    index: number
  ) => void;
}

export interface EditContainerModalInnerProps
  extends EditContainerModalStateProps,
    EditContainerModalDispatchProps {}

const EditContainerModal = ({
  container,
  visible,
  className,
  title,
  parentContainerId,
  afterSave,
  onCancel,
  updateContainer,
  newContainerAt,
}: EditContainerModalInnerProps) => {
  const [name, setName] = useState(container?.name ?? '');
  const [aliases, setAliases] = useState(container?.aliases ?? '');
  const [description, setDescription] = useState(container?.description ?? '');
  const [visibleState, setVisibleState] = useState(visible);

  // if the modal starts showing, re-fill the data
  if (visible && visible !== visibleState) {
    setName(container?.name ?? '');
    setAliases(container?.aliases ?? '');
    setDescription(container?.description ?? '');

    setVisibleState(visible);
  } else if (visible !== visibleState) {
    setVisibleState(visible);
  }

  const save = () => {
    if (container) {
      updateContainer(container.id, {
        name,
        aliases,
        description,
      });
      afterSave(container.id);
    } else if (parentContainerId) {
      newContainerAt(name, aliases, description, parentContainerId, 0);
    }
  };

  return (
    <Modal
      title={title}
      visible={visibleState}
      style={{ top: '3vh' }}
      onOk={save}
      onCancel={() => {
        setName('');
        setAliases('');
        setDescription('');
        onCancel();
      }}
      okText="Save"
      cancelText="Cancel"
      className={className}
    >
      <Form
        onFinish={save}
        layout="horizontal"
        labelCol={{
          xs: { span: 24 },
          sm: { span: 6 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 18 },
        }}
      >
        <Form.Item label="Name">
          <Input
            placeholder="Name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            autoFocus={true}
            autoComplete="false"
            autoCorrect="false"
            spellCheck={false}
            onPressEnter={save}
          />
        </Form.Item>
        <Form.Item label="Alias names">
          <Input
            placeholder="Alternate names"
            value={aliases}
            onChange={(event) => setAliases(event.target.value)}
            suffix={
              <Tooltip title="Looks up these words when you're searching for a folder">
                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
            }
            autoComplete="false"
            autoCorrect="false"
            spellCheck={false}
            onPressEnter={save}
          />
        </Form.Item>
        <Form.Item label="Description">
          <Input.TextArea
            placeholder="Description"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            onPressEnter={(e) => {
              if (e.metaKey && navigator.platform.indexOf('Mac') !== -1) {
                save();
              }
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

function mapStateToProps(
  state: Types.All,
  ownProps: EditContainerModalProps
): EditContainerModalStateProps {
  return {
    container: ownProps.container,
    visible: ownProps.visible,
    className: ownProps.className,
    title: ownProps.title,
  };
}

function mapDispatchToProps(
  dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
  ownProps: EditContainerModalProps
): EditContainerModalDispatchProps {
  return {
    onCancel: ownProps.onCancel,
    updateContainer: (
      containerId: Types.StringModelId,
      containerProps: Types.UpdatedableContainer
    ) => {
      dispatch(
        ContainersOperations.updateContainer(containerId, containerProps)
      );
    },
    newContainerAt: (
      name: string,
      aliases: string,
      description: string,
      parentContainerId: Types.StringModelId,
      index: number
    ) => {
      return dispatch(
        ContainersOperations.newContainerAt(
          name,
          Types.ContainerType.COLLECTION,
          'default',
          aliases,
          description,
          parentContainerId,
          index,
          (containerId) => ownProps.afterSave(containerId)
        )
      );
    },
    afterSave: ownProps.afterSave,
  };
}

export default Redux.connect(
  mapStateToProps,
  mapDispatchToProps
)(EditContainerModal);
