import { PlusOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import * as _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Thunk from 'redux-thunk';
import { ContainersOperations, ContainersSelectors } from '../../State/Containers';
import * as Types from '../../State/types';
import SelectContainerModal from '../Molecules/SelectContainerModal';

export interface CollectionListViewProps {
  collectionIds: Types.StringModelId[];
  visibleCollection?: Types.StringModelId;
  showAddForEntry?: Types.ModelId;
  onRemove: (containerId: Types.StringModelId) => void;
}

export interface CollectionListViewStateProps {
  containerPathsWithContainers: Types.Container[][];
  visibleCollection?: Types.StringModelId;
  showAddForEntry?: Types.ModelId;
}

export interface CollectionListViewDispatchProps {
  onRemove: (containerId: Types.StringModelId) => void;
  addEntryToCollection: (
    entryId: Types.ModelId,
    collectionId: Types.StringModelId
  ) => void;
}

export interface CollectionListViewInnerProps
  extends CollectionListViewStateProps,
    CollectionListViewDispatchProps {}

function CollectionListView(props: CollectionListViewInnerProps) {
  const {
    containerPathsWithContainers,
    visibleCollection,
    showAddForEntry,
    onRemove,
    addEntryToCollection,
  } = props;

  const [showCollectionSelect, setShowCollectionSelect] = useState(false);
  const [selectedCollectionId, setSelectedCollectionId] = useState<
    Types.StringModelId | undefined
  >(undefined);

  const close = (shouldSave: boolean = false, collectionId?: string) => {
    collectionId = collectionId ? collectionId : selectedCollectionId;
    if (collectionId && showAddForEntry && shouldSave) {
      addEntryToCollection(showAddForEntry, collectionId);
    }
    setSelectedCollectionId(undefined);
    setShowCollectionSelect(false);
  };

  return (
    <>
      {_.compact(
        containerPathsWithContainers.map(containerPath => {
          if (!containerPath.length) {
            return undefined;
          }
          const containerId = _.get(_.last(containerPath), 'id', '-1');
          return (
            <Tag
              key={containerId}
              closable={true}
              onClose={() => onRemove(containerId)}
              style={{ marginBottom: 4 }}
              className={
                visibleCollection === containerId
                  ? 'visible-container'
                  : undefined
              }
              visible={true}
            >
              <Link to={`/collections/${containerId}`}>
                {_.map(containerPath, 'name').join('/')}
              </Link>
            </Tag>
          );
        })
      )}
      {showAddForEntry ? (
        <>
          <Tag
            onClick={() => setShowCollectionSelect(true)}
            style={{
              background: '#fff',
              borderStyle: 'dashed',
              cursor: 'pointer',
            }}
          >
            <PlusOutlined /> Collection
          </Tag>
          <SelectContainerModal
            title={`Select collection to add`}
            visible={showCollectionSelect}
            onCancel={() => setShowCollectionSelect(false)}
            className="container_modal"
            limitSelectionTo={Types.ContainerType.COLLECTION}
            onSelect={selectedId => {
              close(true, selectedId);
            }}
            containersToDisable={containerPathsWithContainers.map(
              containerPath => _.get(_.last(containerPath), 'id', '-1')
            )}
          />
        </>
      ) : null}
    </>
  );
}

function mapStateToProps(
  state: Types.All,
  ownProps: CollectionListViewProps
): CollectionListViewStateProps {
  return {
    containerPathsWithContainers: ownProps.collectionIds.map(collectionId =>
      _.map(ContainersSelectors.treePathContainersFor(state, collectionId))
    ),
    showAddForEntry: ownProps.showAddForEntry,
  };
}

function mapDispatchToProps(
  dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
  ownProps: CollectionListViewProps
): CollectionListViewDispatchProps {
  return {
    onRemove: ownProps.onRemove,
    addEntryToCollection: (entryId, collectionId) => {
      dispatch(
        ContainersOperations.addEntryToCollection(entryId, collectionId)
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionListView);
