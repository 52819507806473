import * as Actions from './actions';
import * as Types from '../types';
import { getType } from 'typesafe-actions';
import { initialState } from '../initialState';
import * as PersistanceManagement from '../../lib/PersistanceManagement';

export default function session(
  state: Types.Session = initialState.session,
  action: Actions.LoginAction
): Types.Session {
  switch (action.type) {
    case getType(Actions.populateFromPersistance):
      return {
        ...state,
        ...action.payload.persistedState,
      };
    case getType(Actions.saveLastRequestDate):
    case getType(Actions.loginSuccess):
      return persist({
        ...state,
        ...action.payload,
      });
    case getType(Actions.loginFailure):
      return {
        ...state,
        loginState: Actions.LoginState.LoggedOut,
        errors: action.payload.errors,
      };
    case getType(Actions.logoutRequest):
      return { ...state, loginState: Actions.LoginState.LoggedOut };
    default:
      return state;
  }
}

const persist = (state: Types.Session) => {
  if (state.featureFlags.shouldStoreContent) {
    PersistanceManagement.persistSession(state);
  }
  return state;
};
