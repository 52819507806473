import * as Types from '../types';
import { createAction } from 'typesafe-actions';

export const populateFromPersitance = createAction(
  'POPULATE_CONTAINERS_FROM_PERSISTANCE',
  (partialState: Partial<Types.Containers>) => ({ partialState })
)();

export const fetchContainersRequest = createAction(
  'CONTAINERS_FETCH_REQUEST',
  () => ({})
)();

export const fetchContainersSuccess = createAction(
  'CONTAINERS_FETCH_SUCCESS',
  (containers: Types.ById<Types.Container>) => ({ containers })
)();

export const receivedContainerUpdate = createAction(
  'RECEIVED_CONTAINER_UPDATE',
  (
    containerById: Types.ById<Types.Container>,
    deletedContainers: Types.StringModelId[]
  ) => ({ containerById, deletedContainers })
)();

// Delete entries
export const deleteContainerRequest = createAction(
  'CONTAINER_DELETE_REQUEST',
  (containerID: Types.StringModelId) => ({ containerID })
)();

export const deleteContainerSuccess = createAction(
  'CONTAINER_DELETE_SUCCESS',
  (
    containerId: Types.StringModelId,
    containerById: Types.ById<Types.Container>
  ) => ({ containerId, containerById })
)();

export const deleteContainerFailure = createAction(
  'CONTAINER_DELETE_FAILURE',
  (containerID: Types.StringModelId, error: Types.UnconfuseError) => ({
    containerID,
    error,
  })
)();

// Create entries
export const createContainerRequest = createAction(
  'CONTAINER_CREATE_REQUEST',
  () => ({})
)();

export const createContainerSuccess = createAction(
  'CONTAINER_CREATE_SUCCESS',
  () => {}
)();

export const createContainerFailure = createAction(
  'CONTAINER_CREATE_FAILURE',
  (errors: string[]) => ({ errors })
)();

// Update entries
export const updateContainerRequest = createAction(
  'CONTAINER_UPDATE_REQUEST',
  (newContainerProps: object) => ({ newContainerProps })
)();

export const updateContainerSuccess = createAction(
  'CONTAINER_UPDATE_SUCCESS',
  () => {}
)();

export const updateContainerFailure = createAction(
  'CONTAINER_UPDATE_FAILURE',
  (containerID: Types.StringModelId, error: Types.UnconfuseError) => ({
    containerID,
    error,
  })
)();

export const enablePersistance = createAction(
  'ENABLE_CONTAINER_PERSISTANCE',
  () => {}
)();

export type ContainersAction =
  | ReturnType<typeof populateFromPersitance>
  | ReturnType<typeof fetchContainersRequest>
  | ReturnType<typeof fetchContainersSuccess>
  | ReturnType<typeof receivedContainerUpdate>
  | ReturnType<typeof deleteContainerRequest>
  | ReturnType<typeof deleteContainerSuccess>
  | ReturnType<typeof deleteContainerFailure>
  | ReturnType<typeof updateContainerRequest>
  | ReturnType<typeof updateContainerSuccess>
  | ReturnType<typeof updateContainerFailure>
  | ReturnType<typeof createContainerRequest>
  | ReturnType<typeof createContainerSuccess>
  | ReturnType<typeof createContainerFailure>
  | ReturnType<typeof enablePersistance>;
