import { Modal } from 'antd';
import React from 'react';
import * as Redux from 'react-redux';
import * as Thunk from 'redux-thunk';
import * as Types from '../../State/types';
import ContainerSelectView from '../Containers/ContainerSelectView';

export interface SelectContainerModalProps {
  visible: boolean;
  className: string;
  title: string;
  containersToDisable: Types.StringModelId[];
  limitSelectionTo: Types.ContainerType;
  onSelect: (selectedContainerId: Types.StringModelId) => void;
  onCancel: () => void;
}

export interface SelectContainerModalStateProps {
  visible: boolean;
  className: string;
  title: string;
  containersToDisable: Types.StringModelId[];
  limitSelectionTo: Types.ContainerType;
}

export interface SelectContainerModalDispatchProps {
  onSelect: (selectedContainerId: Types.StringModelId) => void;
  onCancel: () => void;
}

export interface SelectContainerModalInnerProps
  extends SelectContainerModalStateProps,
    SelectContainerModalDispatchProps {}

const SelectContainerModal = ({
  visible,
  className,
  containersToDisable,
  title,
  limitSelectionTo,
  onSelect,
  onCancel,
}: SelectContainerModalInnerProps) => {
  return (
    <Modal
      title={title}
      style={{ top: '5vh' }}
      visible={visible}
      onCancel={onCancel}
      className={className}
      footer={null}
    >
      {containerSelectForm(
        containersToDisable,
        selectedId => selectedId && onSelect(selectedId),
        visible,
        limitSelectionTo,
      )}
    </Modal>
  );
};

function containerSelectForm(
  containersToDisable: Types.StringModelId[],
  onSelect: (selectedId: Types.StringModelId | undefined) => void,
  visible: boolean,
  limitSelectionTo?: Types.ContainerType,
) {
  return (
    /* the value of `--vh` is calculated in calculateAndSetVerticalHeight() in index.tsx */
    <div style={{ height: 'calc(var(--vh, 1vh) * 80)' }}>
      <ContainerSelectView
        disabledContainerIds={containersToDisable}
        onSelect={onSelect}
        limitSelectionTo={limitSelectionTo}
        autofocus={true}
        visible={visible}
      />
    </div>
  );
}

function mapStateToProps(
  state: Types.All,
  ownProps: SelectContainerModalProps
): SelectContainerModalStateProps {
  return {
    visible: ownProps.visible,
    className: ownProps.className,
    title: ownProps.title,
    containersToDisable: ownProps.containersToDisable,
    limitSelectionTo: ownProps.limitSelectionTo,
  };
}

function mapDispatchToProps(
  dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
  ownProps: SelectContainerModalProps
): SelectContainerModalDispatchProps {
  return {
    onSelect: ownProps.onSelect,
    onCancel: ownProps.onCancel,
  };
}

export default Redux.connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectContainerModal);
