import { Layout } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import * as Types from '../State/types';
import { UISelectors } from '../State/UserInterface';
import './App.css';
import Bookmarklet from './Bookmarklet';
import ContentView from './TopLevelViews/ContentView';
import FeedbackForm from './UserGuidance/FeedbackForm';

export interface AppStateProps {
  editEntryViewIsVisible: boolean;
  sidebarDrawerIsVisible: boolean;
}

export class App extends React.Component<AppStateProps, {}> {
  render() {
    return (
      <>
        <Switch>
          <Route exact={true} path="/bookmarklet_add" component={Bookmarklet} />
          <Route
            render={() => (
              <Layout className="AppWrapper">
                <Route path="*" component={ContentView} />
              </Layout>
            )}
          />
        </Switch>
        <FeedbackForm />
      </>
    );
  }
}

function mapStateToProps(state: Types.All): AppStateProps {
  return {
    editEntryViewIsVisible: UISelectors.editEntryViewIsVisibleSelector(state),
    sidebarDrawerIsVisible: UISelectors.sidebarDrawerIsVisible(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
