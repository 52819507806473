import 'antd/dist/antd.css';
import { ConnectedRouter } from 'connected-react-router';
import * as localForage from 'localforage';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Thunk from 'redux-thunk';
import App from './Components/App';
import './index.css';
import * as serviceWorker from './registerServiceWorker';
import { SessionOperations } from './State/Session';
import configureStore, { history } from './State/store';
import * as Types from './State/types';

localForage.config({
  // driver: localforage.WEBSQL, // Force WebSQL; same as using setDriver()
  name: 'unconfuse',
  version: 1.0,
  // size: 4980736, // Size of database, in bytes. WebSQL-only for now.
  // storeName: 'keyvaluepairs', // Should be alphanumeric, with underscores.
  description: 'Local storage for containers and entries.',
});

let store = configureStore();
const dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions> =
  store.dispatch;
dispatch(SessionOperations.startApp());

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root') as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// # Vertical height fix for bookmarklet on mobile #
// The bookmarklet on mobile phones can not use the browser supplied vh
//  because the browser does not take the bottom toolbar into account.
//  Therefore we need this awkward workaround and we calculate the height
//  in the CSS the following way: `height: calc(var(--vh, 1vh) * 100);`
// This awesome trick is taken from
//  https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
const calculateAndSetVerticalHeight = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

calculateAndSetVerticalHeight();

// We listen to the resize event
window.addEventListener('resize', () => {
  calculateAndSetVerticalHeight();
});
// END Vertical height fix
