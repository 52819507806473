import { createBrowserHistory, History } from 'history';
import * as Redux from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import * as Types from './types';
import { compose, combineReducers } from 'redux';

import { default as entries } from './Entries';
import { default as containers } from './Containers';
import { default as session } from './Session';
import { default as userInterface } from './UserInterface';
import { connectRouter, routerMiddleware } from 'connected-react-router';

const createRootReducer = (history: History) =>
  combineReducers<Types.All>({
    router: connectRouter(history),
    userInterface,
    entries,
    containers,
    session,
  });

export const history = createBrowserHistory();

export default function configureStore(
  // this could also be History.LocationState
): Redux.Store<Types.All> {
  const middlewares: Redux.Middleware[] = [thunk, routerMiddleware(history)];

  if (process.env.NODE_ENV === `development`) {
    const logger = createLogger();
    middlewares.push(logger);
  }

  const composeEnhancer: typeof compose =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store: Redux.Store<Types.All> = Redux.createStore(
    createRootReducer(history),
    composeEnhancer(
      Redux.applyMiddleware(...middlewares)
    )
  );

  return store;
}
