import * as moment from 'moment';

import { EntriesAction } from './Entries/actions';
import { UnconfuseUIAction } from './UserInterface/actions';
import { LoginAction } from './Session/actions';
import { ContainersAction } from './Containers/actions';
import * as Thunk from 'redux-thunk';
import { RouterState, RouterAction } from 'connected-react-router';

import * as SessionModel from './Session/actions';

export type UnconfuseAction = Thunk.ThunkAction<
  Promise<AllActions> | AllActions,
  All,
  undefined,
  AllActions
>;

export type AllActions =
  | RouterAction
  | EntriesAction
  | UnconfuseUIAction
  | ContainersAction
  | LoginAction;

export type ModelId = string;
export type StringModelId = string;

/**
 * error: app can continue to run
 * fatal: app must be reloaded
 */
export enum ErrorLevel {
  error = 'error',
  fatal = 'fatal',
}

export type UnconfuseError = {
  message: string;
  level: ErrorLevel;
};

export enum ContainerModal {
  edit = 'edit',
  merge = 'merge',
  move = 'move',
  split = 'split',
}

/**
 * Relevant for which entries are selected in the sidebar
 * 1. all = all entries
 * 2. unsorted = entries without collection
 * 3. <collection> = entries from the collection
 * 4. <folder> = [not yet implemented]
 */
export enum EntriesListingMode {
  all = 'all',
  unsorted = 'unsorted',
  collection = 'collection',
  folder = 'folder'
}

/**
 * Generic data structure to keep model instances and make
 * them accessible by their Id
 */
export interface ById<T> {
  [key: string]: T;
}

export type UserInterface = {
  editEntryViewVisible: boolean;
  visibleContainerModal?: ContainerModal;
  visiblePage: number;
  pageSize: number;
  searchPhrase: string;
  selectedCollectionKey?: string;
  numberOfRenderedEntries: number;
  hintForBookmarkletIsVisible: boolean;
  hintForIntegrationIsVisible: boolean;
  modalForFeedbackIsVisible: boolean;
  errors: string[];
  showsSidebarDrawer: boolean;
};

export type Session = {
  loginState: SessionModel.LoginState;
  email: string;
  userID: number;
  errors: string[];
  featureFlags: SessionModel.FeatureFlags;
  rootFolderId: StringModelId;
  numOfEntries: number;
  numOfContainers: number;
  lastRequestAt?: moment.Moment;
};

export type Entry = {
  id: ModelId;
  title: string;
  description: string;
  url: string;
  loggedAt: moment.Moment;
  updatedAt: moment.Moment;
  source?: string;
  domain?: string;
  collections: string[];
  keywords?: string;
};

export type NewEntry = {
  id?: ModelId;
  title: string;
  description: string;
  url: string;
  loggedAt?: moment.Moment;
  source?: string;
  collections: string[];
  keywords?: string;
};

export type EntryInEdit = {
  id: ModelId;
  title: string;
  description: string;
  url: string;
  loggedAt: moment.Moment;
  collections: StringModelId[];
};

export type Entries = {
  byId: { [key: string]: Entry };
  allIds: ModelId[];
  initialFetchOfEntriesIsDone: boolean;
  mostDistantFetchedDate: moment.Moment;
  nearestFetchedDate: moment.Moment;
  entryInEdit: undefined | EntryInEdit;
  shouldPersist?: boolean;
};

// export type ContainerType = "folder" | "collection" | "smartFolder";
export enum ContainerType {
  FOLDER = 'folder',
  COLLECTION = 'collection',
  SMARTFOLDER = 'smartfolder',
}

export interface Container {
  id: StringModelId;
  name: string;
  containerType: ContainerType;
  icon: string;
  aliases: string;
  description: string;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  entries: StringModelId[];
  children?: StringModelId[];
  parentId?: StringModelId;
  position: number;
}

export type UpdatedableContainer = Partial<
  Pick<
    Container,
    | 'parentId'
    | 'position'
    | 'name'
    | 'entries'
    | 'containerType'
    | 'icon'
    | 'aliases'
    | 'description'
  >
>;

export interface ContainerOccurrences {
  [collectionId: string]: {
    occurences: Entry[];
    path: string;
    id: StringModelId;
    parentId?: StringModelId;
  };
}

export interface Collection extends Container {
  containerType: ContainerType.COLLECTION;
}

export interface Folder extends Omit<Container, 'children'> {
  containerType: ContainerType.FOLDER;
  children: (Folder | Collection)[];
}

export interface ContainerTreeNode {
  id: StringModelId;
  children: ContainerTreeNode[];
  containerType: ContainerType;
  name: string;
  key: string;
  icon: string;
  entriesNum: number;
}

export interface EntryToCollectionMap {
  [key: string]: StringModelId[];
}

export interface Containers {
  byId: ById<Container>;
  initialFetchOfContainersIsDone: boolean;
  byEntryId: EntryToCollectionMap;
  shouldPersist?: boolean;
}

export type All = {
  userInterface: UserInterface;
  entries: Entries;
  containers: Containers;
  session: Session;
  router: RouterState;
};

export interface DefaultResponse {
  containers: ServerContainer[];
  entries: ServerEntryInbound[];
  response_time: string;
  deleted_entry_ids: number[];
  deleted_container_ids: number[];
}

export type PersistEntry = Omit<Entry, 'loggedAt' | 'updatedAt'> & {
  loggedAt: string;
  updatedAt: string;
};

export type PersistContainer = Omit<Container, 'createdAt' | 'updatedAt'> & {
  createdAt: string;
  updatedAt: string;
};

export interface PersistanceSession {
  lastRequestAt: moment.Moment;
  featureFlags: SessionModel.FeatureFlags;
  rootFolderId: StringModelId;
}

export interface PersistedSession {
  lastRequestAt: string;
  featureFlags: SessionModel.FeatureFlags;
  rootFolderId: StringModelId;
}

export interface PersistanceEntries {
  byId: ById<PersistEntry>;
}

export interface PersistanceContainers {
  byId: ById<PersistContainer>;
}

export interface PersistedData {
  containers: {
    byId: ById<Container>;
  };
  entries: {
    byId: ById<Entry>;
  };
  session: PersistanceSession;
}

export interface ServerEntry {
  id: number;
  title: string;
  url: string;
  collections?: string[];
  description: string;
}

export interface ServerEntryInbound extends ServerEntry {
  logged_at: string;
  updated_at: string;
}

export interface ServerEntryOutbound extends Omit<ServerEntry, 'id'> {
  logged_at?: string;
  updated_at?: string;
}

export interface ServerContainer {
  id: number;
  name: string;
  entries: string;
  container_type: 'folder' | 'collection' | 'smartfolder';
  icon: string;
  aliases: string;
  description: string;
  created_at: string;
  updated_at: string;
  parent_id?: number;
  position: number;
}

export interface SendContainer
  extends Omit<Container, 'id' | 'updatedAt' | 'createdAt'> {}

export interface SendServerContainer
  extends Omit<ServerContainer, 'id' | 'updated_at' | 'created_at'> {}
