import * as Types from '../types';
import { SessionSelectors } from '../Session';
import * as _ from 'lodash';

// This function is to be used only for debugging during development
function allContainersById(state: Types.All) {
  return state.containers.byId;
}

function rootContainer(state: Types.All) {
  return containerById(state, SessionSelectors.rootFolderId(state));
}

function containerById(
  state: Types.All,
  id: Types.StringModelId
): Types.Container {
  return state.containers.byId[id];
}

function collectionsForEntry(
  state: Types.All,
  entryId: Types.StringModelId
): Types.StringModelId[] {
  return state.containers.byEntryId[entryId] || [];
}

export function containerTree(state: Types.All): Types.ContainerTreeNode {
  const root = state.containers.byId[SessionSelectors.rootFolderId(state)];
  return !root
    ? {
        id: '-1',
        key: '-1',
        containerType: Types.ContainerType.FOLDER,
        name: 'root',
        icon: 'root',
        children: [],
        entriesNum: 0,
      }
    : {
        id: root.id,
        key: root.id,
        containerType: Types.ContainerType.FOLDER,
        name: 'root',
        icon: 'root',
        children: root.children
          ? root.children.map((childId, idx) => {
              return createContainerNode(
                state,
                containerById(state, childId),
                keyForParentKeySelfAndIndex(root.id, childId, idx)
              );
            })
          : [],
        entriesNum: 0,
      };
}

function createContainerNode(
  state: Types.All,
  container: Types.Container,
  nodeKey: string
): Types.ContainerTreeNode {
  if (container.containerType === Types.ContainerType.FOLDER) {
    return {
      ...container,
      containerType: Types.ContainerType.FOLDER,
      key: nodeKey,
      children: (container.children || []).map((containerID, idx) =>
        createContainerNode(
          state,
          containerById(state, containerID),
          keyForParentKeySelfAndIndex(nodeKey, containerID, idx)
        )
      ),
      entriesNum: 0,
    };
  } else {
    return treeNodeForCollection(container, nodeKey);
  }
}

// ## SEARCH ##
const containerTreeForSearchPhaseCreator = (
  state: Types.All
): ((searchPhrase: string) => Types.ContainerTreeNode) => {
  return function(searchPhrase: string) {
    return containerTreeForSearchPhrase(state, searchPhrase);
  };
};

// Returns all nodes of the tree, where either the node itself or
//  any subnode matches or undefined if there's no match
const containerTreeForSearchPhrase = (
  state: Types.All,
  searchPhrase: string
): Types.ContainerTreeNode => {
  return containerTreeForSearchPhraseWithMatchingIds(state, searchPhrase).tree;
};

const containerTreeForSearchPhraseWithMatchingIds = (
  state: Types.All,
  searchPhrase: string
): {
  tree: Types.ContainerTreeNode;
  matchingPaths: string[];
  matchingIds: string[];
} => {
  const fullTree = containerTree(state);

  if (searchPhrase.length > 0) {
    const matches = containerNodesMatchingSearchPhrase(
      state,
      fullTree,
      searchPhrase
    );
    return {
      tree: matches?.matchingSubtree || {
        ...fullTree,
        children: [],
      },
      matchingPaths: matches?.matchingPaths ?? [],
      matchingIds: matches?.matchingIds ?? [],
    };
  } else {
    return { tree: fullTree, matchingPaths: [], matchingIds: [] };
  }
};

const containerTreeForSearchPhaseWithMatchingIdsCreator = (
  state: Types.All
): ((
  searchPhrase: string
) => {
  tree: Types.ContainerTreeNode;
  matchingPaths: string[];
  matchingIds: string[];
}) => {
  return function(searchPhrase: string) {
    return containerTreeForSearchPhraseWithMatchingIds(state, searchPhrase);
  };
};

// Support method for containerTreeForSearchPhrase(WithMatchingIds), so that it
//   can be recursive
//
export const containerNodesMatchingSearchPhrase = (
  state: Types.All,
  containerNode: Types.ContainerTreeNode,
  searchPhrase: string
):
  | {
      matchingSubtree: Types.ContainerTreeNode;
      matchingIds: string[];
      matchingPaths: string[];
    }
  | undefined => {
  const container = containerById(state, containerNode.id);

  if (containerStringMatchesSearchPhrase(container, searchPhrase)) {
    // This matches collections or folders
    return {
      matchingSubtree: containerNode,
      matchingPaths: [],
      matchingIds: [container.id],
    };
  } else if (container.containerType === Types.ContainerType.COLLECTION) {
    // This is to make it more explicit for non-matching collections
    //  that they return undefined
    return undefined;
  } else {
    // For not-direcly matching folders, we traverse into the children
    //  if there's a match in the subtree, those matches are returned
    //  and with the id of every folder in that path to the match
    const matchingChildrenAndKeys = _.compact(
      containerNode.children.map(child =>
        containerNodesMatchingSearchPhrase(state, child, searchPhrase)
      )
    );
    return matchingChildrenAndKeys.length > 0
      ? {
          matchingSubtree: {
            ...containerNode,
            children: matchingChildrenAndKeys.map(
              matchingChildAndIds => matchingChildAndIds.matchingSubtree
            ),
          },
          matchingPaths: _.flatten(
            matchingChildrenAndKeys.map(
              matchingChildAndIds => matchingChildAndIds.matchingPaths
            )
          ).concat(container.id),
          matchingIds: _.flatten(
            matchingChildrenAndKeys.map(
              matchingChildAndIds => matchingChildAndIds.matchingIds
            )
          ),
        }
      : undefined;
  }
};

// ## PATHS ##
// Returns an array containing the ids of all the containers in the direct path from the root
// container to the given container; the first element is the topmost parent directly below the
// root container the last element is the id of the direct parent of the given container
export const treePathFor = (
  state: Types.All,
  containerId: Types.StringModelId
): string => {
  const pathContainers = treePathContainersFor(state, containerId);
  return _.map(pathContainers, 'name').join('/');
};

// Returns all containers on the direct path from the root container to the given container;
// The first element in the returning array is the child of the root container (NOT the root container
// itself), the last element is the direct parent of the given container
export const treePathContainersFor = (
  state: Types.All,
  containerID: Types.StringModelId
) => {
  const treePath = [] as Types.Container[];

  let parentContainer: Types.Container | undefined = containerById(
    state,
    containerID
  );

  if (!parentContainer || !rootContainer) {
    return treePath;
  }

  while (parentContainer) {
    treePath.push(parentContainer);
    parentContainer = parentContainerFor(state, parentContainer.id);
  }
  return _.tail(_.reverse(treePath));
};

// Returns the container which has the childId as a child container
export function parentContainerFor(
  state: Types.All,
  childId: Types.StringModelId
): Types.Container | undefined {
  const parentContainerId = _.get(containerById(state, childId), 'parentId');
  return parentContainerId
    ? containerById(state, parentContainerId)
    : undefined;
}

export const recentCollectionsTree = (state: Types.All) => {
  return Object.values(state.containers.byId)
    .filter(cont => cont.containerType === Types.ContainerType.COLLECTION)
    .sort((a, b) => b.updatedAt.diff(a.updatedAt))
    .map(coll => treeNodeForCollection(coll, coll.id));
};

const SelectorOperations = {
  containerById,
  allContainersById,
  rootContainer,
  containerTreeForSearchPhaseCreator,
  containerTreeForSearchPhrase,
  containerTreeForSearchPhaseWithMatchingIdsCreator,
  containerTreeForSearchPhraseWithMatchingIds,
  containerTree,
  collectionsForEntry,
  treePathFor,
  treePathContainersFor,
  parentContainerFor,
  recentCollectionsTree,
};
export default SelectorOperations;

export function containerStringMatchesSearchPhrase(
  container: Types.Container,
  searchPhrase: string
): boolean {
  const containerStringElements = _.compact(
    `${container.name} ${container.aliases}`.toLocaleLowerCase().split(' ')
  );
  const searchPhraseElements = _.compact(
    searchPhrase.toLocaleLowerCase().split(' ')
  );

  // return true if any searchPhrase element can be found in any containerString element
  return searchPhraseElements.some(
    searchPhraseElement =>
      containerStringElements.filter(
        containerStringElement =>
          containerStringElement.indexOf(searchPhraseElement) > -1
      ).length > 0
  );
}

// Each key contains the whole path of the node
function keyForParentKeySelfAndIndex(
  parentKey: Types.StringModelId,
  containerId: Types.StringModelId,
  idx: number
) {
  return `${parentKey}.${idx}-${containerId}`;
}

function treeNodeForCollection(container: Types.Container, key: string) {
  return {
    id: container.id,
    key,
    icon: container.icon,
    name: container.name,
    containerType: Types.ContainerType.COLLECTION,
    children: [],
    entriesNum: container.entries.length,
  };
}
