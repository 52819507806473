import * as Types from '../types';
import { createAction } from 'typesafe-actions';

export enum MenuItem {
  Inbox = 'inbox',
  Tags = 'tags',
  All = 'all',
  Collections = 'collections',
}

export const updateSearchPhrase = createAction(
  'SEARCH_PHRASE_UPDATE',
  (searchPhrase: string) => ({ searchPhrase })
)();

export const setSelectedCollection = createAction(
  'SELECT_COLLECTION',
  (selectedCollectionKey: string | undefined) => ({ selectedCollectionKey })
)();

export const setPage = createAction(
  'UI_SET_PAGE_NUMBER',
  (pageNumber: number) => {
    if (pageNumber < 1) {
      pageNumber = 1;
    }
    return { pageNumber };
  }
)();

export const renderMoreElements = createAction(
  'NUM_OF_RENDERED_ENTRIES_INCREASE',
  () => {}
)();

export const renderDefaultNumberOfElements = createAction(
  'NUM_OF_RENDERED_ENTRIES_RESET',
  () => {}
)();

export const showBookmarkletHint = createAction(
  'UI_BOOKMARKLET_HINT_SHOW',
  () => {}
)();

export const hideBookmarkletHint = createAction(
  'UI_BOOKMARKLET_HINT_HIDE',
  () => {}
)();

export const showIntegrationsHint = createAction(
  'UI_INTEGRATION_HINT_SHOW',
  () => {}
)();

export const hideIntegrationsHint = createAction(
  'UI_INTEGRATION_HINT_HIDE',
  () => {}
)();

export const showFeedbackModal = createAction(
  'UI_FEEDBACK_MODAL_SHOW',
  () => {}
)();

export const hideFeedbackModal = createAction(
  'UI_FEEDBACK_MODAL_HIDE',
  () => {}
)();

export const showEditEntryView = createAction(
  'SHOW_EDIT_ENTRY_VIEW',
  () => {}
)();

export const hideEditEntryView = createAction(
  'HIDE_EDIT_ENTRY_VIEW',
  () => {}
)();

export const setVisibleContainerModal = createAction(
  'SET_CONTAINER_MODAL',
  (visibleModal?: Types.ContainerModal) => ({ visibleModal })
)();

export const selectContainer = createAction(
  'SELECT_CONTAINER',
  (selectedContainerPath: string | undefined) => ({
    selectedContainerPath,
  })
)();

export const setSidebarDrawerVisibility = createAction(
  'SET_SIDEBAR_DRAWER_VISIBILITY',
  (isVisible: boolean) => ({ isVisible })
)();

export type UnconfuseUIAction =
  | ReturnType<typeof updateSearchPhrase>
  | ReturnType<typeof setSelectedCollection>
  | ReturnType<typeof setPage>
  | ReturnType<typeof renderMoreElements>
  | ReturnType<typeof renderDefaultNumberOfElements>
  | ReturnType<typeof showBookmarkletHint>
  | ReturnType<typeof hideBookmarkletHint>
  | ReturnType<typeof showIntegrationsHint>
  | ReturnType<typeof hideIntegrationsHint>
  | ReturnType<typeof showFeedbackModal>
  | ReturnType<typeof hideFeedbackModal>
  | ReturnType<typeof showEditEntryView>
  | ReturnType<typeof hideEditEntryView>
  | ReturnType<typeof selectContainer>
  | ReturnType<typeof setVisibleContainerModal>
  | ReturnType<typeof setSidebarDrawerVisibility>;
