import { Modal, Tabs } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import * as Thunk from 'redux-thunk';
import { appBaseURL } from '../../lib/apiPaths';
import * as Types from '../../State/types';
import { UIOperations, UISelectors } from '../../State/UserInterface';

interface BookmarkletHintProps {
  bookmarkletHintIsVisible: boolean;
  hideBookmarkletHint: () => void;
}

function BookmarkletHint({
  bookmarkletHintIsVisible,
  hideBookmarkletHint,
}: BookmarkletHintProps) {
  const bookmarkletURL = `javascript:
        o = location.href;
        t=document.title;
        if (document.getSelection)
          %7B
          s=document.getSelection();
        %7D else %7B
            d="";
            %7D;
        void(
          open(
            "${appBaseURL()}/bookmarklet_add
            ?url="+encodeURIComponent(o)+"
            &description="+encodeURIComponent(s)+"
            &title="+encodeURIComponent(t),
            "unconfuse%20add",
            "toolbar=no,scrollbars=yes,width=750,height=700"
          )
        );`.replace(/(\r\n|\n|\r|\s)/gm, '');

  return (
    <Modal
      title="Quick entry logging with the bookmarklet"
      visible={bookmarkletHintIsVisible}
      onOk={hideBookmarkletHint}
      onCancel={hideBookmarkletHint}
      footer={''}
    >
      <p>
        For quick and easy logging while browsing web pages add the unconfuse
        bookmarklet to your browser favorites. It allows you to log any page or
        document that you are looking at.
      </p>
      <h3>How to install?</h3>

      <Tabs defaultActiveKey={matchMedia('(hover)').matches ? '1' : '2'}>
        <Tabs.TabPane tab="Desktop" key="1">
          <p>This is a single step activity.</p>
          <ol>
            <li>
              Drag the following link to your browser's bookmark bar:
              <br />
              Link:&nbsp;
              <a href={bookmarkletURL}>Log!</a>
            </li>
            <li>Done</li>
          </ol>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Mobile" key="2">
          <p>
            This is a multi-step activity. Please&nbsp;
            <a href="mailto:hello@unconfuse.me">write</a>
            &nbsp;if you're stuck.
          </p>
          <ol>
            <li>Create a bookmark by bookmarking this page</li>
            <li>
              Copy the following red string
              <br />
              <span style={{ color: 'red' }}>{bookmarkletURL}</span>
            </li>
            <li>Edit the bookmark that you created</li>
            <li>
              Paste the copied string into the <em>address</em> field and change
              the name to <em>Log!</em>
            </li>
            <li>Done.</li>
          </ol>
        </Tabs.TabPane>
      </Tabs>
      <p>
        From now on whenever you are on an interesting page, press the bookmark
        to log the page.
      </p>
    </Modal>
  );
}

function mapStateToProps(state: Types.All, ownProps: object) {
  return {
    bookmarkletHintIsVisible: UISelectors.hintForBookmarkletIsVisible(state),
  };
}

function mapDispatchToProps(
  dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>
) {
  return {
    hideBookmarkletHint: () => dispatch(UIOperations.hideBookmarkletHint()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookmarkletHint);
