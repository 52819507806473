import { Tooltip } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import * as Thunk from 'redux-thunk';
import { md5 } from '../../../lib/md5';
import * as ParseURL from '../../../lib/ParseURL';
import {
  ContainersOperations,
  ContainersSelectors
} from '../../../State/Containers';
import * as Types from '../../../State/types';
import CollectionListView from '../../Containers/CollectionListView';
import './Entry.css';

export interface EntryProps {
  entry: Types.Entry;
  visibleCollection?: Types.StringModelId;
}

interface EntryStateProps {
  entry: Types.Entry;
  collectionIds: Types.StringModelId[];
  visibleCollection?: Types.StringModelId;
}

interface EntryDispatchProps {
  onRemove: (containerId: Types.StringModelId) => void;
}

interface EntryInnerProps extends EntryStateProps, EntryDispatchProps {}

function Entry({
  entry,
  collectionIds,
  visibleCollection,
  onRemove,
}: EntryInnerProps) {
  const urlHash = md5(entry.url);

  return (
    <div className="entry">
      <div
        className="preview_image"
        style={{
          backgroundImage: `url('https://unconfuse-page-thumbs.s3.eu-central-1.amazonaws.com/${urlHash}-medium.png')`,
        }}
      ></div>
      <div className="info">
        <Tooltip
          mouseEnterDelay={0.8}
          mouseLeaveDelay={0.3}
          placement="topLeft"
          title={
            <>
              {`${entry.loggedAt.format('MMM DD, YYYY HH:mm')}`}
              <br />
              <span className="entry-tooltip-url">{entry.url}</span>
            </>
          }
        >
          <div>
            <a href={entry.url} className="title">
              {entry.title}
            </a>
          </div>
        </Tooltip>
        <div className="second_line">
          <div
            className="date"
            title={entry.loggedAt.format('MMM DD, YYYY HH:mm')}
          >
            {entry.loggedAt.format(
              entry.loggedAt.isBefore(moment(), 'year')
                ? 'MMM D, YYYY'
                : 'MMM D'
            )}
          </div>

          <div className="favicon_domain">
            <div
              className="favicon"
              style={{
                backgroundImage: `url('${ParseURL.forFaviconURL(entry.url)}')`,
              }}
            >
              &nbsp;
            </div>
            <div className="domain">{entry.domain}</div>
          </div>
        </div>

        {entry.description && (
          <div className="description">
            {/* <PushpinOutlined /> */}
            <div className="value">{entry.description}</div>
          </div>
        )}

        <div className="meta-info">
          <div className="collection_list">
            <CollectionListView
              collectionIds={collectionIds}
              visibleCollection={visibleCollection}
              onRemove={onRemove}
              showAddForEntry={entry.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(
  state: Types.All,
  ownProps: EntryProps
): EntryStateProps {
  return {
    entry: ownProps.entry,
    collectionIds: ContainersSelectors.collectionsForEntry(
      state,
      ownProps.entry.id.toString()
    ),
    visibleCollection: ownProps.visibleCollection,
  };
}

function mapDispatchToProps(
  dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
  ownProps: EntryProps
): EntryDispatchProps {
  return {
    onRemove: (collectionId) => {
      dispatch(
        ContainersOperations.removeEntryFromCollection(
          ownProps.entry.id,
          collectionId
        )
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Entry);
