import { Tree } from 'antd';
import _ from 'lodash';
import * as RcTree from 'rc-tree/lib/interface';
import React, { useState } from 'react';
import * as Redux from 'react-redux';
import { RouteComponentProps } from 'react-router';
import * as Thunk from 'redux-thunk';
import {
  ContainersOperations,
  ContainersSelectors
} from '../../State/Containers';
import * as Types from '../../State/types';
import './Sidebar.css';

export interface ContainerTreeStateProps {
  selectedContainerId: string;
  pathToSelectedContainer: string[];
  containerTree: Types.ContainerTreeNode;
  matchingPaths: string[];
  searchPhrase: string;
  allContainersById: { [containerId: number]: Types.Container };
  history: RouteComponentProps['history'];
}

export interface ContainerTreeDispatchProps {
  moveContainerTo: (
    id: Types.StringModelId,
    newParent: Types.StringModelId,
    idx: number
  ) => void;
}

export interface ContainerTreeProps
  extends ContainerTreeStateProps,
    ContainerTreeDispatchProps {}

interface ContainerTreeOwnProps {
  selectedContainerId: string;
  containerTree: Types.ContainerTreeNode;
  matchingPaths: string[];
  history: RouteComponentProps['history'];
  searchPhrase: string;
}

function ContainerTree({
  selectedContainerId,
  pathToSelectedContainer,
  containerTree,
  matchingPaths,
  searchPhrase,
  allContainersById,
  history,
  moveContainerTo,
}: ContainerTreeProps) {
  // # Expansion of tree #
  // The expansion of the tree nodes has to be handled manually, so that
  // search matches can be expanded automatically and tree nodes still be expanded
  // or closed.
  // Auto expanding is leveraged here, so that the node path does not manually have
  // to be inserted (although this might be necessary to have a more straightforward behavior).
  // Initially auto expanding is true. Expanding or closing nodes, switches the auto expanding off
  // and make the tree managed manually.
  // Changes to the search phrase or the selected container re-enable the auto-expanding and clear
  // the manually expanded keys.
  //
  // Notes:
  //   * it needs to be seen if this behavior is nice or not
  //   * this behavior is also desirable for other container trees, can it be abstracted?
  const frontNodes: Types.ContainerTreeNode[] = [
    {
      key: 'all',
      name: 'All',
      id: 'all',
      children: [],
      containerType: Types.ContainerType.COLLECTION,
      entriesNum: 0,
      icon: 'default',
    },
    {
      key: 'unsorted',
      name: 'Unsorted',
      id: 'unsorted',
      children: [],
      containerType: Types.ContainerType.COLLECTION,
      entriesNum: 0,
      icon: 'default',
    },
  ];

  const [manuallyExpandedKeys, setManuallyExpandedKeys] = useState(
    _.compact(pathToSelectedContainer) as string[]
  );

  // HACK
  //  This is probably not the React-way of handling an external state
  //   change's repercussions on the internal state. If you find a cleaner
  //   way, change the following implementation!
  const [internalSelectedContainerId, setInternalSelectedContainerId] =
    useState(selectedContainerId);
  if (internalSelectedContainerId !== selectedContainerId) {
    setInternalSelectedContainerId(selectedContainerId);
    setManuallyExpandedKeys(
      _.uniq(manuallyExpandedKeys.concat(pathToSelectedContainer))
    );
  }

  const [internalSearchPhrase, setInternalSearchPhrase] =
    useState(searchPhrase);
  if (internalSearchPhrase !== searchPhrase) {
    setInternalSearchPhrase(searchPhrase);
  }
  // HACK END

  // Key pattern
  //  ['36.1-1940', '36.1-1940.7-406']
  /** Node:
   * children: [];
   * containerType: 'collection';
   * entriesNum: 6;
   * icon: 'default';
   * id: '306';
   * key: '36.164-306';
   * name: 'advertisement';
   */
  const newContainerTree = { ...containerTree };
  newContainerTree.children = [...containerTree.children];
  newContainerTree.children.unshift(...frontNodes);

  return (
    <Tree
      className="tags-tree"
      draggable={true}
      onDrop={(info) => onDrop(info, allContainersById, moveContainerTo)}
      blockNode={true}
      selectedKeys={[selectedContainerId]}
      defaultExpandedKeys={[selectedContainerId]}
      expandedKeys={matchingPaths.concat(manuallyExpandedKeys)}
      // Only available in > 4.17.0
      // fieldNames={{title: 'name', key: 'key', children: 'children'}}
      // switcherIcon={<DownOutlined />}
      autoExpandParent={false}
      defaultExpandParent={true}
      titleRender={(node) => {
        const treeNode = node as Types.ContainerTreeNode;
        return <div>{treeNode.name}</div>;
      }}
      onSelect={(selectedKeys) => {
        const selectedCollectionIdKey = selectedKeys[0] as string;
        const selectedCollectionId = selectedCollectionIdKey.split('-').pop();
        if (
          selectedCollectionId === 'all' ||
          selectedCollectionId === 'unsorted'
        ) {
          history.push(`/${selectedCollectionId}`);
        } else {
          history.push(`/collections/${selectedCollectionId}`);
        }
      }}
      onExpand={(expandedKeys, info) => {
        console.log(expandedKeys);
        const expandedId = `${info.node.key}`;
        if (!expandedId) {
          return;
        }
        const newKeys = info.expanded
          ? _.uniq(manuallyExpandedKeys.concat(expandedId))
          : _.without(manuallyExpandedKeys, expandedId);
        setManuallyExpandedKeys(newKeys);
      }}
      treeData={newContainerTree.children}
    />
  );
}

function onDrop(
  info: {
    event: React.MouseEvent;
    node: RcTree.EventDataNode;
    dragNode: RcTree.EventDataNode;
    dragNodesKeys: RcTree.Key[];
    dropPosition: number;
    dropToGap: boolean;
  },
  allContainers: { [containerId: string]: Types.Container },
  moveContainerTo: (
    id: Types.StringModelId,
    newParent: Types.StringModelId,
    idx: number
  ) => void
) {
  const { node, dragNode, dropPosition, dropToGap } = info;

  global.console.log(node);
  // const dropKey = leaf((node.props.eventKey as string) || '').id;
  // const dragKey = leaf((dragNode.props.eventKey as string) || '').id;
  const dropKey = node.key as string;
  const dragKey = dragNode.key as string;

  // dropPath
  //   the last element represent the drop index, but it is dependent on if the
  //   cursor was closer to the upper or lower element when being dropped
  //  [0]..always the root folder
  //  [1]..the index of the topmost folder
  const dropPath = node.pos;
  const dropPathElements = dropPath.split('-').map((num) => parseInt(num, 10));

  const normalizedDropPosition =
    dropPosition - Number(dropPathElements[dropPathElements.length - 1]);

  const dragContainer = allContainers[dragKey];
  const dropContainer = allContainers[dropKey];

  // global.console.warn(`-------------------------`);
  // global.console.warn(`#### Gap? ${dropToGap ? 'yes' : 'no'}`);
  // global.console.warn(`#### dropPosition ${dropPosition}`);
  // global.console.warn(`#### normPos ${normalizedDropPosition}`);
  // global.console.warn(`#### path ${dropPath}`);

  // 1. Can not drag something onto a collection
  // 2. Make sure that TypeScript knows that all other elements are present
  if (
    (dropContainer.containerType === 'collection' && !dropToGap) ||
    !dragContainer ||
    !dropContainer ||
    !dropContainer.parentId
  ) {
    return;
  }

  // If dropped onto a folder make it the first child of that folder
  if (!dropToGap) {
    moveContainerTo(dragContainer.id, dropContainer.id, 0);
    return;
  }

  // Ant has a weird way of communicating the drop position, this line
  //   fixes this
  if (normalizedDropPosition === 1) {
    dropPathElements[dropPathElements.length - 1]++;
  }

  // If dragged into the top level the index is shifted due to the
  //  "All" and "Unsorted" collections
  if (dropPathElements.length === 2) {
    dropPathElements[1] -= 2;
  }

  let newIndex = dropToGap
    ? _.get(dropPathElements, dropPathElements.length - 1, 0)
    : // If a container has been dropped onto another one, put it on the first child position
      0;

  global.console.log(`${dragContainer.parentId}, ${dropContainer.parentId}`);
  if (
    dragContainer.parentId === dropContainer.parentId &&
    newIndex > dragContainer.position
  ) {
    global.console.log(`reducing the index`);
    newIndex--;
  }

  global.console.warn(`#### result idx ${newIndex}`);

  moveContainerTo(dragKey, dropContainer.parentId, newIndex);
}

function mapStateToProps(
  state: Types.All,
  // ownProps: RouteComponentProps<{}>
  ownProps: ContainerTreeOwnProps
): ContainerTreeStateProps {
  return {
    allContainersById: ContainersSelectors.allContainersById(state),
    selectedContainerId: ownProps.selectedContainerId,
    pathToSelectedContainer: ContainersSelectors.treePathContainersFor(
      state,
      ownProps.selectedContainerId
    ).map((c) => c.id),
    containerTree: ownProps.containerTree,
    matchingPaths: ownProps.matchingPaths,
    searchPhrase: ownProps.searchPhrase,
    history: ownProps.history,
  };
}

function mapDispatchToProps(
  dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>
): ContainerTreeDispatchProps {
  return {
    moveContainerTo: (
      id: Types.StringModelId,
      newParent: Types.StringModelId,
      idx: number
    ) => {
      dispatch(ContainersOperations.insertContainerAt(id, newParent, idx));
    },
  };
}

export default Redux.connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainerTree);
