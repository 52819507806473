import * as Actions from './actions';
import * as Types from '../types';
import { getType } from 'typesafe-actions';
import { initialState } from '../initialState';
import _ from 'lodash';
import * as PersistanceManagement from '../../lib/PersistanceManagement';

export default function entries(
  state: Types.Containers = initialState.containers,
  action: Actions.ContainersAction
): Types.Containers {
  let byId: Types.ById<Types.Container>;

  switch (action.type) {
    case getType(Actions.populateFromPersitance):
      byId = withChildren({
        ...state.byId,
        ..._.get(action.payload.partialState, 'byId', {}),
      });
      return {
        ...state,
        byId,
        byEntryId: deriveContainersByEntry(byId),
        shouldPersist: true,
        initialFetchOfContainersIsDone: true,
      };
    case getType(Actions.fetchContainersRequest):
      return { ...state };
    case getType(Actions.fetchContainersSuccess):
      byId = withChildren({ ...state.byId, ...action.payload.containers });
      return persist({
        ...state,
        byId,
        byEntryId: deriveContainersByEntry(byId),
        initialFetchOfContainersIsDone: true,
      });
    case getType(Actions.receivedContainerUpdate):
      byId = { ...state.byId };
      action.payload.deletedContainers.forEach(
        idToDelete => delete byId[idToDelete]
      );
      byId = withChildren({ ...byId, ...action.payload.containerById });
      return persist({
        ...state,
        byId,
        byEntryId: deriveContainersByEntry(byId),
      });
    case getType(Actions.enablePersistance):
      return {
        ...state,
        shouldPersist: true,
      };
    case getType(Actions.deleteContainerSuccess):
    case getType(Actions.createContainerSuccess):
    case getType(Actions.updateContainerSuccess):
    default:
      return state;
  }
}

// 2019-08-04: Change from children to parent_id
//   the API delivers the parent id and position, the legacy implementation
//   uses the children array of the parent as a main source (for the trees)
//   this could also probably be better done memoized and that requires rewriting
//   of some selectors. We leave this as a technical debt.
const withChildren = (byId: { [key: string]: Types.Container }) => {
  // Preflight: Make all children arrays empty
  _.forEach(byId, container => {
    if (container.containerType === 'folder') {
      container.children = [];
    }
  });
  // Rebuild the children list for each container
  _.forEach(byId, container => {
    if (container.parentId) {
      // we've set the array properly in preflight, this OR is to make TypeScript stop complaining
      (byId[container.parentId].children || [])[container.position] =
        container.id;
    }
  });
  // Postflight: Make sure there are no gaps in the children arrays
  //    (happens from position number gaps on the server)
  _.forEach(byId, container => {
    container.children = _.compact(container.children || []);
  });

  return byId;
};

// const handleEntriesDiff = (
//   byEntryId: Types.EntryToCollectionMap,
//   containerId: Types.StringModelId,
//   oldEntries: Types.StringModelId[],
//   newEntries: Types.StringModelId[]
// ): Types.EntryToCollectionMap => {
//   const added = _.difference(newEntries, oldEntries);
//   const removed = _.difference(oldEntries, newEntries);
//   _.forEach(added, entryId => {
//     byEntryId[entryId].push(containerId);
//   });
//   _.forEach(removed, entryId => {
//     _.pull(byEntryId[entryId], containerId);
//   });
//   return byEntryId;
// };

function deriveContainersByEntry(byId: {
  [key: string]: Types.Container;
}): { [entryId: string]: string[] } {
  let containersByEntry: { [entryId: string]: string[] } = {};

  Object.values(byId).forEach(container => {
    if (container.containerType === Types.ContainerType.COLLECTION) {
      container.entries.forEach(entryId => {
        if (containersByEntry[entryId]) {
          containersByEntry[entryId].push(container.id);
        } else {
          containersByEntry[entryId] = [container.id];
        }
      });
    }
  });
  return containersByEntry;
}

// This breaks the "no side effect" rule of reducers, but it makes no sense
//   to duplicate all reducer functionality in the operations
const persist = (state: Types.Containers) => {
  if (state.shouldPersist) {
    PersistanceManagement.persistContainers(state.byId);
  }
  return state;
};
