import PostProcess from '../lib/PostProcess';
import moment from 'moment';
import * as Types from '../State/types';
import * as localForage from 'localforage';
import _ from 'lodash';

/** Loads persisted state if available.
 * @returns all state that has been persisted or `undefined` if nothing has been persisted
 * or persisted data is outdated.
 */
export const loadPersistedData = async (): Promise<
  Types.PersistedData | undefined
> => {
  global.console.time('loadPersistedData');
  global.console.log(`*** Starting persistance load`);
  const persistedContainers = (await localForage.getItem(
    'containers'
  )) as Types.ById<Types.PersistContainer>;
  const containers = PostProcess.containersPersistToClient(persistedContainers);

  const persistedEntries = (await localForage.getItem('entries')) as Types.ById<
    Types.PersistEntry
  >;
  const entries = PostProcess.entriesPersistToClient(persistedEntries);

  const persistedSession = (await localForage.getItem(
    'session'
  )) as Types.PersistedSession;

  if (!containers || !entries || !persistedSession) {
    global.console.log(`*** No persisted data found`);
    return undefined;
  }

  global.console.log(
    `*** Loaded data from persistance (conts: ${
      Object.keys(containers).length
    }, ents: ${Object.keys(entries).length})`
  );
  global.console.timeEnd('loadPersistedData');
  return {
    containers: {
      byId: containers,
    },
    entries: {
      byId: entries,
    },
    session: {
      ...persistedSession,
      lastRequestAt: moment(persistedSession.lastRequestAt),
    },
  };
};

export const persistEntries = (entries: Types.ById<Types.Entry>) => {
  const toPersist: Types.ById<
    Types.PersistEntry
  > = PostProcess.entriesClientToPersist(entries);
  global.console.time('persistEntries');

  localForage
    .setItem('entries', toPersist)
    .then(persistedValue => {
      global.console.log(
        `successfully persisted ${Object.keys(persistedValue).length} entries`
      );
      global.console.timeEnd('persistEntries');
    })
    .catch(function(err) {
      global.console.log(
        `******* error when persisting entries from persisted cache ${err}`
      );
      global.console.timeEnd('persistEntries');
    });
};

export const persistContainers = (containers: Types.ById<Types.Container>) => {
  const toPersist: Types.ById<
    Types.PersistContainer
  > = PostProcess.containersClientToPersist(containers);
  global.console.time('persistContainers');

  localForage
    .setItem('containers', toPersist)
    .then(persistedValue => {
      global.console.log(
        `successfully persisted ${
          Object.keys(persistedValue).length
        } containers`
      );
      global.console.timeEnd('persistContainers');
    })
    .catch(function(err) {
      global.console.log(
        `******* error when persisting containers from persisted cache ${err}`
      );
      global.console.timeEnd('persistContainers');
    });
};

export const persistSession = (sessionState: Types.Session) => {
  if (!sessionState || !sessionState.lastRequestAt) {
    return;
  }

  const statePersistance: Types.PersistedSession = {
    ..._.pick(sessionState, ['lastRequestAt', 'featureFlags', 'rootFolderId']),
    lastRequestAt: sessionState.lastRequestAt.toISOString(),
  };

  localForage
    .setItem('session', statePersistance)
    .then(persistedValue =>
      global.console.log(`successfully persisted ${persistedValue} to session`)
    )
    .catch(function(err) {
      global.console.log(
        `******* error when persisting containers from persisted cache ${err}`
      );
    });
};

export const clearPersitentStorage = () => {
  localForage.clear();
};
