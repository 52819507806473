export function forDomain(pageUrl: string): string {
  return (pageUrl.match(/http[s]?:\/\/(www\.)?([^/]*)/) || ['', ''])[2];
}

export function forFaviconURL(pageUrl: string): string {
  return (
    pageUrl.slice(0, pageUrl.indexOf('://')) +
    '://' +
    forDomain(pageUrl) +
    '/favicon.ico'
  );
  // check for availability, if not, check with google via
  //   http://www.google.com/s2/favicons?domain=www.google.de
}
