import * as Types from '../types';
import { createAction } from 'typesafe-actions';

export const populateFromPersitance = createAction(
  'POPULATE_ENTRIES_FROM_PERSISTANCE',
  (partialEntriesState: Partial<Types.Entries>) => ({ partialEntriesState })
)();

export const enablePersistance = createAction(
  'ENABLE_ENTRIES_PERSISTANCE',
  () => {}
)();

export const fetchEntriesRequest = createAction(
  'ENTRIES_FETCH_REQUEST',
  () => {}
)();

export const fetchEntriesSuccess = createAction(
  'ENTRIES_FETCH_SUCCESS',
  (
    entries: Types.ById<Types.Entry>,
    params: { loggedBefore: string; loggedSince: string }
  ) => ({ entries, params })
)();

export const receivedEntriesUpdate = createAction(
  'RECEIVED_ENTRIES_UPDATE',
  (
    entries: Types.ById<Types.Entry>,
    deletedEntries: Types.StringModelId[]
  ) => ({ entries, deletedEntries })
)();

// Search entries
export const searchEntriesRequest = createAction(
  'ENTRIES_SEARCH_REQUEST',
  (searchPhrase: string) => ({ searchPhrase })
)();

export const searchEntriesSuccess = createAction(
  'ENTRIES_SEARCH_SUCCESS',
  (entries: Types.ById<Types.Entry>) => ({ entries })
)();

export const searchEntriesFailures = createAction(
  'ENTRIES_SEARCH_FAILURE',
  (errors: string[]) => ({ errors })
)();

// Delete entries
export const deleteEntryRequest = createAction(
  'ENTRY_DELETE_REQUEST',
  (entryID: Types.ModelId) => ({ entryID })
)();

export const deleteEntrySuccess = createAction(
  'ENTRY_DELETE_SUCCESS',
  (entryID: Types.ModelId) => ({ entryID })
)();

export const deleteEntryFailure = createAction(
  'ENTRY_DELETE_FAILURE',
  (entryID: Types.ModelId, errors: string[]) => ({ entryID, errors })
)();

// Create entries
export const createEntryRequest = createAction(
  'ENTRY_CREATE_REQUEST',
  () => ({})
)();

export const createEntrySuccess = createAction(
  'ENTRY_CREATE_SUCCESS',
  (newEntry: Types.Entry) => ({ newEntry })
)();

export const createEntryFailure = createAction(
  'ENTRY_CREATE_FAILURE',
  (errors: string[]) => ({ errors })
)();

// Edit entry
export const editEntryAction = createAction(
  'ENTRY_EDIT',
  (entryID: Types.ModelId | undefined, entryCollections: string[]) => ({
    entryID,
    entryCollections,
  })
)();

export const changeEntryInEditAction = createAction(
  'CHANGE_ENTRY_IN_EDIT',
  (entryInEdit: Partial<Types.EntryInEdit>) => ({ entryInEdit })
)();

export const cancelEditing = createAction('ENTRY_EDIT_CANCEL', () => {})();

// Update entries
export const updateEntryRequest = createAction(
  'ENTRY_UPDATE_REQUEST',
  (newEntryProps: object) => ({ newEntryProps })
)();

export const updateEntrySuccess = createAction(
  'ENTRY_UPDATE_SUCCESS',
  (updatedEntry: Types.Entry) => ({ updatedEntry })
)();

export const updateEntryFailure = createAction(
  'ENTRY_UPDATE_FAILURE',
  (entryID: Types.ModelId, errors: string[]) => ({ entryID, errors })
)();

export type EntriesAction =
  | ReturnType<typeof populateFromPersitance>
  | ReturnType<typeof enablePersistance>
  | ReturnType<typeof fetchEntriesRequest>
  | ReturnType<typeof fetchEntriesSuccess>
  | ReturnType<typeof receivedEntriesUpdate>
  | ReturnType<typeof deleteEntryRequest>
  | ReturnType<typeof deleteEntrySuccess>
  | ReturnType<typeof deleteEntryFailure>
  | ReturnType<typeof updateEntryRequest>
  | ReturnType<typeof updateEntrySuccess>
  | ReturnType<typeof updateEntryFailure>
  | ReturnType<typeof createEntryRequest>
  | ReturnType<typeof createEntrySuccess>
  | ReturnType<typeof createEntryFailure>
  | ReturnType<typeof editEntryAction>
  | ReturnType<typeof changeEntryInEditAction>
  | ReturnType<typeof cancelEditing>
  | ReturnType<typeof searchEntriesRequest>
  | ReturnType<typeof searchEntriesSuccess>
  | ReturnType<typeof searchEntriesFailures>;
