import {
  populateFromPersistance,
  saveLastRequestDate,
  logoutRequest,
  logoutSuccess,
  logoutFailure,
  loginSuccess,
  loginCheckRequest,
  goToChangePassword,
  applicationError,
} from './actions';
import * as Redux from 'redux';
import * as Auth from '../../lib/webRequests';
import * as Types from '../types';
import * as apiPaths from '../../lib/apiPaths';
import Analytics from '../../lib/analytics';
import { ContainersOperations } from '../Containers';
import { EntriesOperations } from '../Entries';
import * as Thunk from 'redux-thunk';
import * as PersistanceManagement from '../../lib/PersistanceManagement';

const startApp = () => {
  return async function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ) {
    global.console.log(
      'Started app in environment: ' + apiPaths.currentEnvironment()
    );

    // Loading persisted data
    const persistedState = await PersistanceManagement.loadPersistedData();

    if (persistedState) {
      dispatch(
        ContainersOperations.populateFromPersitance(persistedState.containers)
      );
      dispatch(
        EntriesOperations.populateFromPersitance(persistedState.entries)
      );
      dispatch(populateFromPersistance(persistedState.session));

      // Update the store with the most recent changes
      dispatch(EntriesOperations.initialFetch());
      dispatch(ContainersOperations.fetchContainers());
    }

    dispatch(loginCheckRequest());

    // If a session state has been persisted, we can assume that there's a valid login
    //   if the response from the server says otherwise, we need to delete the persistent
    //   store and redirect to logout.
    return ensureLoggedIn()
      .then((response) => {
        if (!persistedState) {
          // Update the store with the most recent changes
          dispatch(EntriesOperations.initialFetch());
          dispatch(ContainersOperations.fetchContainers());
        }

        global.console.log(
          `status: ${response.status}; body: ${response.body}`
        );

        response.json().then((json) => {
          const email = json.user.email;
          const userID = json.user.id;
          // const nickname = json.user.nickname;
          // const imageURL = json.user.image;
          const featureFlags = JSON.parse(json.user.feature_flags || '{}');
          const rootFolderId = json.user.root_container_id;
          const numOfContainers = json.user.num_of_containers;
          const numOfEntries = json.user.num_of_entries;

          Analytics.init(userID, email);

          if (!persistedState && featureFlags.shouldStoreContent) {
            dispatch(ContainersOperations.enablePersistance());
            dispatch(EntriesOperations.enablePersistance());
            PersistanceManagement.persistContainers(getState().containers.byId);
            PersistanceManagement.persistEntries(getState().entries.byId);
          }

          dispatch(SessionOperations.persistSessionState());

          return dispatch(
            loginSuccess(
              email,
              userID,
              featureFlags,
              rootFolderId.toString(),
              numOfContainers,
              numOfEntries
            )
          );
        });
      })
      .catch((e) => {
        global.console.error(`Error when ensuring login`);
      });
  };
};

const logout = () => {
  return function (dispatch: Redux.Dispatch<Types.AllActions>) {
    dispatch(logoutRequest());
    PersistanceManagement.clearPersitentStorage();
    return Auth.fetchWithCredentials(apiPaths.logoutURL(), 'DELETE')
      .then((response) =>
        response.ok ? dispatch(logoutSuccess()) : dispatch(logoutFailure())
      )
      .then((action) => window.location.assign(apiPaths.loginURL().toString()));
  };
};

export const persistSessionState = () => {
  return async function (
    dispatch: Thunk.ThunkDispatch<Types.All, undefined, Types.AllActions>,
    getState: () => Types.All
  ) {
    PersistanceManagement.persistSession(getState().session);
  };
};

export const ensureLoggedIn = async (): Promise<Response> => {
  const response = await Auth.fetchWithCredentials(apiPaths.userInfoURL());
  if (!response.ok) {
    global.console.error(`Response to ensureLoggedIn not ok. Status: ${response.status}, body: ${response.body}`);
    throw response;
  }
  return response;
    // .catch((err) => {
    //   // Auth.goTologin();
    //   logout();
    //   return err;
    // });
};

const SessionOperations = {
  populateFromPersistance,
  persistSessionState,
  startApp,
  logout,
  goToChangePassword,
  saveLastRequestDate,
  applicationError,
};
export default SessionOperations;