import { BarsOutlined, PlusOutlined, SmileOutlined } from '@ant-design/icons';
import { Button, Col, Input, Menu, Row, Skeleton } from 'antd';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import logo from '../../Icons/logo.svg';
import * as Types from '../../State/types';
import BookmarkletHint from '../UserGuidance/BookmarkletHint';
import IntegrationHint from '../UserGuidance/IntegrationHint';
import ContainerTree from './ContainerTree';
import './Sidebar.css';


export interface StateProps {
  selectedContainerId: string;
  initialFetchDone: boolean;
  userEmail: string;
  history: RouteComponentProps['history'];
  containerTreeSearch: (
    searchPhrase: string
  ) => { tree: Types.ContainerTreeNode; matchingPaths: string[] };
}

export interface DispatchProps {
  showBookmarkletHint: () => void;
  showIntegrationHint: () => void;
  showFeedbackModal: () => void;
  newEntry: () => void;
  handleLogout: () => void;
  handleChangePassword: () => void;
  addNewFolder: (parentContainerId: Types.StringModelId, index: number) => void;
  addNewCollection: (
    parentContainerId: Types.StringModelId,
    index: number
  ) => void;
}

export interface DispatchProps {}
export interface Props extends StateProps, DispatchProps {}

export default function Sidebar({
  selectedContainerId,
  initialFetchDone,
  userEmail,
  history,
  showBookmarkletHint,
  showIntegrationHint,
  showFeedbackModal,
  newEntry,
  handleLogout,
  handleChangePassword,
  containerTreeSearch,
  addNewCollection,
  addNewFolder,
}: Props) {
  const [searchPhrase, setSearchPhrase] = useState('');

  const { tree: containerTree, matchingPaths } = containerTreeSearch(
    searchPhrase
  );

  if (!initialFetchDone && containerTree.children.length === 0) {
    return <Skeleton paragraph={{ rows: 12 }} active={true} />;
  }
  return (
    <>
      <Row className="menu_row" justify="space-between">
        <BookmarkletHint />
        <IntegrationHint />
        <Col>
          {' '}
          <Link to="/">
            <div className="logo">
              <img src={logo} alt="unconfuse logo" />
            </div>
          </Link>
        </Col>
        <Col>
          <Menu
            // theme="light"
            mode="horizontal"
          >
            <Menu.SubMenu
              key="entry"
              title={
                <span>
                  <PlusOutlined />
                  <span className="menu-item-title">Entry</span>
                </span>
              }
            >
              <Menu.Item key="createEntry" onClick={newEntry}>
                Add Entry
              </Menu.Item>
              <Menu.Item
                key="showBookmarkletHint"
                onClick={showBookmarkletHint}
              >
                Add via bookmarklet
              </Menu.Item>
              <Menu.Item
                key="showIntegrationtHint"
                onClick={showIntegrationHint}
              >
                Add via integration
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu
              key="account"
              title={
                <span>
                  <BarsOutlined />
                  <span className="menu-item-title">Menu</span>
                </span>
              }
            >
              <Menu.ItemGroup title={userEmail}>
                <Menu.Item key="logout" onClick={(e) => handleLogout()}>
                  Logout
                </Menu.Item>
                <Menu.Item
                  key="changePassword"
                  onClick={(e) => handleChangePassword()}
                >
                  Change Password
                </Menu.Item>
              </Menu.ItemGroup>
              <Menu.ItemGroup title="Feedback">
                <Menu.Item key="Feedback" onClick={showFeedbackModal}>
                  <SmileOutlined />
                  <span className="menu-item-title">Feedback</span>
                </Menu.Item>
              </Menu.ItemGroup>
            </Menu.SubMenu>
          </Menu>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={12}>
          <Button
            onClick={() => addNewFolder(containerTree.id, 0)}
            block={true}
          >
            + Folder
          </Button>
        </Col>
        <Col xs={12}>
          <Button
            onClick={() => addNewCollection(containerTree.id, 0)}
            block={true}
          >
            + Collection
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <h3 className="section-header">Folders</h3>
          {(containerTree.children.length > 32 || searchPhrase.length > 0) && (
            <Input
              value={searchPhrase}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearchPhrase(event.target.value);
              }}
              placeholder={`Search`}
              allowClear={true}
              autoComplete="false"
              autoCorrect="false"
              spellCheck={false}
            />
          )}
          <ContainerTree
            selectedContainerId={selectedContainerId}
            containerTree={containerTree}
            matchingPaths={matchingPaths}
            searchPhrase={searchPhrase}
            history={history}
          />
        </Col>
      </Row>
    </>
  );
}
